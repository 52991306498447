import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'
import { TagProps } from './types'
import { themeToStyle } from './utils'

const useStyles = makeStyles<{
  color: TagProps['color']
  variant: TagProps['variant']
}>({
  root: (theme, { color, variant }) => css`
    display: inline-flex;
    align-items: center;
    ${typographyToStyle(theme.typography.label)};
    font-family: ${theme.typography.fontFamily};
    color: ${themeToStyle[variant][color].color};
    background-color: ${themeToStyle[variant][color].background};
    padding: 1px ${theme.spacing(2)}px;
    border-radius: ${theme.borderRadius.pill};
    min-height: 24px;
  `,

  icon: (theme, { variant, color }) => css`
    width: ${theme.spacing(1)}px;
    height: ${theme.spacing(1)}px;
    border-radius: ${theme.borderRadius.circular};
    background: ${themeToStyle[variant][color].icon};
    margin-right: ${theme.spacing(1)}px;
  `,
})

export default useStyles
