import { SerializedStyles } from '@emotion/react'
import { HtmlHTMLAttributes } from 'react'

export enum TooltipStyleKeys {
  root = 'root',
  element = 'element',
  tooltip = 'tooltip',
  placement = 'placement',
  tooltipTheme = 'theme',
  variant = 'variant',
}

export const variants = ['fixed', 'auto'] as const
export type Variant = typeof variants[number]

export const placements = ['top', 'down', 'left', 'right'] as const
export type Placement = typeof placements[number]

export const tooltipThemes = ['light', 'dark'] as const
export type Theme = typeof tooltipThemes[number]

export interface TooltipProps {
  content?: string
  styles?: Partial<Record<TooltipStyleKeys, SerializedStyles>>
  placement?: Placement
  theme?: Theme
  variant?: Variant
  tooltipProps?: HtmlHTMLAttributes<HTMLDivElement>
}

export interface ValidatedTheme
  extends Pick<TooltipProps, 'theme' | 'placement' | 'variant'> {}

export interface PropsToValidate {
  propTheme: TooltipProps['theme']
  propVariant: TooltipProps['variant']
  propPlacement: TooltipProps['placement']
}

export interface ValidatedProps
  extends Pick<TooltipProps, 'placement' | 'theme'> {}

export interface UseValidatePropsParams {
  propPlacement: TooltipProps['placement']
  propTheme: TooltipProps['theme']
}

export interface UseValidateProps {
  (propsToValidate: UseValidatePropsParams): ValidatedProps
}
