import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFrame: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 12.75c-.69 0-1.25.56-1.25 1.25v5c0 .69.56 1.25 1.25 1.25h3c.69 0 1.25-.56 1.25-1.25v-5c0-.69-.56-1.25-1.25-1.25h-3ZM13.25 14A2.75 2.75 0 0 1 16 11.25h3A2.75 2.75 0 0 1 21.75 14v5A2.75 2.75 0 0 1 19 21.75h-3A2.75 2.75 0 0 1 13.25 19v-5ZM16 3.75c-.69 0-1.25.56-1.25 1.25v2c0 .69.56 1.25 1.25 1.25h3c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25h-3ZM13.25 5A2.75 2.75 0 0 1 16 2.25h3A2.75 2.75 0 0 1 21.75 5v2A2.75 2.75 0 0 1 19 9.75h-3A2.75 2.75 0 0 1 13.25 7V5ZM5 3.75c-.69 0-1.25.56-1.25 1.25v5c0 .69.56 1.25 1.25 1.25h3c.69 0 1.25-.56 1.25-1.25V5c0-.69-.56-1.25-1.25-1.25H5ZM2.25 5A2.75 2.75 0 0 1 5 2.25h3A2.75 2.75 0 0 1 10.75 5v5A2.75 2.75 0 0 1 8 12.75H5A2.75 2.75 0 0 1 2.25 10V5ZM5 15.75c-.69 0-1.25.56-1.25 1.25v2c0 .69.56 1.25 1.25 1.25h3c.69 0 1.25-.56 1.25-1.25v-2c0-.69-.56-1.25-1.25-1.25H5ZM2.25 17A2.75 2.75 0 0 1 5 14.25h3A2.75 2.75 0 0 1 10.75 17v2A2.75 2.75 0 0 1 8 21.75H5A2.75 2.75 0 0 1 2.25 19v-2Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFrame
