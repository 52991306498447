import { AxiosError } from 'axios';
import {
  ApplyAppTokenRefreshInterceptorOptions,
  ApplyAppTokenRefreshInterceptorParams,
  ApplyRefreshTokenFn,
  FailedRequestQueue,
} from './types';

let isRefreshing = false;
let failedRequestsQueue: FailedRequestQueue[] = [];

const processQueue = (error: AxiosError, accessToken: string) => {
  failedRequestsQueue.forEach(request => {
    if (error) {
      request.onFailure(error);
    } else {
      request.onSuccess(accessToken);
    }

    failedRequestsQueue = [];
  });
};

const applyAppTokenRefreshInterceptor: ApplyRefreshTokenFn<ApplyAppTokenRefreshInterceptorParams> = ({
  client,
  handleRefreshToken,
  onSuccess,
  onError,
  options,
}) => {
  const shouldIntercept = (error: AxiosError) => {
    try {
      return error?.response?.status === 401;
    } catch (e) {
      return false;
    }
  };

  const currentOptions: ApplyAppTokenRefreshInterceptorOptions = {
    shouldIntercept,
    preventUpdateHeaders: false,
    ...options,
  };

  const interceptor = (error: AxiosError) => {
    const originalConfig = error.config;

    if (currentOptions?.shouldIntercept?.(error)) {
      if (!isRefreshing) {
        isRefreshing = true;

        handleRefreshToken()
          .then(accessToken => {
            if (!options?.preventUpdateHeaders) {
              client.defaults.headers.token = accessToken;
            }

            if (onSuccess) {
              onSuccess(accessToken);
            }

            processQueue(null, accessToken);
          })
          .catch(error => {
            processQueue(error, null);

            if (currentOptions?.log) {
              console.log('intercepted error<>>', error);
            }

            if (onError) {
              onError(error);
            }
          })
          .finally(() => {
            isRefreshing = false;
          });
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: (token: string) => {
            originalConfig.headers.token = token;

            resolve(client(originalConfig));
          },
          onFailure: (err: AxiosError) => {
            reject(err);
          },
        });
      });
    }

    return Promise.reject(error);
  };

  client.interceptors.response.use(undefined, interceptor);
};

export { applyAppTokenRefreshInterceptor };
