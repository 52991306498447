import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgDownload: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 .25a.75.75 0 0 1 .75.75v12.19l3.72-3.72a.75.75 0 0 1 1.06 1.06l-5 5.001a.75.75 0 0 1-1.061 0l-5-5a.75.75 0 0 1 1.06-1.061L8.25 13.19V1A.75.75 0 0 1 9 .25ZM.25 19a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 0 1.5H1A.75.75 0 0 1 .25 19Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDownload
