import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgCameraOn: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 22 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.633 1.625c-.898 0-1.625.727-1.625 1.625v7.583c0 .898.727 1.625 1.625 1.625h8.532c.897 0 1.625-.727 1.625-1.625V3.25c0-.898-.728-1.625-1.625-1.625H3.633ZM.383 3.25A3.25 3.25 0 0 1 3.633 0h8.532a3.25 3.25 0 0 1 3.25 3.25v7.583a3.25 3.25 0 0 1-3.25 3.25H3.633a3.25 3.25 0 0 1-3.25-3.25V3.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.204 1.912c1.33-1.07 3.304-.124 3.304 1.582v7.096c0 1.705-1.975 2.652-3.305 1.581l-4.11-3.308a.812.812 0 1 1 1.018-1.266l4.112 3.309c.265.213.66.025.66-.316V3.494a.406.406 0 0 0-.66-.317l-4.112 3.31a.812.812 0 1 1-1.018-1.267l4.11-3.308Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCameraOn
