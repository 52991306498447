import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'

export const useStyles = makeStyles({
  root: ({ spacing }) => css`
    display: inline-flex;
    align-items: center;
    margin-right: ${spacing(1)}px;
  `,

  labelText: ({ colors, typography }) => css`
    color: ${colors.neutral[900]};
    ${typographyToStyle(typography.label)}
    cursor:pointer;
  `,

  disabledLabel: ({ colors }) => css`
    pointer-events: none;
    color: ${colors.extra.disabled_font};
  `,
})

export default useStyles
