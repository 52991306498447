import { useMemo } from 'react'
import { theme } from '@conexasaude/styles'
import { TagProps, Color, colors, Variant, variants } from './types'

export const isColor = (value: Color): value is Color => colors.includes(value)
export const isVariant = (value: Variant): value is Variant =>
  variants.includes(value)
export interface ValidatedProps {
  color: TagProps['color']
  variant: TagProps['variant']
}

export interface PropsToValidate {
  propColor: TagProps['color']
  propVariant: TagProps['variant']
}
export const useValidateState = ({
  propColor,
  propVariant,
}: PropsToValidate): ValidatedProps => {
  const color = useMemo(() => {
    if (!isColor(propColor)) {
      if (propColor) {
        console.warn(
          'The provided color property is not a valid color. A default value will be used.',
        )
      }
      return 'primary'
    }
    return propColor
  }, [propColor])

  const variant = useMemo(() => {
    if (!isVariant(propVariant)) {
      if (propVariant) {
        console.warn(
          'The provided variant property is not a valid variant. A default value will be used.',
        )
      }
      return 'solid'
    }
    return propVariant
  }, [propVariant])

  return { color, variant }
}

export const themeToStyle = {
  solid: {
    primary: {
      background: theme.colors.primary[600],
      color: theme.colors.common.white,
      icon: '',
    },
    secondary: {
      background: theme.colors.secondary[600],
      color: theme.colors.common.white,
      icon: '',
    },
    success: {
      background: theme.colors.success[600],
      color: theme.colors.common.white,
      icon: '',
    },
    danger: {
      background: theme.colors.danger[600],
      color: theme.colors.common.white,
      icon: '',
    },
    warning: {
      background: theme.colors.warning[600],
      color: theme.colors.common.white,
      icon: '',
    },
    info: {
      background: theme.colors.info[600],
      color: theme.colors.common.white,
      icon: '',
    },
    neutral: {
      background: theme.colors.neutral[600],
      color: theme.colors.common.white,
      icon: '',
    },
  },
  light: {
    primary: {
      background: theme.colors.primary[100],
      color: theme.colors.primary[700],
      icon: theme.colors.primary[500],
    },
    secondary: {
      background: theme.colors.secondary[100],
      color: theme.colors.secondary[700],
      icon: theme.colors.secondary[500],
    },
    success: {
      background: theme.colors.success[100],
      color: theme.colors.success[700],
      icon: theme.colors.success[500],
    },
    danger: {
      background: theme.colors.danger[100],
      color: theme.colors.danger[700],
      icon: theme.colors.danger[500],
    },
    warning: {
      background: theme.colors.warning[100],
      color: theme.colors.warning[700],
      icon: theme.colors.warning[500],
    },
    info: {
      background: theme.colors.info[100],
      color: theme.colors.info[700],
      icon: theme.colors.info[500],
    },
    neutral: {
      background: theme.colors.neutral[100],
      color: theme.colors.neutral[700],
      icon: theme.colors.neutral[500],
    },
  },
}
