import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'

const useStyles = makeStyles<{
  hasIcon: boolean
  hasLabel: boolean
  fullWidth: boolean
}>({
  root: ({ spacing }, { hasLabel, fullWidth }) => css`
    width: ${fullWidth ? '100%' : 'auto'};
    display: inline-flex;
    flex-direction: column;
    ${hasLabel
      ? `
    padding: ${spacing(1.5)}px 0;
    `
      : ''}
  `,

  wrapper: css`
    position: relative;
    display: inline-flex;
    align-items: center;
    min-width: 217px;
  `,

  label: theme => css`
    ${typographyToStyle(theme.typography.label)};
    font-family: ${theme.typography.fontFamily};
    color: ${theme.colors.neutral[500]};
    background: ${theme.colors.common.white};
    position: absolute;
    left: 12px;
    top: -12px;
    padding: 0 4px;
    z-index: 1;
  `,

  inputBox: ({ borderRadius, colors }) => css`
    border-radius: ${borderRadius.sm};
    border-color: ${colors.neutral[500]};
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    width: 100%;
    display: flex;

    &:focus-within {
      border-color: ${colors.primary[500]};
      color: ${colors.primary[500]};

      & ~ label {
        color: ${colors.primary[500]};
      }
    }
  `,

  input: ({ colors, typography }, { hasIcon }) => css`
    border: none;
    box-sizing: border-box;
    border: none;
    padding: 12px 15px;
    ${hasIcon
      ? `
      padding-right:40px;
    `
      : ''}
    display: inline-flex;
    position: relative;
    width: 100%;
    transition: all 0.2s ease-in-out;
    resize: none;
    ${typographyToStyle(typography.body2)};
    color: ${colors.neutral[900]};

    &::placeholder {
      color: ${colors.neutral[400]};
    }

    &:focus {
      outline: none;

      &::placeholder {
        color: ${colors.neutral[900]};
      }
    }
  `,

  icon: css`
    display: inline-flex;
    pointer-events: none;
    margin-right: 16px;
    position: absolute;
    right: 0;
  `,

  data_entered: ({ colors }) => css`
    label {
      color: ${colors.neutral[900]};
      border-color: ${colors.neutral[800]};
    }
  `,

  validated: ({ colors }) => css`
    > label {
      color: ${colors.success[600]};
      border-color: ${colors.success[600]};
    }
    input,
    textarea {
      &::placeholder {
        color: ${colors.neutral[900]};
      }
    }
  `,

  error: ({ colors }) => css`
    > label {
      color: ${colors.danger[500]};
      border-color: ${colors.danger[500]};
    }

    input,
    textarea {
      &::placeholder {
        color: ${colors.neutral[900]};
      }
    }
  `,

  errorMessage: theme => css`
    color: ${theme.colors.danger[600]};
    ${typographyToStyle(theme.typography.microCopy)};
    margin: 0;
    margin-top: 4px;
    margin-left: 4px;
  `,

  disabled: ({ colors }) => css`
    > label {
      color: ${colors.extra.disabled_font};
      border-color: ${colors.extra.disabled_bg};
    }

    input,
    textarea {
      color: ${colors.extra.disabled_font};
      background: transparent;
      &::placeholder {
        color: ${colors.extra.disabled_font};
      }
    }
  `,
})

export default useStyles
