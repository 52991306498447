import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFail: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.447 8.553c.3.3.3.788 0 1.088L9.64 15.447a.77.77 0 1 1-1.088-1.088l5.806-5.806c.3-.3.787-.3 1.088 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.553 8.553c.3-.3.788-.3 1.088 0l5.806 5.806a.77.77 0 0 1-1.088 1.088L8.553 9.64a.77.77 0 0 1 0-1.088Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.538A8.461 8.461 0 0 0 3.538 12 8.46 8.46 0 0 0 12 20.462 8.46 8.46 0 0 0 20.462 12 8.46 8.46 0 0 0 12 3.538ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFail
