import { Theme as HeroTheme } from '@conexasaude/styles'
import { useMemo } from 'react'
import { ToastProps, Theme, themes, Variants, variants } from './types'

export const isType = (value: Theme): value is Theme => themes.includes(value)
export const isVariant = (value: Variants): value is Variants =>
  variants.includes(value)

export interface ValidatedProps {
  theme: ToastProps['theme']
  variant: ToastProps['variant']
}

export interface PropsToValidate {
  propTheme: ToastProps['theme']
  propVariant: ToastProps['variant']
}

export const useValidateStates = ({
  propTheme,
  propVariant,
}: PropsToValidate): ValidatedProps => {
  const theme = useMemo(() => {
    if (!isType(propTheme)) {
      if (propTheme) {
        console.warn(
          'The provided variant property is not a valid variant. A default value will be used.',
        )
      }
      return 'light'
    }
    return propTheme
  }, [propTheme])

  const variant = useMemo(() => {
    if (!isVariant(propVariant)) {
      if (propVariant) {
        console.warn(
          'The provided variant property is not a valid variant. A default value will be used.',
        )
      }
      return 'success'
    }
    return propVariant
  }, [propVariant])

  return { theme, variant }
}

export const themeToStyle = (
  themeVariant: 'dark' | 'light',
  themeColors: HeroTheme['colors'],
): {
  background: string
  color: string
  closeIcon: string
} => {
  const variantDict = {
    dark: {
      background: themeColors.neutral[700],
      color: themeColors.common.white,
      closeIcon: themeColors.common.white,
    },
    light: {
      background: themeColors.common.white,
      color: themeColors.neutral[900],
      closeIcon: themeColors.neutral[800],
    },
  }
  return variantDict[themeVariant]
}
