import React, { FunctionComponent, useMemo } from 'react'
import { ModalProps } from './types'
import useWidth, { useStateStyles } from './utils'
import useStyles from './styles'
import { ReactComponent as CloseIcon } from '@/assets/icon_modalClose.svg'
import { Button } from '../Button'
import { Link } from '../Link'
import isBool from '@/utils/isBool'

export const Modal: FunctionComponent<ModalProps> = ({
  styles: overrides,
  children,
  title,
  isMobile: isMobileProp,
  open,
  onClose,
  primaryActionLabel: primaryActionLabelProp = 'Concluir',
  secondaryActionLabel: secondaryActionLabelProp = 'Desfazer',
  negativeActionLabel: negativeActionLabelProp = 'Cancelar',
  closeOnBackdrop,
  onBackdropClick,
  primaryActionProps,
  secondaryActionProps,
  negativeAction,
  primaryAction,
  secondaryAction,
}) => {
  const width = useWidth()

  const isMobile = useMemo(() => {
    if (isBool(isMobileProp)) return isMobileProp
    return width <= 639
  }, [isMobileProp, width])

  const styles = useStyles({ isMobile })
  const { openStyles, closeOnBackdropStyles } = useStateStyles({
    open,
    closeOnBackdrop,
    styles,
    overrides,
  })

  return (
    <div
      css={[styles.root, overrides?.root, ...openStyles]}
      className="hero-modal_root"
      data-testid="hero-modal"
    >
      <button
        css={[styles.backdrop, overrides?.backdrop, ...closeOnBackdropStyles]}
        className="hero-modal_backdrop"
        onClick={closeOnBackdrop ? onClose : onBackdropClick}
      ></button>
      <div css={[styles.modal, overrides?.modal]} className="hero-modal">
        <div
          css={[styles.header, overrides?.header]}
          className="hero-modal_header"
        >
          <h4>{title}</h4>
          {onClose && (
            <button onClick={onClose}>
              <CloseIcon />
            </button>
          )}
        </div>
        <div
          css={[styles.container, overrides?.container]}
          className="hero-modal_container"
        >
          {children}
        </div>
        <div
          css={[styles.footer, overrides?.footer]}
          className="hero-modal_footer"
        >
          {isMobile ? (
            <>
              {primaryAction && (
                <Button onClick={primaryAction} {...primaryActionProps}>
                  {primaryActionLabelProp}
                </Button>
              )}
              {secondaryAction && (
                <Button onClick={secondaryAction} {...secondaryActionProps}>
                  {secondaryActionLabelProp}
                </Button>
              )}
              {negativeAction && (
                <Link onClick={negativeAction} renderAs="button">
                  {negativeActionLabelProp}
                </Link>
              )}
            </>
          ) : (
            <>
              {secondaryAction && (
                <Button onClick={secondaryAction} {...secondaryActionProps}>
                  {secondaryActionLabelProp}
                </Button>
              )}
              <div>
                {negativeAction && (
                  <Link onClick={negativeAction} renderAs="button">
                    {negativeActionLabelProp}
                  </Link>
                )}
                {primaryAction && (
                  <Button onClick={primaryAction} {...primaryActionProps}>
                    {primaryActionLabelProp}
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
