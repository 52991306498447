import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgEdit: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.834 3.622c-.71 0-1.296.612-1.296 1.381v13.994c0 .768.586 1.381 1.296 1.381h14.332c.71 0 1.296-.613 1.296-1.381v-6.384c0-.448.344-.81.769-.81.425 0 .769.362.769.81v6.384C22 20.65 20.737 22 19.166 22H4.834C3.264 22 2 20.65 2 18.997V5.003C2 3.35 3.263 2 4.834 2H12c.425 0 .77.363.77.81 0 .449-.345.812-.77.812H4.834Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.009 2.871a2.719 2.719 0 0 1 3.988 0l.177.186c1.101 1.16 1.101 3.043 0 4.204l-8.605 9.07-.003.002a1.78 1.78 0 0 1-.832.496l-3.812 1.005a.751.751 0 0 1-.745-.21.835.835 0 0 1-.199-.785l.954-4.018c.079-.333.242-.637.472-.88l8.605-9.07Zm-8.23 13.065 2.582-.68a.26.26 0 0 0 .121-.073l8.604-9.069c.5-.527.5-1.383 0-1.91l-.176-.186a1.236 1.236 0 0 0-1.813 0l-8.605 9.07a.276.276 0 0 0-.068.126l-.646 2.722Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.384 4.583a.742.742 0 0 1 1.088 0l3.077 3.244c.3.316.3.83 0 1.146a.742.742 0 0 1-1.088 0L15.385 5.73a.842.842 0 0 1 0-1.147ZM8.616 12.22c.08-.011.24-.035.387-.035 1.841 0 3.333 1.573 3.333 3.513 0 .161-.022.325-.034.415a2.576 2.576 0 0 0-.005.039c-.056.444-.442.756-.863.697-.421-.058-.718-.466-.662-.91l.012-.094c.01-.08.014-.11.014-.147 0-1.045-.803-1.892-1.795-1.892a.633.633 0 0 0-.103.01l-.133.02c-.421.054-.805-.263-.857-.707-.051-.444.249-.849.67-.903l.036-.006Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEdit
