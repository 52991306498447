import { FunctionComponent, useMemo } from 'react'
import { HeaderProps } from './types'
import useStyles from './styles'
import { isBool } from '@/utils/isBool'
import useWidth from './utils'

export const Header: FunctionComponent<HeaderProps> = ({
  styles: overrides,
  logo,
  rightSideContent,
  rightSideIcon,
  leftSideIcon,
  label,
  isMobile: isMobileProp,
  button,
}) => {
  const width = useWidth()

  const isMobile = useMemo(() => {
    if (isBool(isMobileProp)) return isMobileProp
    return width <= 639
  }, [isMobileProp, width])

  const styles = useStyles({ isMobile })

  return (
    <header
      css={[styles.root, overrides?.root]}
      className="hero-header_root"
      data-testid="hero-header"
    >
      <div
        css={[styles.leftSide, overrides?.leftSide]}
        className="hero-header_leftSide"
      >
        {leftSideIcon && (
          <span
            css={[styles.leftSideIcon, overrides?.leftSideIcon]}
            data-testid="leftSideIcon"
          >
            {leftSideIcon}
          </span>
        )}
        {label && !logo && (
          <h3 css={[styles.label, overrides?.label]}>{label}</h3>
        )}
        {logo && !label && (
          <div css={[styles.logo, overrides?.logo]}>{logo}</div>
        )}
      </div>
      <div
        css={[styles.rightSide, overrides?.rightSide]}
        className="hero-header_rightSide"
      >
        {rightSideIcon && (
          <span
            css={[styles.rightSideIcon, overrides?.rightSideIcon]}
            data-testid="rightSideIcon"
          >
            {rightSideIcon}
          </span>
        )}

        {rightSideContent && !button && (
          <div css={[styles.rightSideContent, overrides?.rightSideContent]}>
            {rightSideContent}
          </div>
        )}

        {button && !rightSideContent && (
          <div css={[styles.button, overrides?.button]}>{button}</div>
        )}
      </div>
    </header>
  )
}
