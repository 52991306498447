import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgCloseCircle: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.298 5.702c.2.2.2.525 0 .726l-3.87 3.87a.513.513 0 0 1-.726-.725l3.87-3.87c.2-.2.526-.2.726 0Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.702 5.702c.2-.2.525-.2.726 0l3.87 3.87a.513.513 0 0 1-.725.726l-3.87-3.87c-.2-.2-.2-.525 0-.726Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2.36a5.64 5.64 0 1 0 0 11.28A5.64 5.64 0 0 0 8 2.36ZM1.333 8a6.666 6.666 0 1 1 13.334 0A6.666 6.666 0 1 1 1.334 8Z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgCloseCircle
