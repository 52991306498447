import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFileError: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.748 2.003h6.922a.758.758 0 0 1 .15 0h.1c.729 0 1.427.289 1.942.804l2.83 2.827c.517.515.807 1.215.807 1.944v11.173A2.748 2.748 0 0 1 17.75 21.5H7.748A2.748 2.748 0 0 1 5 18.751v-.332A3.749 3.749 0 0 1 2 14.746v-4.001A3.749 3.749 0 0 1 5 7.072V4.75a2.748 2.748 0 0 1 2.748-2.748ZM6.5 18.75v-.257h3.248a3.747 3.747 0 0 0 3.748-3.748v-4.001a3.747 3.747 0 0 0-3.748-3.747H6.5V4.75c0-.69.56-1.25 1.25-1.25h6.246v3.247c0 .966.783 1.749 1.75 1.749H19V18.75c0 .69-.56 1.25-1.249 1.25H7.748c-.69 0-1.249-.56-1.249-1.25Zm9.245-11.753h3.113a1.248 1.248 0 0 0-.223-.304l-2.83-2.827a1.25 1.25 0 0 0-.31-.226v3.107c0 .138.112.25.25.25ZM3.498 10.745a2.248 2.248 0 0 1 2.248-2.248h4.001a2.248 2.248 0 0 1 2.249 2.248v4.001a2.248 2.248 0 0 1-2.249 2.249h-4a2.248 2.248 0 0 1-2.25-2.249v-4.001Zm3.574.268a.75.75 0 1 0-1.152.96l.85 1.019-.85 1.019a.75.75 0 0 0 1.152.96l.673-.809.673.808a.75.75 0 0 0 1.152-.96l-.85-1.018.85-1.02a.75.75 0 0 0-1.152-.96l-.673.809-.673-.808Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFileError
