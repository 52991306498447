import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgPhoneOn: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        clipRule="evenodd"
        d="M11.19 11.192a13.162 13.162 0 0 0 2.749-3.968.78.78 0 0 0-.173-.862l-.853-.852c-.7-.699-.7-1.687-.089-2.298l1.223-1.222a2.083 2.083 0 0 1 2.946 0l.68.679c.771.772 1.093 1.885.885 2.99-.515 2.721-2.096 5.701-4.647 8.253-2.551 2.55-5.532 4.132-8.253 4.646-1.105.209-2.218-.113-2.99-.885l-.678-.678a2.083 2.083 0 0 1 0-2.946l1.222-1.222c.61-.61 1.6-.61 2.209 0l.94.942a.78.78 0 0 0 .862.173c1.385-.612 2.75-1.532 3.968-2.75Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgPhoneOn
