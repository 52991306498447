import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMedicalCross: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.539a8.461 8.461 0 0 0-8.461 8.46v.002A8.462 8.462 0 1 0 12 3.539h-.002Zm10 8.46C22 6.477 17.523 2 12 2S2 6.477 2 12s4.478 10 10 10c5.523 0 10.001-4.478 10-10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.367 7.911c0-.991.803-1.795 1.794-1.795h1.678c.992 0 1.795.804 1.795 1.795V9.38h1.47c.99 0 1.794.804 1.794 1.795v1.678c0 .99-.804 1.795-1.795 1.795h-1.469v1.469c0 .99-.803 1.794-1.795 1.794h-1.678a1.795 1.795 0 0 1-1.794-1.794v-1.47h-1.47a1.795 1.795 0 0 1-1.794-1.794v-1.678c0-.991.804-1.795 1.795-1.795h1.469V7.91Zm1.794-.256a.257.257 0 0 0-.256.256v2.238a.77.77 0 0 1-.77.77H7.899a.257.257 0 0 0-.257.256v1.678c0 .141.115.256.257.256h2.238a.77.77 0 0 1 .77.77v2.238c0 .14.114.256.255.256h1.678a.257.257 0 0 0 .257-.256v-2.239a.77.77 0 0 1 .77-.769h2.237a.257.257 0 0 0 .257-.256v-1.678a.257.257 0 0 0-.257-.257h-2.238a.77.77 0 0 1-.769-.769V7.911a.257.257 0 0 0-.257-.256h-1.678Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMedicalCross
