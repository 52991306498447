import { MainContainerProps } from './types'
import { makeStyles, css } from '@conexasaude/styles'

const useStyles = makeStyles<{ minHeight: MainContainerProps['minHeight'] }>({
  root: (theme, { minHeight }) => css`
    display: flex;
    flex-direction: column;
    background: #fff;
    min-height: ${minHeight};
  `,
})

export default useStyles
