import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgQuestionCheckmarkCircle: FunctionComponent<
  IndividualIconProps
> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M13 21.608a8.119 8.119 0 0 1-1 .063 9.004 9.004 0 1 1 9.004-9.004c0 .334-.02.668-.062 1M20.004 17.669l-2.501 2.5-1.501-1.5"
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="m12 13.378 1.328-.737a2.087 2.087 0 0 0 1.073-1.825 2.298 2.298 0 0 0-2.42-2.15 2.345 2.345 0 0 0-2.382 1.812M12.1 16.569a.1.1 0 1 1-.2 0 .1.1 0 0 1 .2 0"
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgQuestionCheckmarkCircle
