import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFolder: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.846 5.509a3.004 3.004 0 0 1 3-3.009h5.657c.918 0 1.799.366 2.448 1.017l2.189 2.195a3.477 3.477 0 0 1 1.014 2.455v3.381a.693.693 0 1 1-1.385 0v-3.38c0-.553-.219-1.083-.608-1.474L14.97 4.5c-.389-.39-.917-.61-1.468-.61H7.846c-.892 0-1.615.725-1.615 1.62V8.77a.693.693 0 1 1-1.385 0V5.509Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.846 2.522c.383 0 .693.31.693.694v3.218c0 .384.31.695.692.695h3.209a.693.693 0 0 1 0 1.388h-3.21a2.08 2.08 0 0 1-2.076-2.083V3.216c0-.383.31-.694.692-.694ZM3 10.622a2.542 2.542 0 0 1 2.538-2.546h3.875a2.075 2.075 0 0 1 1.728.928l1.024 1.54c.128.194.344.31.576.31h5.72A2.542 2.542 0 0 1 21 13.4v5.554a2.542 2.542 0 0 1-2.538 2.546H5.538A2.542 2.542 0 0 1 3 18.954v-8.332Zm2.538-1.157c-.637 0-1.153.518-1.153 1.157v8.332c0 .64.516 1.157 1.153 1.157h12.924c.637 0 1.153-.518 1.153-1.157V13.4c0-.64-.516-1.158-1.153-1.158H12.74a2.075 2.075 0 0 1-1.728-.927l-1.024-1.54a.692.692 0 0 0-.576-.31H5.538Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.462 15.02c0-.384.31-.695.692-.695h2.77a.693.693 0 0 1 0 1.389h-2.77a.693.693 0 0 1-.692-.694Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.538 12.937c.383 0 .693.31.693.694v2.777a.693.693 0 1 1-1.385 0v-2.777c0-.383.31-.694.692-.694Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFolder
