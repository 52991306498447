import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgPlus: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 .907c.523 0 .947.424.947.948v14.29a.947.947 0 0 1-1.894 0V1.855c0-.524.424-.948.947-.948Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.093 9a.947.947 0 0 1-.948.947H1.855a.947.947 0 1 1 0-1.894h14.29c.524 0 .948.424.948.947Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPlus
