import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgParagraphNumber: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.25 4a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75ZM10.4 9a.75.75 0 0 1 .75-.75H20a.75.75 0 0 1 0 1.5h-8.85A.75.75 0 0 1 10.4 9ZM5.537 3.307A.75.75 0 0 1 6 4v5a.75.75 0 1 1-1.5 0V5.81a.75.75 0 0 1-1.03-1.09l1.25-1.25a.75.75 0 0 1 .817-.163ZM10.25 14a.75.75 0 0 1 .75-.75h9a.75.75 0 0 1 0 1.5h-9a.75.75 0 0 1-.75-.75ZM10.4 19a.75.75 0 0 1 .75-.75H20a.75.75 0 0 1 0 1.5h-8.85a.75.75 0 0 1-.75-.75ZM5 14.75a.25.25 0 0 0-.25.25.75.75 0 0 1-1.5 0c0-.966.784-1.75 1.75-1.75h.614a1.979 1.979 0 0 1 1.503 3.266L5.63 18.25H7a.75.75 0 0 1 0 1.5H4a.75.75 0 0 1-.57-1.238l2.548-2.972a.479.479 0 0 0-.364-.79H5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 9A.75.75 0 0 1 4 8.25h2.5a.75.75 0 0 1 0 1.5H4A.75.75 0 0 1 3.25 9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgParagraphNumber
