import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSettingsHandles: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.385 4.82a.77.77 0 0 1 .769-.769h3.077a.77.77 0 1 1 0 1.539h-3.077a.77.77 0 0 1-.77-.77ZM2 4.82a.77.77 0 0 1 .77-.769H14.05a.77.77 0 0 1 0 1.539H2.77A.77.77 0 0 1 2 4.82ZM9.18 12a.77.77 0 0 1 .769-.77H21.23a.77.77 0 0 1 0 1.54H9.949a.77.77 0 0 1-.77-.77ZM2 12a.77.77 0 0 1 .77-.77h3.076a.77.77 0 1 1 0 1.54H2.77A.77.77 0 0 1 2 12ZM17.385 19.18a.77.77 0 0 1 .769-.77h3.077a.77.77 0 1 1 0 1.539h-3.077a.77.77 0 0 1-.77-.77ZM2 19.18a.77.77 0 0 1 .77-.77H14.05a.77.77 0 0 1 0 1.539H2.77a.77.77 0 0 1-.769-.77Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.01 3.914a1.282 1.282 0 1 0-1.814 1.813 1.282 1.282 0 0 0 1.813-1.813Zm1.086-1.089a2.82 2.82 0 1 0-3.988 3.989 2.82 2.82 0 0 0 3.99-3.988h-.002ZM8.804 11.093a1.282 1.282 0 1 0-1.813 1.813 1.282 1.282 0 0 0 1.813-1.813Zm1.087-1.088a2.82 2.82 0 1 0-3.989 3.989 2.82 2.82 0 0 0 3.99-3.988v-.001ZM17.01 18.273a1.282 1.282 0 1 0-1.814 1.813 1.282 1.282 0 0 0 1.813-1.813Zm1.086-1.089a2.82 2.82 0 1 0-3.988 3.989 2.82 2.82 0 0 0 3.99-3.988h-.002Z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgSettingsHandles
