import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgArrowUp: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.442 9.24a.77.77 0 0 1 1.116 0l4.21 4.363a.84.84 0 0 1 0 1.157.77.77 0 0 1-1.116 0L12 10.975 8.348 14.76a.77.77 0 0 1-1.117 0 .84.84 0 0 1 0-1.157l4.21-4.363Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowUp
