import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFontItalic: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.682 5.273a.75.75 0 0 1 .546.91l-3 12a.75.75 0 0 1-1.456-.365l3-12a.75.75 0 0 1 .91-.545Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 18a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75ZM9.25 6a.75.75 0 0 1 .75-.75h7a.75.75 0 0 1 0 1.5h-7A.75.75 0 0 1 9.25 6Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFontItalic
