import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgTrash: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.818 5.077c.427 0 .773.344.773.77v13.076c0 .859.68 1.539 1.526 1.539h7.727c.862 0 1.565-.697 1.565-1.539V5.846c0-.425.346-.77.773-.77a.77.77 0 0 1 .773.77v13.077c0 1.708-1.413 3.077-3.11 3.077H8.116a3.067 3.067 0 0 1-3.072-3.077V5.846c0-.425.346-.77.773-.77Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 5.846c0-.425.346-.77.773-.77h15.454c.427 0 .773.345.773.77 0 .425-.346.77-.773.77H4.273a.771.771 0 0 1-.773-.77ZM9.167 2.77c0-.426.346-.77.772-.77h4.122a.77.77 0 0 1 .772.77.77.77 0 0 1-.772.768H9.939a.771.771 0 0 1-.772-.769ZM14.06 9.18a.77.77 0 0 1 .773.769v7.18a.77.77 0 0 1-.772.768.77.77 0 0 1-.773-.769v-7.18a.77.77 0 0 1 .773-.769ZM9.94 9.18a.77.77 0 0 1 .772.769v7.18a.77.77 0 0 1-.773.768.771.771 0 0 1-.772-.769v-7.18c0-.424.346-.769.772-.769Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTrash
