import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSearch: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.286 7.22a5.704 5.704 0 1 0-8.067 8.066 5.704 5.704 0 0 0 8.067-8.067Zm1.095-1.096A7.253 7.253 0 1 0 6.124 16.381 7.253 7.253 0 0 0 16.381 6.124Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.282 15.282a.774.774 0 0 1 1.095 0l3.396 3.396a.774.774 0 1 1-1.095 1.095l-3.396-3.396a.774.774 0 0 1 0-1.095Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSearch
