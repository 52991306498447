import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgShieldHeart: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.515 3.836a4.009 4.009 0 0 0-3.03 0L5.342 5.934c-.483.197-.8.666-.8 1.187v4.04c0 4.233 3.169 8.234 7.458 9.28 4.29-1.046 7.457-5.047 7.457-9.28V7.12c0-.52-.315-.989-.8-1.187l-5.142-2.098Zm.584-1.424a5.556 5.556 0 0 0-4.198 0L4.758 4.51A2.82 2.82 0 0 0 3 7.12v4.04c0 5.027 3.77 9.687 8.83 10.821a.776.776 0 0 0 .34 0c5.06-1.134 8.83-5.794 8.83-10.82V7.12a2.82 2.82 0 0 0-1.758-2.61L14.1 2.41Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.522 9.692c-.757 0-1.35.708-1.35 1.444 0 .786.522 1.577 1.268 2.228.36.313.738.563 1.057.73.16.084.295.143.398.179.052.018.086.027.105.031.019-.004.053-.013.105-.031.103-.036.238-.095.398-.178a5.703 5.703 0 0 0 1.056-.731c.747-.65 1.27-1.442 1.27-2.228 0-.735-.595-1.444-1.35-1.444-.449 0-.73.206-.902.4a.772.772 0 0 1-1.154 0 1.158 1.158 0 0 0-.901-.4Zm-2.893 1.444c0-1.4 1.109-2.982 2.893-2.982.614 0 1.104.183 1.478.423.374-.24.864-.423 1.478-.423 1.783 0 2.893 1.582 2.893 2.982 0 1.445-.921 2.624-1.797 3.387-.45.393-.928.71-1.351.932a4.525 4.525 0 0 1-.61.27c-.177.062-.396.121-.613.121-.217 0-.436-.06-.613-.12a4.525 4.525 0 0 1-.61-.27c-.423-.223-.9-.54-1.351-.933-.876-.763-1.797-1.942-1.797-3.387Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgShieldHeart
