import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgShareScreen: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5.12A2.75 2.75 0 0 1 5 2.37h5a.75.75 0 0 1 0 1.5H5c-.69 0-1.25.56-1.25 1.25v14c0 .69.56 1.25 1.25 1.25h14c.69 0 1.25-.56 1.25-1.25v-6.125a.75.75 0 1 1 1.5 0v6.125A2.75 2.75 0 0 1 19 21.87H5a2.75 2.75 0 0 1-2.75-2.75v-14Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.25 8.372a3.751 3.751 0 0 1 3.752-3.75h5.002a.75.75 0 0 1 0 1.5h-5.002a2.251 2.251 0 0 0-2.252 2.25v2.501a.75.75 0 0 1-1.5 0v-2.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.972 2.34a.75.75 0 0 1 1.061 0l2.501 2.5a.75.75 0 0 1 0 1.061l-2.501 2.502a.75.75 0 1 1-1.06-1.061l1.97-1.97-1.97-1.972a.75.75 0 0 1 0-1.06Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgShareScreen
