import { makeStyles, css } from '@conexasaude/styles'

const useStyles = makeStyles({
  root: css`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `,
})

export default useStyles
