import { SwitchProps } from './types'
import { makeStyles, css } from '@conexasaude/styles'

const aspect_ratio = '57%'

const useStyles = makeStyles<{
  width: SwitchProps['width']
  disabled: SwitchProps['disabled']
  checked: SwitchProps['checked']
}>({
  root: (theme, { width, checked }) => css`
    width: ${width || 40}px;
    position: relative;
    display: inline-flex;
    background: ${checked
      ? theme.colors.primary[500]
      : theme.colors.neutral[500]};
    border-radius: ${theme.borderRadius.pill};
    transition: all 0.35s ease-in-out;
  `,

  container: theme => css`
    border-radius: ${theme.borderRadius.pill};
    padding: 3px 3px calc(${aspect_ratio} - 3px) 3px;
    width: 100%;
    display: flex;
    align-items: center;
  `,

  dot: (theme, { checked }) => css`
    width: calc(${aspect_ratio} - 6px);
    height: 0;
    transition: all 0.2s ease-in-out;
    left: ${!checked ? '3px' : 'calc((100% - 57%) + 3px)'};
    right: auto;
    display: inline-block;
    position: absolute;

    :before {
      content: '';
      position: absolute;
      padding-bottom: 100%;
      width: 100%;
      border-radius: ${theme.borderRadius.circular};
      background: ${theme.colors.common.white};
    }
  `,

  input: css`
    margin: 0;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  `,

  disabled: theme => css`
    background: ${theme.colors.extra.disabled_bg};
    cursor: not-allowed;
    pointer-events: none;
    transition: all 0.35s ease-in-out;
  `,
})

export default useStyles
