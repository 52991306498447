import { ITypographySpecification } from '@conexasaude/styles'
import { SerializedStyles } from '@emotion/react'
import { ButtonHTMLAttributes, ReactNode } from 'react'

export enum ButtonStylesKeys {
  root = 'root',
  flat = 'flat',
  outlined = 'outlined',
  disabled = 'disabled',
  busy = 'busy',
  active = 'active',
  label = 'label',
  iconBox = 'iconBox',
  startIcon = 'startIcon',
  endIcon = 'endIcon',
}

export const sizes = ['sm', 'md', 'lg'] as const
export type Size = typeof sizes[number]

export const variants = ['flat', 'outlined'] as const
export type Variant = typeof variants[number]

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  styles?: Partial<Record<ButtonStylesKeys, SerializedStyles>>
  active?: boolean
  size?: Size
  disabled?: boolean
  busy?: boolean
  variant?: Variant
  fullWidth?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
}

export interface PropsToValidate {
  sizeProp: ButtonProps['size']
  variantProp: ButtonProps['variant']
}

export interface ValidatedProps {
  size: ButtonProps['size']
  variant: ButtonProps['variant']
}

export interface SizeStyles {
  spacing: {
    horizontal: number
    vertical: number
  }
  typography: ITypographySpecification
}
