import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgEyeOff: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.714 18.435a.768.768 0 0 1 .995-.442c.766.298 1.536.459 2.291.459.425 0 .77.346.77.774A.772.772 0 0 1 12 20c-.972 0-1.931-.207-2.846-.563a.776.776 0 0 1-.44-1.002Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.476 11.802a.777.777 0 0 1 .31 1.05c-1.016 1.892-2.465 3.664-4.144 4.97C15.967 19.123 14.014 20 12 20a.772.772 0 0 1-.77-.774c0-.428.345-.774.77-.774 1.57 0 3.202-.69 4.701-1.856 1.495-1.162 2.809-2.762 3.732-4.481a.767.767 0 0 1 1.043-.313Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.761 8.233a.766.766 0 0 1 1.085.086 16.398 16.398 0 0 1 1.94 2.83c.285.53.285 1.173 0 1.702a.766.766 0 0 1-1.041.313.777.777 0 0 1-.312-1.05.245.245 0 0 0 0-.228 14.858 14.858 0 0 0-1.757-2.562.778.778 0 0 1 .085-1.091ZM19.724 4.227c.3.302.3.792 0 1.095L5.364 19.773a.766.766 0 0 1-1.088 0 .778.778 0 0 1 0-1.095l14.36-14.451a.766.766 0 0 1 1.088 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.741 10.248a2.45 2.45 0 0 0-3.481 0 2.49 2.49 0 0 0 0 3.503c.3.303.3.793 0 1.095a.766.766 0 0 1-1.088 0 4.045 4.045 0 0 1 0-5.693 3.983 3.983 0 0 1 5.657 0c.3.302.3.792 0 1.095a.766.766 0 0 1-1.088 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.358 6.179C8.033 4.876 9.986 4 12 4c2.014 0 3.97.877 5.644 2.181a.778.778 0 0 1 .138 1.086.766.766 0 0 1-1.08.139C15.205 6.238 13.573 5.548 12 5.548c-1.57 0-3.202.69-4.701 1.856-1.495 1.163-2.809 2.763-3.732 4.482a.245.245 0 0 0 0 .23c.923 1.718 2.236 3.316 3.73 4.48a.778.778 0 0 1 .138 1.085.766.766 0 0 1-1.08.14c-1.676-1.307-3.125-3.077-4.142-4.97a1.805 1.805 0 0 1 0-1.7C3.23 9.256 4.679 7.483 6.358 6.178Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEyeOff
