import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgProfileCheckmark: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M19.0029 21.0038C20.108 21.0038 21.0038 20.108 21.0038 19.0029"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0037 19.0029V15.0012"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0029 21.0037H14.0008"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99917 2.99622H4.99709C3.89206 2.99622 2.99625 3.89202 2.99625 4.99705V7.9983"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0029 10.9995H15.0013C13.8962 10.9995 13.0004 10.1037 13.0004 8.99872V4.99705C13.0004 3.89202 13.8962 2.99622 15.0013 2.99622H19.0029C20.1079 2.99622 21.0038 3.89202 21.0038 4.99705V8.99872C21.0038 10.1037 20.1079 10.9995 19.0029 10.9995Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3637 6.14661L16.6629 7.84731L15.6405 6.82789"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99917 21.0037H3.49646C3.2202 21.0037 2.99625 20.7797 2.99625 20.5035V20.0973C2.99955 18.9417 3.93553 18.0057 5.09113 18.0024H8.40451C9.5601 18.0057 10.4961 18.9417 10.4994 20.0973V20.5035C10.4994 20.7797 10.2754 21.0037 9.99917 21.0037Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.74781 15.5014C5.64383 15.5014 4.74846 14.6072 4.747 13.5033C4.74553 12.3993 5.63852 11.5027 6.7425 11.4998C7.84648 11.4968 8.74423 12.3886 8.74866 13.4926C8.75077 14.0246 8.54091 14.5356 8.16545 14.9126C7.78999 15.2895 7.27986 15.5014 6.74781 15.5014Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgProfileCheckmark
