import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgJustifyCenter: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.251 4.002a.75.75 0 0 1 .75-.75h16.007a.75.75 0 0 1 0 1.5H4.001a.75.75 0 0 1-.75-.75ZM3.251 8.003a.75.75 0 0 1 .75-.75h16.007a.75.75 0 0 1 0 1.5H4.001a.75.75 0 0 1-.75-.75ZM3.251 12.005a.75.75 0 0 1 .75-.75h16.007a.75.75 0 0 1 0 1.5H4.001a.75.75 0 0 1-.75-.75ZM3.251 16.007a.75.75 0 0 1 .75-.75h16.007a.75.75 0 0 1 0 1.5H4.001a.75.75 0 0 1-.75-.75ZM7.253 20.008a.75.75 0 0 1 .75-.75h8.003a.75.75 0 0 1 0 1.5H8.003a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgJustifyCenter
