import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMenu: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.251 6a.75.75 0 0 1 .75-.75h17.992a.75.75 0 1 1 0 1.5H3.001A.75.75 0 0 1 2.25 6ZM2.251 12a.75.75 0 0 1 .75-.75h17.992a.75.75 0 1 1 0 1.5H3.001a.75.75 0 0 1-.75-.75ZM2.251 18a.75.75 0 0 1 .75-.75h17.992a.75.75 0 1 1 0 1.5H3.001a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMenu
