import { FunctionComponent } from 'react'
import useStyles from './styles'
import { TagProps } from './types'
import { useValidateState } from './utils'

export const Tag: FunctionComponent<TagProps> = ({
  styles: overrides,
  color: propColor,
  variant: propVariant,
  icon,
  label,
  children,
}) => {
  const { color, variant } = useValidateState({ propColor, propVariant })
  const styles = useStyles({ color, variant })

  return (
    <span css={[styles.root, overrides?.root]} data-testid="hero-tag">
      {variant === 'light' && icon && (
        <span css={[styles.icon, overrides?.icon]} />
      )}
      {children || label}
    </span>
  )
}
