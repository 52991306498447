import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgClip: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6 3.605a4.629 4.629 0 1 1 6.547 6.546l-7.232 7.232a2.866 2.866 0 0 1-4.052-4.052l5.486-5.486a.75.75 0 1 1 1.06 1.06l-5.486 5.486a1.366 1.366 0 0 0 1.932 1.932l7.232-7.232a3.13 3.13 0 1 0-4.426-4.426L6.43 11.897a4.892 4.892 0 1 0 6.92 6.92l4.389-4.39a.75.75 0 1 1 1.06 1.061l-4.389 4.39a6.392 6.392 0 0 1-9.04 0 6.392 6.392 0 0 1 0-9.041L12.6 3.605Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgClip
