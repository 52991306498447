import { SerializedStyles } from '@emotion/react'
import { AnchorHTMLAttributes, ButtonHTMLAttributes, ReactNode } from 'react'

export enum LinkStylesKeys {
  root = 'root',
  disabled = 'disabled',
  active = 'active',
  label = 'label',
  iconBox = 'iconBox',
  startIcon = 'startIcon',
  endIcon = 'endIcon',
}

export const acceptedRoots = ['button', 'a'] as const
export type AcceptedRoot = typeof acceptedRoots[number]

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  href?: undefined
}

export type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  href?: string
}
export interface LinkProps {
  styles?: Partial<Record<LinkStylesKeys, SerializedStyles>>
  active?: boolean
  disabled?: boolean
  fullWidth?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
  renderAs?: AcceptedRoot
}
export interface useStateStylesReturn {
  disabledStyles: SerializedStyles[]
}

export interface useStateStylesProps
  extends Pick<LinkProps, 'styles' | 'disabled'> {
  overrides?: LinkProps['styles']
}

export interface IUseStateStyles {
  (props: useStateStylesProps): useStateStylesReturn
}
