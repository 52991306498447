import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgClose: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.277 6.277c.37-.37.97-.37 1.34 0l10.105 10.106a.947.947 0 1 1-1.34 1.34L6.278 7.617a.947.947 0 0 1 0-1.34Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.723 6.277c.37.37.37.97 0 1.34L7.617 17.722a.947.947 0 1 1-1.34-1.34L16.383 6.278c.37-.37.97-.37 1.34 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgClose
