import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgLeftArrowCircle: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.949A7.05 7.05 0 0 0 2.95 10 7.05 7.05 0 0 0 10 17.051 7.05 7.05 0 0 0 17.052 10 7.05 7.05 0 0 0 10 2.949Zm0 15.384A8.333 8.333 0 0 1 1.667 10 8.333 8.333 0 0 1 10 1.667 8.333 8.333 0 0 1 18.334 10 8.333 8.333 0 0 1 10 18.333Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.94 10c0-.354.287-.641.641-.641h6.838a.641.641 0 0 1 0 1.282H6.581a.641.641 0 0 1-.64-.641Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.599 6.983c.25.25.25.656 0 .906L7.488 10l2.11 2.112a.641.641 0 1 1-.906.906l-2.564-2.564a.641.641 0 0 1 0-.906l2.564-2.564c.25-.25.656-.25.907 0Z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgLeftArrowCircle
