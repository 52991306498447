import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFilterCrossDelete: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 21H8C5.23858 21 3 18.7614 3 16V8C3 5.23858 5.23858 3 8 3H16C18.7614 3 21 5.23858 21 8V14"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 18L18 21"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 18L21 21"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.625 12.4996V14.3737C13.625 14.7524 13.411 15.0987 13.0722 15.2681L11.8222 15.8931C11.5123 16.0481 11.1441 16.0315 10.8493 15.8493C10.5545 15.6671 10.375 15.3452 10.375 14.9987V12.4996L8.38921 10.9677C8.14376 10.7784 8 10.4859 8 10.1759V9C8 8.44772 8.44772 8 9 8H15C15.5523 8 16 8.44772 16 9V10.1759C16 10.4859 15.8562 10.7784 15.6108 10.9677L13.625 12.4996Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgFilterCrossDelete
