import { SerializedStyles } from '@emotion/react'
import { HTMLAttributes } from 'react'

export enum TabScrollButtonStyleKeys {
  root = 'root',
  icon = 'icon',
}

export const directions = ['left', 'right'] as const
export type Direction = typeof directions[number]

type ButtonAttrs = HTMLAttributes<HTMLButtonElement>
type AttrProperties = 'onClick'
export interface TabScrollButtonProps
  extends Pick<ButtonAttrs, AttrProperties> {
  styles?: Partial<Record<TabScrollButtonStyleKeys, SerializedStyles>>
  direction: Direction
  disabled?: boolean
  buttonProps?: Omit<ButtonAttrs, AttrProperties>
}
