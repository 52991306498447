import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSigninSendShare: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 8.5A.5.5 0 0 1 2 8h8.253a.5.5 0 1 1 0 1H2a.5.5 0 0 1-.5-.5Z"
        fill="#4E535C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.646 5.896a.5.5 0 0 1 .708 0l2.25 2.25a.5.5 0 0 1 0 .708l-2.25 2.249a.5.5 0 1 1-.707-.707L9.543 8.5 7.646 6.604a.5.5 0 0 1 0-.708Z"
        fill="#4E535C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-9a2 2 0 0 1-2-2v-1.5a.5.5 0 0 1 1 0V13a1 1 0 0 0 1 1h9a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-9a1 1 0 0 0-1 1v1.5a.5.5 0 0 1-1 0V4Z"
        fill="#4E535C"
      />
    </svg>
  )
}

export default SvgSigninSendShare
