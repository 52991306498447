import { createContext, useContext } from 'react'
import { IDropdownContext } from './types'

export const DropdownContext = createContext<IDropdownContext>(null)

export const useDropdownContext = (): IDropdownContext => {
  const context = useContext(DropdownContext)

  return context
}
