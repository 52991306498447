import { ITypographySpecification } from '@conexasaude/styles'
import { SerializedStyles } from '@emotion/react'
import { ImgHTMLAttributes } from 'react'

export enum AvatarStylesKeys {
  root = 'root',
  avatar = 'avatar',
  label = 'label',
  image = 'image',
  notification = 'notification',
  avatarDefault = 'avatarDefault',
}

export const sizes = ['xs', 'sm', 'md', 'lg', 'xl'] as const

export type Size = typeof sizes[number]

export interface AvatarProps {
  styles?: Partial<Record<AvatarStylesKeys, SerializedStyles>>
  size?: Size
  alt?: string
  src?: string
  label?: string
  imgProps?: ImgHTMLAttributes<HTMLImageElement>
  hasNotifications?: boolean
}

export interface SizeStyles {
  avatar: {
    size: {
      imgHeight: number
      imgWidth: number
      notificationHeight: number
      notificationWidth: number
    }
    border: {
      notificationBorder: string
    }
    margin: number
  }
  name: {
    style: ITypographySpecification
  }
}
