import boxSizingReset from '@/utils/boxSizingReset'
import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'
import { isString } from '@/utils/isString'
import { DropdownProps } from './types'

type DropdownStyleProps = Pick<
  DropdownProps,
  'fullWidth' | 'popoverMaxHeight'
> & {
  isOpen: boolean
  hasLabel: boolean
}

const useStyles = makeStyles<DropdownStyleProps>({
  root: ({ spacing }, { fullWidth, hasLabel }) => css`
    ${boxSizingReset()}
    position: relative;
    width: ${fullWidth ? '100%' : 'auto'};
    display: inline-flex;
    flex-direction: column;
    ${hasLabel
      ? `
    padding: ${spacing(1.5)}px 0;
    `
      : ''}
    user-select: none;
  `,

  wrapper: css`
    position: relative;

    > svg {
      pointer-events: none;
      position: absolute;
      right: 16px;
      top: calc(50% - 24px / 2);
    }
  `,

  label: theme => css`
    ${typographyToStyle(theme.typography.label)};
    color: ${theme.colors.neutral[500]};
    background: ${theme.colors.common.white};
    position: absolute;
    left: 12px;
    top: -12px;
    padding: 0 4px;
    z-index: 1;
    transition: color 250ms ease-in-out;
  `,

  select: (theme, { isOpen, popoverMaxHeight }) => css`
    border: 1px solid #fafafc;
    background-color: ${theme.colors.common.white};
    box-shadow: ${theme.shadows[1]};
    border-radius: ${theme.borderRadius.sm};
    position: absolute;
    display: ${isOpen ? 'inline-flex' : 'none'};
    width: 100%;
    left: 0;
    top: 100%;
    z-index: 2;
    margin: 0;
    padding: 0;
    margin-top: 4px;
    flex-direction: column;
    align-items: center;
    max-height: ${!popoverMaxHeight
      ? '226px'
      : isString(popoverMaxHeight)
      ? `${popoverMaxHeight}`
      : `${popoverMaxHeight}px`};
    overflow-y: auto;

    > button {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &:first-of-type {
        border-top-left-radius: ${theme.borderRadius.sm};
        border-top-right-radius: ${theme.borderRadius.sm};
      }

      &:last-of-type {
        border-bottom-left-radius: ${theme.borderRadius.sm};
        border-bottom-right-radius: ${theme.borderRadius.sm};
      }
    }
  `,

  placeholder: (theme, { isOpen }) => css`
    pointer-events: all;
    cursor: pointer;
    border: none;
    border-radius: ${theme.borderRadius.sm};
    border-color: ${theme.colors.neutral[500]};
    border-style: solid;
    border-width: 1px;
    transition: all 0.2s ease-in-out;
    position: relative;
    background: transparent;
    box-sizing: border-box;
    padding: 10px ${theme.spacing(2)}px;
    padding-top: 12px;
    display: inline-flex;
    justify-content: space-between;
    position: relative;
    min-width: 217px;
    width: 100%;

    > svg {
      color: ${theme.colors.neutral[900]};
      transition: transform 250ms ease-in-out;
      transform: ${isOpen ? 'rotate(180deg)' : ''};
    }

    > p {
      margin: 0;
      color: ${theme.colors.neutral[500]};
      ${typographyToStyle(theme.typography.body2)};
    }
  `,

  disabled: theme => css`
    .hero-dropdown__placeholder {
      border-color: ${theme.colors.extra.disabled_font} !important;
      pointer-events: none !important;
    }
    .hero-dropdown__placeholder > p {
      color: ${theme.colors.extra.disabled_font} !important;
    }
    .hero-dropdown__label {
      color: ${theme.colors.extra.disabled_font} !important;
    }
  `,

  active: theme => css`
    .hero-dropdown__placeholder {
      border-color: ${theme.colors.primary[500]};
    }
  `,

  error: theme => css`
    .hero-dropdown__placeholder {
      border-color: ${theme.colors.danger[500]};
      color: ${theme.colors.neutral[900]};
    }
    .hero-dropdown__label {
      color: ${theme.colors.neutral[500]};
    }
  `,

  errorMessage: theme => css`
    display: inline-flex;
    align-items: center;
    margin-top: 4.5px;

    > svg {
      margin-right: 5px;
    }
    > p {
      color: ${theme.colors.danger[500]};
      ${typographyToStyle(theme.typography.microCopy)};
    }
  `,

  data_entered: theme => css`
    .hero-dropdown__placeholder {
      border-color: ${theme.colors.neutral[900]};
      > p {
        color: ${theme.colors.neutral[900]};
      }
    }
  `,

  nativeInput: css`
    left: 0;
    width: 100%;
    bottom: 0;
    opacity: 0;
    position: absolute;
    pointer-events: none;
  `,
})

export default useStyles
