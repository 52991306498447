import { css, makeStyles } from '@conexasaude/styles'

export const useStyles = makeStyles({
  toastWrapper: ({ spacing }) => css`
    pointer-events: auto;
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: ${spacing(2)}px;
    }
  `,

  toastOverride: css`
    @media (max-width: 800px) {
      max-width: 100%;
    }
  `,
})

export default useStyles
