import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgLock: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.839 10.718c-.713 0-1.29.573-1.29 1.282v7.18c0 .708.577 1.282 1.29 1.282H17.16c.713 0 1.29-.574 1.29-1.282V12c0-.709-.577-1.282-1.29-1.282H6.84ZM4 12a2.83 2.83 0 0 1 2.839-2.82H17.16A2.83 2.83 0 0 1 20 12v7.18A2.83 2.83 0 0 1 17.161 22H6.84A2.83 2.83 0 0 1 4 19.18V12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.538c-1.853 0-3.355 1.493-3.355 3.334v3.077c0 .425-.346.769-.774.769a.772.772 0 0 1-.774-.77V6.873C7.097 4.182 9.292 2 12 2c2.708 0 4.903 2.181 4.903 4.872v3.077c0 .425-.346.769-.774.769a.772.772 0 0 1-.774-.77V6.873c0-1.841-1.502-3.334-3.355-3.334Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgLock
