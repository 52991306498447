import { ProgressBarProps } from './types'
import { sizesToStyles } from './utils'
import { makeStyles, css } from '@conexasaude/styles'

const useStyles = makeStyles<{
  size: ProgressBarProps['size']
  progress: ProgressBarProps['progress']
  width: ProgressBarProps['width']
  color: ProgressBarProps['color']
}>({
  root: (theme, { size, width }) => css`
    width: ${width || '100%'};
    height: ${sizesToStyles[size].height}px;
    background: ${theme.colors.extra.disabled_bg};
    border-radius: ${theme.borderRadius.pill};
    position: relative;
  `,

  progress: (theme, { color, size, progress }) => css`
    transition: width 0.4s ease-in-out;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: ${sizesToStyles[size].height}px;
    width: ${progress}%;
    border-radius: ${theme.borderRadius.pill};
    background: ${color || theme.colors.primary[500]};
  `,
})

export default useStyles
