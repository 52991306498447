import { css, makeStyles } from '@conexasaude/styles'

export const useStyles = makeStyles({
  container: css`
    z-index: 99;
    position: fixed;
    padding: 4px;
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 2em);
  `,

  topRight: css`
    top: 1em;
    right: 1em;
    align-items: flex-end;
  `,
  topLeft: css`
    top: 1em;
    left: 1em;
    align-items: flex-start;
  `,
  bottomLeft: css`
    bottom: 1em;
    left: 1em;
    align-items: flex-start;
  `,
  bottomRight: css`
    bottom: 1em;
    right: 1em;
    align-items: flex-end;
  `,
})

export default useStyles
