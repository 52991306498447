import { boxSizingReset } from '@/styles/boxSizingReset'
import { makeStyles, css } from '@conexasaude/styles'
import { ToastContainerProps } from './types'

export const useStyles = makeStyles<{ zIndex: ToastContainerProps['zIndex'] }>({
  root: (theme, { zIndex }) => css`
    ${boxSizingReset}
    pointer-events: none;
    z-index: ${zIndex || 99};
  `,
})

export default useStyles
