import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useStyles from './styles'
import { TabsProps } from './types'
import { TabScrollButton } from '@/components/TabScrollButton'
import ownerWindow from '@/utils/ownerWindow'
import debounce from '@/utils/debounce'
import { Direction } from '../TabScrollButton/types'
import { css } from '@conexasaude/styles'
import { TabsContext } from './TabsContext'
import { useControlled } from '@/utils/useControlled'
import { createChainedFunction } from '@/utils/createChainedFunction'

export const Tabs: FunctionComponent<TabsProps> = ({
  styles: overrides,
  scrollButtons = true,
  defaultValue = null,
  children,
  value: propValue,
  onChange,
}) => {
  const styles = useStyles()
  const scrollerRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<HTMLDivElement>(null)
  const [disableLeftArrow, setDisableLeftArrow] = useState(true)
  const [disableRightArrow, setDisableRightArrow] = useState(true)
  const [value, setValue] = useControlled({
    default: defaultValue,
    controlled: propValue,
    name: 'Tabs',
  })

  const updateArrows = useCallback(() => {
    const scroller = scrollerRef.current
    const { scrollWidth, scrollLeft, offsetWidth } = scroller
    const showLeft = scrollLeft > 0
    const showRight = scrollLeft + offsetWidth < scrollWidth

    setDisableLeftArrow(!showLeft)
    setDisableRightArrow(!showRight)
  }, [])

  useEffect(() => {
    if (scrollButtons) {
      updateArrows()
    }
  }, [updateArrows, scrollButtons])

  const debouncedUpdateArrows = useRef(debounce(updateArrows))

  const handleScrollClick = useCallback((direction: Direction) => {
    const scroller = scrollerRef.current
    const { scrollLeft, offsetWidth } = scroller
    const scrollValue =
      scrollLeft + (direction === 'right' ? 1 : -1) * offsetWidth
    scroller.scrollTo({
      behavior: 'smooth',
      left: scrollValue,
    })
  }, [])

  useEffect(() => {
    if (!scrollButtons) return

    const winderson = ownerWindow(listRef.current)

    const handler = debounce<Window['onresize']>(updateArrows)

    winderson.addEventListener('resize', handler)

    return () => {
      handler.clear()
      winderson.removeEventListener('resize', handler)
    }
  }, [updateArrows, scrollButtons])

  const handleScroll = useCallback(() => {
    debouncedUpdateArrows.current()
  }, [])

  const handleChange = useMemo(
    () => createChainedFunction(setValue, onChange),
    [setValue, onChange],
  )

  return (
    <TabsContext.Provider
      value={{ value, onChange: handleChange, scroller: scrollerRef }}
    >
      <div data-testid="hero-tabs" css={[styles.root, overrides?.root]}>
        {scrollButtons && (
          <TabScrollButton
            direction="left"
            onClick={() => handleScrollClick('left')}
            disabled={disableLeftArrow}
            styles={{ root: css([styles.scrollButtonOverride, { left: 0 }]) }}
          />
        )}
        <div
          css={[styles.scroller, overrides?.scroller]}
          ref={scrollerRef}
          onScroll={(scrollButtons && handleScroll) || undefined}
        >
          <div css={[styles.list, overrides?.list]} ref={listRef}>
            {children}
          </div>
        </div>
        {scrollButtons && (
          <TabScrollButton
            direction="right"
            onClick={() => handleScrollClick('right')}
            disabled={disableRightArrow}
            styles={{ root: css([styles.scrollButtonOverride, { right: 0 }]) }}
          />
        )}
      </div>
    </TabsContext.Provider>
  )
}
