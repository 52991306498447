import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMinimize: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.246 2.996a.75.75 0 0 1 .75-.75h4.002a.75.75 0 1 1 0 1.5H3.746v3.252a.75.75 0 0 1-1.5 0V2.996Zm8.004 2A2.75 2.75 0 0 1 13 2.246h6.004a2.75 2.75 0 0 1 2.75 2.75V11a2.75 2.75 0 0 1-2.75 2.75H13A2.75 2.75 0 0 1 10.25 11V4.996ZM13 3.746c-.69 0-1.25.56-1.25 1.25V11c0 .69.56 1.25 1.25 1.25h6.004c.69 0 1.25-.56 1.25-1.25V4.996c0-.69-.56-1.25-1.25-1.25H13ZM8.529 15.471a.75.75 0 0 1 0 1.06l-3.722 3.723h2.191a.75.75 0 0 1 0 1.5H2.996a.75.75 0 0 1-.75-.75v-4.002a.75.75 0 0 1 1.5 0v2.19l3.722-3.72a.75.75 0 0 1 1.06 0Zm12.475.78a.75.75 0 0 1 .75.75v4.003a.75.75 0 0 1-.75.75h-4.002a.75.75 0 0 1 0-1.5h3.252v-3.252a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMinimize
