import { FunctionComponent } from 'react'
import useStyles from './styles'
import { FormGroupProps } from './types'
import { useStateStyles } from './utils'

export const FormGroup: FunctionComponent<FormGroupProps> = ({
  children,
  row = false,
  styles: overrides,
  rootProps = {},
}) => {
  const styles = useStyles({ row })

  const { directionStyles } = useStateStyles({ overrides, styles, row })

  return (
    <div {...rootProps} css={[styles.root, ...directionStyles]}>
      {children}
    </div>
  )
}
