import { createContext, useContext } from 'react'
import { ITabsContext } from './types'

export const TabsContext = createContext<ITabsContext>(null)

export const useTabsContext = (): ITabsContext => {
  const context = useContext(TabsContext)

  return context
}
