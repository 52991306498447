import { SerializedStyles } from '@emotion/react'
import { useMemo } from 'react'
import { FormGroupProps } from './types'

export interface useStateStylesReturn {
  directionStyles: SerializedStyles[]
}

export interface useStateStylesProps
  extends Pick<FormGroupProps, 'row' | 'styles'> {
  overrides?: FormGroupProps['styles']
}

export interface IUseStateStyles {
  (props: useStateStylesProps): useStateStylesReturn
}

export const useStateStyles: IUseStateStyles = ({ row, styles, overrides }) => {
  const directionStyles = useMemo(() => {
    if (row) return [styles.row, overrides?.row]

    return []
  }, [row, styles.row, overrides?.row])

  return {
    directionStyles,
  }
}
