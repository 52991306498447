import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgAudio: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.662 4.338v5.147a2.34 2.34 0 0 0 4.676 0V4.338a2.34 2.34 0 0 0-4.676 0ZM8 .5a3.839 3.839 0 0 0-3.838 3.838v5.147a3.839 3.839 0 0 0 7.676 0V4.338A3.84 3.84 0 0 0 8 .5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 8.9a.75.75 0 0 1 .75.75 6.25 6.25 0 1 0 12.5 0 .75.75 0 0 1 1.5 0 7.75 7.75 0 1 1-15.5 0A.75.75 0 0 1 1 8.9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 15.9a.75.75 0 0 1 .75.75v2.1a.75.75 0 1 1-1.5 0v-2.1A.75.75 0 0 1 8 15.9Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgAudio
