import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgExit: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.825 12a.77.77 0 0 1 .77-.77h5.135a.77.77 0 1 1 0 1.538h-5.135a.77.77 0 0 1-.77-.768Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.131 9.405c.301-.3.789-.3 1.09 0l2.053 2.051c.301.3.301.787 0 1.087l-2.054 2.052c-.3.3-.788.3-1.089 0-.3-.3-.3-.788 0-1.088L19.641 12l-1.51-1.508c-.3-.3-.3-.787 0-1.087Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.324 3.538c-.71 0-1.284.574-1.284 1.282v14.36c0 .708.574 1.282 1.284 1.282H17.65c.71 0 1.284-.574 1.284-1.283v-2.051a.77.77 0 0 1 1.54 0v2.051A2.822 2.822 0 0 1 17.649 22H5.324A2.822 2.822 0 0 1 2.5 19.18V4.82A2.822 2.822 0 0 1 5.324 2H17.65a2.822 2.822 0 0 1 2.824 2.82v2.051a.77.77 0 0 1-1.54 0v-2.05c0-.71-.575-1.283-1.284-1.283H5.324Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.405 10.205a.77.77 0 0 1 .77.769v2.051a.77.77 0 0 1-1.54 0v-2.051a.77.77 0 0 1 .77-.769Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.486 2a.77.77 0 0 1 .77.769v14.355c0 1.069-.604 2.044-1.56 2.523l-4.11 2.052c-1.876.937-4.086-.427-4.086-2.523a.77.77 0 0 1 1.54 0 1.284 1.284 0 0 0 1.858 1.147l4.107-2.05c.437-.22.711-.663.711-1.15V2.77a.77.77 0 0 1 .77-.769Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgExit
