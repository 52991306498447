import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSyringe: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.175 3.288c.3.3.3.785 0 1.085l-4.267 4.253c-.3.3-.787.3-1.088 0-.3-.3-.3-.785 0-1.084l4.267-4.254c.3-.3.787-.3 1.088 0ZM20.759 5.864c.3.3.301.785.001 1.085l-4.256 4.253c-.3.3-.788.3-1.088.002a.765.765 0 0 1-.002-1.085l4.257-4.254c.3-.3.787-.3 1.088 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.379 5.108c.3-.3.787-.3 1.088 0l7.487 7.454c.3.299.3.784 0 1.084-.3.3-.787.3-1.087 0l-7.488-7.453c-.3-.3-.3-.785 0-1.085ZM7.057 16.959c.3.3.3.785 0 1.084l-3.744 3.732c-.3.3-.787.3-1.088 0-.3-.3-.3-.785 0-1.084l3.744-3.732c.3-.3.787-.3 1.088 0ZM6.718 11.744c.3-.3.787-.3 1.087 0l1.498 1.493c.3.3.3.785 0 1.085-.3.299-.788.299-1.088 0l-1.497-1.493c-.3-.3-.3-.785 0-1.085ZM8.964 9.505c.3-.3.787-.3 1.088 0l1.497 1.493c.3.3.3.785 0 1.084-.3.3-.787.3-1.088 0L8.964 10.59c-.3-.3-.3-.785 0-1.084ZM16.02 2.225c.3-.3.788-.3 1.088 0l4.667 4.652c.3.3.3.785 0 1.084-.3.3-.788.3-1.088 0L16.02 3.31c-.3-.3-.3-.785 0-1.084Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.964 6.596c.3.3.3.785 0 1.084L6.26 14.364a1.275 1.275 0 0 0 0 1.807l1.648 1.641c.5.5 1.312.5 1.813 0l6.704-6.684c.3-.299.788-.299 1.088 0 .3.3.3.786 0 1.085l-6.705 6.684a2.826 2.826 0 0 1-3.987 0l-1.649-1.64a2.806 2.806 0 0 1 0-3.977l6.705-6.684c.3-.3.787-.3 1.087 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSyringe
