import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgPill: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.824 3.562c-.699-.7-1.833-.7-2.532 0-.7.7-.7 1.835 0 2.535.7.7 1.833.7 2.532 0 .7-.7.7-1.835 0-2.535Zm1.085-1.087a3.323 3.323 0 0 0-4.702 0 3.332 3.332 0 0 0 0 4.708 3.323 3.323 0 0 0 4.703 0 3.332 3.332 0 0 0 0-4.708ZM8.212 8.11a3.323 3.323 0 0 0-4.703 0 3.332 3.332 0 0 0 0 4.708 3.323 3.323 0 0 0 4.703 0 3.332 3.332 0 0 0 0-4.709Zm1.085-1.088a4.857 4.857 0 0 0-6.873 0 4.87 4.87 0 0 0 0 6.882 4.857 4.857 0 0 0 6.873 0 4.87 4.87 0 0 0 0-6.882Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10.463c0-.424.344-.768.767-.768h8.186a.768.768 0 0 1 0 1.537H1.767A.768.768 0 0 1 1 10.463ZM20.784 11.911a2.321 2.321 0 0 0-3.286 0l-5.075 5.081a2.327 2.327 0 0 0 0 3.29 2.321 2.321 0 0 0 3.286 0l5.075-5.081a2.328 2.328 0 0 0 0-3.29Zm-4.371-1.086a3.855 3.855 0 0 1 5.457 0 3.865 3.865 0 0 1 0 5.463l-5.076 5.08a3.855 3.855 0 0 1-5.456 0 3.865 3.865 0 0 1 0-5.462l5.075-5.081Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.893 13.383c.3-.3.785-.3 1.085 0l4.339 4.343a.769.769 0 0 1-1.086 1.087l-4.338-4.344c-.3-.3-.3-.786 0-1.086Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPill
