import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSupport: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10.62a.25.25 0 0 0-.25.25v5c0 .138.112.25.25.25h1c.69 0 1.25-.56 1.25-1.25v-3c0-.69-.56-1.25-1.25-1.25h-1Zm-1.75.25c0-.966.784-1.75 1.75-1.75h1a2.75 2.75 0 0 1 2.75 2.75v3A2.75 2.75 0 0 1 19 17.62h-1a1.75 1.75 0 0 1-1.75-1.75v-5ZM5 10.62c-.69 0-1.25.56-1.25 1.25v3c0 .69.56 1.25 1.25 1.25h1a.25.25 0 0 0 .25-.25v-5a.25.25 0 0 0-.25-.25H5Zm-2.75 1.25A2.75 2.75 0 0 1 5 9.12h1c.966 0 1.75.784 1.75 1.75v5A1.75 1.75 0 0 1 6 17.62H5a2.75 2.75 0 0 1-2.75-2.75v-3Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.75 9.37a7.25 7.25 0 1 1 14.5 0v.5a.75.75 0 0 1-1.5 0v-.5a5.75 5.75 0 1 0-11.5 0v.5a.75.75 0 0 1-1.5 0v-.5ZM11.375 19.37a.5.5 0 0 0 0 1h1.25a.5.5 0 0 0 0-1h-1.25Zm-2 .5a2 2 0 0 1 2-2h1.25a2 2 0 0 1 0 4h-1.25a2 2 0 0 1-2-2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 16.12a.75.75 0 0 1 .75.75v1A2.75 2.75 0 0 1 16 20.62h-2.125a.75.75 0 0 1 0-1.5H16c.69 0 1.25-.56 1.25-1.25v-1a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSupport
