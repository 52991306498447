import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgItemDocumentsFiles: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        d="M7.748 4.663h-1.75a2 2 0 0 0-2.001 2.001V19.67a2 2 0 0 0 2 2h2.001M20.003 7.665v-1a2 2 0 0 0-2-2.002h-1.751"
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={10.999}
        y={10.541}
        width={9.004}
        height={11.13}
        rx={1.125}
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={7.749}
        y={2.663}
        width={8.504}
        height={4.002}
        rx={2}
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgItemDocumentsFiles
