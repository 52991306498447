import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFilter: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4.797C3 3.805 3.806 3 4.8 3h14.4c.994 0 1.8.805 1.8 1.797v2.656c0 .477-.19.933-.527 1.27l-5.356 5.349c-.301.3-.79.3-1.091 0a.77.77 0 0 1 0-1.09l5.356-5.347a.255.255 0 0 0 .075-.182V4.797a.257.257 0 0 0-.257-.256H4.8a.257.257 0 0 0-.257.256v2.656c0 .07.027.134.074.18l.001.002 5.356 5.347a.77.77 0 0 1 0 1.09c-.301.3-.79.3-1.09 0L3.528 8.725h-.001A1.793 1.793 0 0 1 3 7.453V4.797Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.429 12.757a.77.77 0 0 1 .771.77v6.418c0 .334.315.58.64.498l2.57-.642a.514.514 0 0 0 .39-.498v-5.776a.77.77 0 0 1 1.543 0v5.776c0 .943-.643 1.764-1.559 1.993l-2.571.642a2.057 2.057 0 0 1-2.556-1.993v-6.418a.77.77 0 0 1 .772-.77Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFilter
