import { makeStyles, css } from '@conexasaude/styles'

const useStyles = makeStyles({
  root: ({ colors, borderWidths }) => css`
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 32px;
    &:after {
      content: '';
      width: 100%;
      height: ${borderWidths.hairline};
      background-color: ${colors.neutral[100]};
      position: absolute;
      left: 0;
      bottom: 0;
    }
  `,

  list: css`
    display: flex;
  `,

  scroller: css`
    flex: 1;
    display: inline-block;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
    overflow: auto;
    white-space: nowrap;
    overflow-x: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  `,

  scrollButtonOverride: theme => css`
    position: absolute;
    top: 0;
    z-index: 2;
    height: 100%;
    background: ${theme.colors.common.white};
  `,
})

export default useStyles
