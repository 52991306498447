import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMiniplayer: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.283 14c0-.966.783-1.75 1.75-1.75H20c.966 0 1.75.784 1.75 1.75v6A1.75 1.75 0 0 1 20 21.75h-8.969A1.75 1.75 0 0 1 9.283 20v-6Zm1.75-.25a.25.25 0 0 0-.25.25v6c0 .139.111.25.25.25H20a.25.25 0 0 0 .25-.25v-6a.25.25 0 0 0-.25-.25h-8.969ZM5.47 6.47a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6.538a.75.75 0 0 1 .75.75V10a.75.75 0 0 1-.75.75H6.29a.75.75 0 1 1 0-1.5H8.25V7.288a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4.75c-.69 0-1.25.56-1.25 1.25v7c0 .69.56 1.25 1.25 1.25h2a.75.75 0 1 1 0 1.5H5A2.75 2.75 0 0 1 2.252 13V6a2.75 2.75 0 0 1 2.75-2.75h11A2.75 2.75 0 0 1 18.75 6v4a.75.75 0 0 1-1.5 0V6c0-.69-.56-1.25-1.25-1.25H5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMiniplayer
