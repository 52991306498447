import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgDocumentCalculator: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.0037 12.4998V21.0033C22.0037 21.5559 21.5558 22.0038 21.0033 22.0038H15.0008C14.4483 22.0038 14.0004 21.5559 14.0004 21.0033V12.4998C14.0004 11.9473 14.4483 11.4994 15.0008 11.4994H21.0033C21.5558 11.4994 22.0037 11.9473 22.0037 12.4998Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.0037 15.0008H14.0004"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49771 7.99789H16.0013"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49771 11.4994H9.99875"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49771 15.0008H9.99875"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.9992 19.0025H5.99708C4.89205 19.0025 3.99625 18.1067 3.99625 17.0016V5.99705C3.99625 4.89202 4.89205 3.99622 5.99708 3.99622H18.0021C19.1071 3.99622 20.0029 4.89202 20.0029 5.99705V8.9983"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7516 17.2018C16.7239 17.2018 16.7015 17.2242 16.7015 17.2518C16.7015 17.2794 16.7239 17.3018 16.7516 17.3018C16.7792 17.3018 16.8016 17.2794 16.8016 17.2518C16.8016 17.2242 16.7792 17.2018 16.7516 17.2018"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2526 17.2018C19.225 17.2018 19.2026 17.2242 19.2026 17.2518C19.2026 17.2794 19.225 17.3018 19.2526 17.3018C19.2802 17.3018 19.3026 17.2794 19.3026 17.2518C19.3026 17.2242 19.2802 17.2018 19.2526 17.2018"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7516 19.7028C16.7239 19.7028 16.7015 19.7251 16.7015 19.7528C16.7015 19.7804 16.7239 19.8028 16.7516 19.8028C16.7792 19.8028 16.8016 19.7804 16.8016 19.7528C16.8016 19.7251 16.7792 19.7028 16.7516 19.7028"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2526 19.7028C19.225 19.7028 19.2026 19.7251 19.2026 19.7528C19.2026 19.7804 19.225 19.8028 19.2526 19.8028C19.2802 19.8028 19.3026 19.7804 19.3026 19.7528C19.3026 19.7251 19.2802 19.7028 19.2526 19.7028"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgDocumentCalculator
