import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFileText: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0033 7.99867H16.0016C15.4491 7.99867 15.0012 7.55076 15.0012 6.99825V2.99658"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.99622"
        y="7.99841"
        width="10.0042"
        height="10.0042"
        rx="3"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.74731 11.2499L6.24752 14.7513H6.57666L7.99825 11.7501L9.41984 14.7513H9.74898L10.2492 11.2499"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99744 7.99867V4.99742C5.99744 3.89239 6.89324 2.99658 7.99827 2.99658H15.1733C15.7039 2.99658 16.2127 3.20757 16.5878 3.58283L19.417 6.412C19.7923 6.78715 20.0033 7.29598 20.0033 7.82659V19.0032C20.0033 20.1083 19.1075 21.0041 18.0024 21.0041H7.99827C6.89324 21.0041 5.99744 20.1083 5.99744 19.0032V18.0028"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgFileText
