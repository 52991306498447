import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgTimeZone: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.754 3.5a8.254 8.254 0 0 0 0 16.508.75.75 0 1 1 0 1.5C6.367 21.508 2 17.14 2 11.754S6.367 2 11.754 2s9.754 4.367 9.754 9.754a.75.75 0 1 1-1.5 0A8.254 8.254 0 0 0 11.754 3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.513 8.752a.75.75 0 0 1 .75-.75h16.981a.75.75 0 0 1 0 1.5H3.264a.75.75 0 0 1-.75-.75ZM2.514 14.755a.75.75 0 0 1 .75-.75h8.49a.75.75 0 1 1 0 1.5h-8.49a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.273 3.433a2.865 2.865 0 0 1 4.962-.001 16.234 16.234 0 0 1 2.27 8.324.75.75 0 1 1-1.5-.004 14.733 14.733 0 0 0-2.063-7.559l-.005-.008a1.366 1.366 0 0 0-2.366 0l-.004.007a14.885 14.885 0 0 0 0 15.125l.003.005c.245.424.697.685 1.187.687a.75.75 0 0 1-.004 1.5 2.876 2.876 0 0 1-2.48-1.432 16.385 16.385 0 0 1 0-16.644Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.756 14.505a3.252 3.252 0 1 0 0 6.503 3.252 3.252 0 0 0 0-6.503Zm0-1.5a4.752 4.752 0 1 0 0 9.503 4.752 4.752 0 0 0 0-9.503Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.294 15.799c.345 0 .624.279.624.623v1.488h1.129a.624.624 0 1 1 0 1.247h-1.753a.624.624 0 0 1-.623-.623v-2.111c0-.345.279-.624.623-.624Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgTimeZone
