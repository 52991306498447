import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFaceId: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.385 8.236a.77.77 0 0 1 .769.77v1.538a.77.77 0 0 1-1.539 0V9.005a.77.77 0 0 1 .77-.77ZM16.615 8.236a.77.77 0 0 1 .77.77v1.538a.77.77 0 0 1-1.539 0V9.005a.77.77 0 0 1 .77-.77ZM12.513 8.253a.77.77 0 0 1 .77.77v3.59c0 .99-.805 1.794-1.796 1.794h-.513a.77.77 0 1 1 0-1.538h.513a.257.257 0 0 0 .257-.257v-3.59a.77.77 0 0 1 .769-.769ZM8.132 15.72c.3-.3.787-.3 1.088 0a3.933 3.933 0 0 0 5.561 0 .77.77 0 0 1 1.088 1.088 5.471 5.471 0 0 1-7.737 0 .77.77 0 0 1 0-1.088ZM2 4.82A2.82 2.82 0 0 1 4.82 2h2.655a.77.77 0 1 1 0 1.538H4.82c-.709 0-1.283.574-1.283 1.283v2.654a.77.77 0 1 1-1.538 0V4.82ZM21.23 15.756a.77.77 0 0 1 .77.77v2.654A2.82 2.82 0 0 1 19.18 22h-2.655a.77.77 0 0 1 0-1.538h2.655c.708 0 1.282-.574 1.282-1.282v-2.655a.77.77 0 0 1 .769-.77ZM2.77 15.756a.77.77 0 0 1 .768.77v2.654c0 .708.574 1.282 1.283 1.282h2.654a.77.77 0 0 1 0 1.538H4.82A2.82 2.82 0 0 1 2 19.18v-2.655a.77.77 0 0 1 .77-.77ZM15.756 2.77a.77.77 0 0 1 .77-.77h2.654A2.82 2.82 0 0 1 22 4.82v2.655a.77.77 0 1 1-1.538 0V4.82c0-.709-.574-1.283-1.282-1.283h-2.655a.77.77 0 0 1-.77-.769Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFaceId
