import { useMemo } from 'react'
import {
  IUseStateStyles,
  AcceptedRoot,
  acceptedRoots,
  LinkProps,
  ButtonProps,
  AnchorProps,
} from './types'

export const isAnchorProps = (
  props: (ButtonProps | AnchorProps) & LinkProps,
): props is AnchorProps => props.renderAs === 'a'

export const isAcceptedRoot = (value: AcceptedRoot): value is AcceptedRoot =>
  acceptedRoots.includes(value)

export const useStateStyles: IUseStateStyles = ({
  disabled,
  overrides,
  styles,
}) => {
  const disabledStyles = useMemo(() => {
    if (disabled) return [styles.disabled, overrides?.disabled]

    return []
  }, [disabled, styles.disabled, overrides?.disabled])
  return {
    disabledStyles,
  }
}
