import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSend: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.874 5.995C2.27 4.308 3.979 2.672 5.639 3.442l14.429 6.697c1.577.731 1.577 2.989 0 3.72l-14.43 6.7c-1.659.769-3.367-.867-2.764-2.554l2.143-6.008-2.143-6.002Zm2.133-1.193a.54.54 0 0 0-.72.689l2.232 6.254a.75.75 0 0 1 0 .504l-2.232 6.26c-.171.478.31.879.72.689l14.43-6.7a.555.555 0 0 0 0-.999L5.006 4.802Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.06 12a.75.75 0 0 1 .75-.75H20.5a.75.75 0 0 1 0 1.5H5.81a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSend
