import { useMemo } from 'react'
import { IUseStateStyles } from './types'

export const useStateStyles: IUseStateStyles = ({
  active,
  error,
  disabled,
  value,
  styles,
  overrides,
}) => {
  const activeStyles = useMemo(() => {
    if (active) return [styles.active, overrides?.active]
    return []
  }, [active, styles.active, overrides?.active])

  const expandedStyles = useMemo(() => {
    if (active && value) return [styles.active, overrides?.active]

    return []
  }, [active, value, styles.active, overrides?.active])

  const dataEnteredStyles = useMemo(() => {
    if (value && !error) return [styles.data_entered, overrides?.data_entered]

    return []
  }, [error, value, styles.data_entered, overrides?.data_entered])

  const errorStyles = useMemo(() => {
    if (error) return [styles.error, overrides?.error]

    return []
  }, [error, styles.error, overrides?.error])

  const disabledStyles = useMemo(() => {
    if (disabled) return [styles.disabled, overrides?.disabled]

    return []
  }, [disabled, styles.disabled, overrides?.disabled])

  return {
    activeStyles,
    dataEnteredStyles,
    expandedStyles,
    disabledStyles,
    errorStyles,
  }
}
