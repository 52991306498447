import { theme } from '@conexasaude/styles'
import { useMemo } from 'react'
import { AvatarProps, Size, sizes, SizeStyles } from './types'

export const isSize = (value: Size): value is Size => sizes.includes(value)

export interface ValidatedProps {
  size: AvatarProps['size']
}

export interface UseValidatePropsParams {
  sizeProp: AvatarProps['size']
}

export interface UseValidateProps {
  (propsToValidate: UseValidatePropsParams): ValidatedProps
}

export const useValidateProps: UseValidateProps = ({ sizeProp }) => {
  const size = useMemo<AvatarProps['size']>(() => {
    if (!isSize(sizeProp)) {
      console.warn(
        'The provided size property is not a valid size. A default value will be used.',
      )
      return 'md'
    }
    return sizeProp
  }, [sizeProp])
  return { size }
}

export const getSizeStyles = (size: AvatarProps['size']): SizeStyles => {
  const sizesToStyles = {
    xs: {
      avatar: {
        size: {
          imgHeight: theme.spacing(3),
          imgWidth: theme.spacing(3),
          notificationHeight: 8,
          notificationWidth: 8,
        },
        border: {
          notificationBorder: '2px solid #FFFFFF',
        },
        margin: 8,
      },
      name: {
        style: theme.typography.microCopy,
      },
    },
    sm: {
      avatar: {
        size: {
          imgHeight: theme.spacing(4),
          imgWidth: theme.spacing(4),
          notificationHeight: 10,
          notificationWidth: 10,
        },
        border: {
          notificationBorder: '2px solid #FFFFFF',
        },
        margin: 12,
      },
      name: {
        style: theme.typography.microCopy,
      },
    },
    md: {
      avatar: {
        size: {
          imgHeight: theme.spacing(5),
          imgWidth: theme.spacing(5),
          notificationHeight: 12,
          notificationWidth: 12,
        },
        border: {
          notificationBorder: '2px solid #FFFFFF',
        },
        margin: 14,
      },
      name: {
        style: theme.typography.body2,
      },
    },
    lg: {
      avatar: {
        size: {
          imgHeight: theme.spacing(6),
          imgWidth: theme.spacing(6),
          notificationHeight: 12,
          notificationWidth: 12,
        },
        border: {
          notificationBorder: '2px solid #FFFFFF',
        },
        margin: 16,
      },
      name: {
        style: theme.typography.body1,
      },
    },
    xl: {
      avatar: {
        size: {
          imgHeight: theme.spacing(7),
          imgWidth: theme.spacing(7),
          notificationHeight: 14,
          notificationWidth: 14,
        },
        border: {
          notificationBorder: '2px solid #FFFFFF',
        },
        margin: 16,
      },
      name: {
        style: theme.typography.body1,
      },
    },
  }

  const currStyles = sizesToStyles[size]
  return currStyles
}
