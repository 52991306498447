import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgDocumentAtestado: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.783 7.398a3.751 3.751 0 0 1 1.677-.396h1.293a.75.75 0 1 1 0 1.5H6.46c-.35 0-.694.081-1.006.238l-.586.292a2.25 2.25 0 0 0-.924.856l-1.55 2.585a.75.75 0 1 1-1.287-.772l1.551-2.585c.367-.612.901-1.106 1.54-1.425m0 0 .585-.293M17.257 5.001a.75.75 0 0 1 .75.75v3.001a.75.75 0 0 1-1.5 0v-3a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.006 7.252a.75.75 0 0 1 .75-.75h3.001a.75.75 0 0 1 0 1.5h-3.001a.75.75 0 0 1-.75-.75ZM16.006 12.754a.75.75 0 0 1 .75-.75h2.001a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM14.005 15.755a.75.75 0 0 1 .75-.75h4.002a.75.75 0 0 1 0 1.5h-4.002a.75.75 0 0 1-.75-.75ZM10.414 10.033a2.396 2.396 0 0 1 3.688 3.025l-2.994 4.49A4.751 4.751 0 0 1 8.77 19.38l-5.765 2.083a.75.75 0 0 1-.51-1.41l5.765-2.084a3.252 3.252 0 0 0 1.6-1.254l2.994-4.49a.896.896 0 0 0-1.38-1.131l-2.19 2.19a.75.75 0 0 1-.531.22h-2a.75.75 0 0 1 0-1.5h1.69l1.971-1.97Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.003 4.25A2.25 2.25 0 0 1 9.253 2h11.005a2.25 2.25 0 0 1 2.25 2.25v15.007a2.25 2.25 0 0 1-2.25 2.25H9.253a2.25 2.25 0 0 1-2.25-2.25v-.307a.75.75 0 0 1 1.5 0v.307c0 .414.336.75.75.75h11.005a.75.75 0 0 0 .75-.75V4.25a.75.75 0 0 0-.75-.751H9.253a.75.75 0 0 0-.75.75v8.504a.75.75 0 0 1-1.5 0V4.251Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDocumentAtestado
