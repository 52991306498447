import { css, SerializedStyles } from '@emotion/react'

const boxSizingReset = (): SerializedStyles => css`
  box-sizing: border-box;

  * {
    &,
    &:before,
    &:after {
      box-sizing: inherit;
    }
  }
`

export default boxSizingReset
