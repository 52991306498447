import { ToastProps } from './types'
import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'
import { themeToStyle } from './utils'

export const useStyles = makeStyles<{
  theme: ToastProps['theme']
  variant: ToastProps['variant']
  hasAction: boolean
}>({
  root: (theme, { theme: toastTheme, hasAction }) => css`
    max-width: ${hasAction ? '764px' : '578px'};
    display: inline-flex;
    padding: ${hasAction ? '12px 16px' : '16px'};
    background: ${themeToStyle(toastTheme, theme.colors).background};
    border-radius: ${theme.borderRadius.sm};
    box-shadow: ${theme.shadows[2]};
  `,

  content: (theme, { theme: toastTheme, hasAction }) => css`
    display: inline-flex;
    align-items: center;
    color: ${themeToStyle(toastTheme, theme.colors).color};
    ${hasAction ? '' : 'margin-right: 32px'};

    > svg {
      margin-right: 16px;
      flex-shrink: 0;
      flex-basis: auto;
    }

    > span {
      ${typographyToStyle(theme.typography.body2)};
    }
  `,

  actions: css`
    display: flex;
    align-items: center;
  `,

  action: (theme, { theme: toastTheme }) => css`
    margin: 0 32px;
    &,
    &:hover,
    &:active {
      ${toastTheme === 'dark'
        ? `
      color: ${theme.colors.info[100]};
    `
        : ''}
    }
  `,

  icon: css`
    margin-right: 18px;
  `,

  close: (theme, { theme: toastTheme }) => css`
    background: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    display: inline-flex;
    padding: 0;
    color: ${themeToStyle(toastTheme, theme.colors).color};

    > svg > path {
      fill: ${themeToStyle(toastTheme, theme.colors).closeIcon};
    }
  `,
})

export default useStyles
