import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'
import { ButtonProps } from './types'
import { getSizeStyles } from './utils'

const useStyles = makeStyles<{
  size: ButtonProps['size']
  variant: ButtonProps['variant']
  fullWidth: ButtonProps['fullWidth']
  active: ButtonProps['active']
}>({
  root: ({ borderRadius, borderWidths, ...theme }, { size, fullWidth }) => {
    const { spacing, typography } = getSizeStyles(size, theme)

    return css`
      outline: none;
      cursor: pointer;
      padding: ${spacing.vertical}px ${spacing.horizontal}px;
      border-radius: ${borderRadius.sm};
      ${typographyToStyle(typography)}
      display:inline-flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      border: ${borderWidths.thin} solid;
      width: ${fullWidth ? '100%' : 'auto'};
    `
  },

  flat: ({ colors }, { active }) => css`
    background-color: ${colors.primary[active ? 800 : 500]};
    color: ${colors.common.white};
    border-color: ${colors.primary[500]};

    &:hover {
      background-color: ${colors.primary[400]};
      border-color: ${colors.primary[400]};
    }

    &:active {
      background-color: ${colors.primary[600]};
      border-color: ${colors.primary[600]};
    }
  `,

  outlined: ({ colors }, { active }) => css`
    background-color: transparent;
    color: ${colors.primary[active ? 800 : 500]};
    border-color: ${colors.primary[active ? 800 : 500]};

    &:hover {
      color: ${colors.primary[400]};
      border-color: ${colors.primary[400]};
    }

    &:active {
      color: ${colors.primary[600]};
      border-color: ${colors.primary[600]};
    }
  `,

  disabled: ({ colors }, { variant }) => {
    const { disabled_bg, disabled_font } = colors.extra

    return css`
      color: ${disabled_font};
      pointer-events: none;
      user-select: none;
      cursor: default;
      border-color: ${disabled_bg};
      ${variant === 'flat' ? `background-color: ${disabled_bg};` : ''}
    `
  },

  busy: css`
    pointer-events: none;
    user-select: none;
    cursor: default;
  `,

  label: css`
    position: relative;
  `,

  iconBox: css`
    display: flex;
  `,

  startIcon: ({ spacing }) => css`
    margin-right: ${spacing(1)}px;
  `,

  endIcon: ({ spacing }) => css`
    margin-left: ${spacing(1)}px;
  `,
})

export default useStyles
