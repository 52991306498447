import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'

const useStyles = makeStyles({
  root: ({ colors }) => css`
    padding: 7px 16px;
    height: 45px;
    border: none;
    margin: 0;
    pointer-events: all;
    cursor: pointer;
    width: 100%;
    background: transparent;
    transition: all 250ms ease-in-out;
    flex-shrink: 0;
    flex-basis: auto;

    &:hover {
      background: ${colors.primary[100]};

      > span {
        color: ${colors.primary[500]};
      }
    }
  `,

  label: ({ colors, typography }) => css`
    ${typographyToStyle(typography.body1)};
    color: ${colors.neutral[900]};
  `,
})

export default useStyles
