import { SerializedStyles } from '@emotion/react'

export enum TagStylesKeys {
  root = 'root',
  icon = 'icon',
}

export const colors = [
  'primary',
  'secondary',
  'success',
  'danger',
  'warning',
  'info',
  'neutral',
] as const

export type Color = typeof colors[number]

export const variants = ['solid', 'light'] as const

export type Variant = typeof variants[number]
export interface TagProps {
  styles?: Partial<Record<TagStylesKeys, SerializedStyles>>
  color?: Color
  variant?: Variant
  icon?: boolean
  label?: string
}
