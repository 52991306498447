import { SerializedStyles } from '@emotion/react'
import { MouseEventHandler, ReactNode } from 'react'

export enum ToastStyleKeys {
  root = 'root',
  action = 'action',
  actions = 'actions',
  content = 'content',
  close = 'close',
}

export const themes = ['dark', 'light'] as const
export type Theme = typeof themes[number]

export const variants = ['success', 'warning', 'danger'] as const
export type Variants = typeof variants[number]

export interface ToastProps {
  styles?: Partial<Record<ToastStyleKeys, SerializedStyles>>
  theme?: Theme
  variant?: Variants
  icon?: ReactNode
  action?: MouseEventHandler<HTMLButtonElement>
  actionLabel?: string
  onClose?(): void
}
