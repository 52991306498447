import { makeStyles, css } from '@conexasaude/styles'

export const useStyles = makeStyles<{ isLeft: boolean }>({
  root: css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.1s ease-in-out;
    transition-delay: 0;

    &:disabled {
      opacity: 0;
      pointer-events: none;
    }
  `,
  icon: (theme, { isLeft }) => css`
    transform: ${isLeft ? `rotate(180deg)` : `rotate(0)`};
  `,
})

export default useStyles
