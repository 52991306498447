import { useMemo } from 'react'
import { ProgressBarProps, Size, sizes } from './types'

export const isSize = (value: Size): value is Size => sizes.includes(value)
export interface ValidatedProps {
  size: ProgressBarProps['size']
}
export interface ValidateProgress {
  progress: ProgressBarProps['progress']
}

export const useValidateProps = ({
  size: sizeProp,
}: Partial<ProgressBarProps>): ValidatedProps => {
  const size = useMemo(() => {
    if (!isSize(sizeProp)) {
      console.warn(
        'The provided size property is not a valid size. A default value will be used.',
      )
      return 'sm'
    }
    return sizeProp
  }, [sizeProp])
  return { size }
}

export const useValidateProgress = ({
  progress: propProgress,
}: Partial<ProgressBarProps>): ValidateProgress => {
  const progress = useMemo(() => {
    if (propProgress < 0) {
      console.warn(
        'The provided progress property is not a valid number. A default value will be used.',
      )
      return 0
    }

    if (propProgress > 100) {
      console.warn(
        'The provided progress property is not a valid number. A default value will be used.',
      )
      return 100
    }
    return propProgress
  }, [propProgress])
  return { progress }
}

export const sizesToStyles = {
  sm: {
    height: 4,
  },
  md: {
    height: 8,
  },
}
