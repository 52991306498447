import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFileWarningError: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0034 7.99867H16.0018C15.4492 7.99867 15.0013 7.55076 15.0013 6.99825V2.99658"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99744 7.99867V4.99742C5.99744 3.89239 6.89324 2.99658 7.99827 2.99658H15.1733C15.7039 2.99658 16.2127 3.20757 16.5878 3.58283L19.417 6.412C19.7923 6.78715 20.0033 7.29598 20.0033 7.82659V19.0032C20.0033 20.1083 19.1075 21.0041 18.0024 21.0041H7.99827C6.89324 21.0041 5.99744 20.1083 5.99744 19.0032V18.0028"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99826 12.8151V10.9794"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99811 14.8403C7.97406 14.8403 7.951 14.8499 7.93409 14.8671C7.91717 14.8842 7.90781 14.9073 7.90807 14.9314C7.90808 14.9683 7.93037 15.0015 7.96451 15.0156C7.99865 15.0296 8.03788 15.0216 8.06384 14.9954C8.0898 14.9691 8.09734 14.9298 8.08293 14.8958C8.06852 14.8618 8.03502 14.8399 7.99811 14.8403"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.99658"
        y="7.99841"
        width="10.0042"
        height="10.0042"
        rx="3"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgFileWarningError
