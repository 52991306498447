import { SerializedStyles } from '@emotion/react'
import { ButtonHTMLAttributes, MouseEvent } from 'react'

export enum TabStylesKeys {
  root = 'root',
  label = 'label',
  active = 'active',
}

export const sizes = ['sm', 'md'] as const

export type Size = typeof sizes[number]

export type TabValue = string | number
export interface TabProps
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  styles?: Partial<Record<TabStylesKeys, SerializedStyles>>
  size?: Size
  value: TabValue
  label: string
  active?: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>, value: TabValue) => void
}

export interface ValidatedProps {
  size: TabProps['size']
}

export interface UseValidatePropsParams {
  sizeProp: TabProps['size']
}

export interface UseValidateProps {
  (propsToValidate: UseValidatePropsParams): ValidatedProps
}

export interface SizeStyles {
  marginRight: number
}
