import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgWarning: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.538A8.461 8.461 0 0 0 3.538 12 8.46 8.46 0 0 0 12 20.462 8.46 8.46 0 0 0 20.462 12 8.46 8.46 0 0 0 12 3.538ZM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.615a.77.77 0 0 1 .77.77v5.128a.77.77 0 1 1-1.54 0V7.385a.77.77 0 0 1 .77-.77ZM10.974 16.362a1.026 1.026 0 1 1 2.05-.005 1.026 1.026 0 0 1-2.05.005Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWarning
