import { FunctionComponent, useMemo } from 'react'
import useStyles from './styles'
import { SpinnerProps } from './types'
import { ReactComponent as IconSpinner } from '@/assets/icon_spinner.svg'

export const Spinner: FunctionComponent<SpinnerProps> = ({
  active = true,
  styles: overrides,
  size,
}) => {
  const styles = useStyles({ size })

  const activeStyles = useMemo(() => {
    if (!active) return []

    return [styles.active, overrides?.active]
  }, [active, styles.active, overrides?.active])

  return (
    <div
      css={[styles.root, overrides?.root, ...activeStyles]}
      data-testid="hero-spinner"
    >
      <IconSpinner css={[styles.icon, overrides?.icon]} />
    </div>
  )
}
