import React, { Suspense, useMemo } from 'react'
import { IconProps } from '../types'
import { iconMap } from '../icons/iconMap'

export const Icon: React.FC<IconProps> = ({ name, size, color, onClick }) => {
  const iconName = useMemo(() => {
    if (!name) {
      throw new Error('Icon name was not provided')
    }
    return name
  }, [name])

  const MappedIcon = iconMap[iconName]

  return (
    <Suspense fallback={<span className="icon-on-suspense"></span>}>
      <MappedIcon color={color} onClick={onClick} size={size} />
    </Suspense>
  )
}
