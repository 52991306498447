import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'
import { LinkProps } from './types'

const useStyles = makeStyles<Pick<LinkProps, 'active' | 'fullWidth'>>({
  root: ({ colors, typography }, { active, fullWidth }) => {
    return css`
      outline: none;
      border: none;
      text-decoration: none;
      cursor: pointer;
      padding: 8px;
      ${typographyToStyle(typography.button3)}
      display:inline-flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      width: ${fullWidth ? '100%' : 'auto'};
      background-color: transparent;
      color: ${colors.primary[active ? 800 : 500]};

      &:hover {
        color: ${colors.primary[400]};
      }

      &:active {
        color: ${colors.primary[600]};
      }
    `
  },

  disabled: ({ colors }) => {
    const { disabled_font } = colors.extra

    return css`
      color: ${disabled_font};
      pointer-events: none;
      user-select: none;
      cursor: default;
    `
  },

  label: css`
    position: relative;
  `,

  activeLine: ({ colors, borderRadius }) => css`
    display: inline-block;
    width: 100%;
    height: 4px;
    background-color: ${colors.secondary[500]};
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: ${borderRadius.pill};
  `,

  iconBox: css`
    display: flex;
  `,

  startIcon: ({ spacing }) => css`
    margin-right: ${spacing(1)}px;
  `,

  endIcon: ({ spacing }) => css`
    margin-left: ${spacing(1)}px;
  `,
})

export default useStyles
