import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMedicalCrossFastHelpCircle: FunctionComponent<
  IndividualIconProps
> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        clipRule="evenodd"
        d="M15.752 11.25a.75.75 0 0 0-.75-.75H13.5V8.998a.75.75 0 0 0-.75-.75H11.25a.75.75 0 0 0-.75.75v1.5H8.998a.75.75 0 0 0-.75.75v1.501c0 .415.335.75.75.75h1.5v1.501c0 .415.336.75.75.75h1.501a.75.75 0 0 0 .75-.75v-1.5h1.501a.75.75 0 0 0 .75-.75V11.25Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.633 5.633a9.004 9.004 0 1 1 0 12.734M4.497 12H1.996M5.997 8.999h-2.5M5.997 15.001h-2.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgMedicalCrossFastHelpCircle
