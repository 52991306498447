import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgStethoscope: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.94 12.269a1.79 1.79 0 1 0-2.532 2.532 1.79 1.79 0 0 0 2.533-2.532Zm1.085-1.086a3.326 3.326 0 1 0-4.702 4.704 3.326 3.326 0 0 0 4.703-4.704ZM1 4.837a2.813 2.813 0 0 1 2.814-2.814H5.86a.767.767 0 0 1 0 1.535H3.814c-.707 0-1.28.572-1.28 1.28V8.93a5.372 5.372 0 1 0 10.745 0V4.837c0-.707-.572-1.279-1.279-1.279H9.953a.767.767 0 0 1 0-1.535H12a2.813 2.813 0 0 1 2.814 2.814V8.93A6.907 6.907 0 1 1 1 8.93V4.837Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.907 14.302c.424 0 .767.344.767.768v1.279a5.116 5.116 0 1 0 10.233 0v-.256a.767.767 0 1 1 1.535 0v.256a6.65 6.65 0 1 1-13.302 0v-1.28c0-.423.343-.767.767-.767ZM5.86 1c.424 0 .768.344.768.767v2.047a.767.767 0 1 1-1.535 0V1.767c0-.423.344-.767.767-.767ZM9.953 1c.424 0 .768.344.768.767v2.047a.767.767 0 1 1-1.535 0V1.767c0-.423.344-.767.767-.767Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgStethoscope
