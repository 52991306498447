import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgNotes: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.248 16.502a.75.75 0 0 1 .75-.75h4.001a.75.75 0 0 1 0 1.5H6.998a.75.75 0 0 1-.75-.75ZM18.032 15.033a.75.75 0 0 1 0 1.061l-1.876 1.876a.75.75 0 0 1-1.06 0l-1.126-1.126a.75.75 0 1 1 1.061-1.06l.595.595 1.346-1.346a.75.75 0 0 1 1.06 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.246 6.996a4.75 4.75 0 0 1 4.75-4.75h10.008a4.75 4.75 0 0 1 4.75 4.75v10.008a4.75 4.75 0 0 1-4.75 4.75H6.996a4.75 4.75 0 0 1-4.75-4.75V6.996Zm4.75-3.25a3.25 3.25 0 0 0-3.25 3.25v10.008a3.25 3.25 0 0 0 3.25 3.25h10.008a3.25 3.25 0 0 0 3.25-3.25V6.996a3.25 3.25 0 0 0-3.25-3.25H6.996Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.248 13a.75.75 0 0 1 .75-.75H15a.75.75 0 1 1 0 1.5H6.998a.75.75 0 0 1-.75-.75ZM8.498 5.748a.75.75 0 0 1 .75.75v3.001a.75.75 0 1 1-1.5 0V6.498a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.248 7.998a.75.75 0 0 1 .75-.75h3.001a.75.75 0 1 1 0 1.5H6.998a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNotes
