import { useMemo } from 'react'
import { theme, css } from '@conexasaude/styles'
import {
  Theme,
  Placement,
  tooltipThemes,
  placements,
  variants,
  Variant,
  PropsToValidate,
  ValidatedTheme,
} from './types'

export const isTheme = (value: Theme): value is Theme =>
  tooltipThemes.includes(value)

export const isPlacement = (value: Placement): value is Placement =>
  placements.includes(value)

export const isVariant = (value: Variant): value is Variant =>
  variants.includes(value)

export const useValidateProps = ({
  propTheme,
  propPlacement,
  propVariant,
}: PropsToValidate): ValidatedTheme => {
  const variant = useMemo(() => {
    if (!isVariant(propVariant)) {
      console.warn(
        'The provided color property is not a valid theme. A default value will be used.',
      )
      return 'auto'
    }
    return propVariant
  }, [propVariant])

  const theme = useMemo(() => {
    if (!isTheme(propTheme)) {
      console.warn(
        'The provided color property is not a valid theme. A default value will be used.',
      )
      return 'light'
    }
    return propTheme
  }, [propTheme])

  const placement = useMemo(() => {
    if (!isPlacement(propPlacement)) {
      console.warn(
        'The provided color property is not a valid position. A default value will be used.',
      )
      return 'top'
    }
    return propPlacement
  }, [propPlacement])
  return { variant, theme, placement }
}

export const variantsToStyle = {
  fixed: css({
    width: '232px',
    height: 'auto',
  }),
  auto: css({
    width: 'auto',
    height: '54px',
    whiteSpace: 'nowrap',
  }),
}

export const themesToStyle = {
  dark: {
    background: theme.colors.neutral[800],
    color: theme.colors.common.white,
  },
  light: {
    background: theme.colors.common.white,
    color: theme.colors.neutral[800],
  },
}

export const placementStyle = {
  down: {
    top: 'calc(100% + 10px)',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    arrow: {
      left: '50%',
      bottom: '100%',
      transform: 'translate(-50%, 55%)',
    },
  },
  top: {
    bottom: 'calc(100% + 10px)',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    arrow: {
      left: '50%',
      top: '100%',
      transform: 'translate(-50%, -55%)',
    },
  },
  right: {
    top: '50%',
    left: 'calc(100% + 10px)',
    transform: 'translate(0px, -50%)',
    arrow: {
      top: '50%',
      right: '100%',
      transform: 'translate(55%, -50%)',
    },
  },
  left: {
    top: '50%',
    right: 'calc(100% + 10px)',
    transform: 'translate(0px, -50%)',
    arrow: {
      top: '50%',
      left: '100%',
      transform: 'translate(-55%, -50%)',
    },
  },
}
