import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgOverflow: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5027 12C18.5027 12.2763 18.2788 12.5002 18.0025 12.5002C17.7263 12.5002 17.5023 12.2763 17.5023 12C17.5023 11.7237 17.7263 11.4998 18.0025 11.4998C18.2788 11.4998 18.5027 11.7237 18.5027 12"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5002 12C12.5002 12.2763 12.2762 12.5002 12 12.5002C11.7237 12.5002 11.4998 12.2763 11.4998 12C11.4998 11.7237 11.7237 11.4998 12 11.4998C12.2762 11.4998 12.5002 11.7237 12.5002 12"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.49773 12C6.49773 12.2763 6.27378 12.5002 5.99752 12.5002C5.72127 12.5002 5.49731 12.2763 5.49731 12C5.49731 11.7237 5.72127 11.4998 5.99752 11.4998C6.27378 11.4998 6.49773 11.7237 6.49773 12"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgOverflow
