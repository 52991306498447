import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFileCheck: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.246 2.25a.75.75 0 0 1 .749.75v3.998c0 .139.112.25.25.25h3.999a.75.75 0 1 1 0 1.5h-3.999a1.75 1.75 0 0 1-1.749-1.75V3a.75.75 0 0 1 .75-.749ZM10.64 11.429a.75.75 0 0 1 0 1.06l-2.5 2.5a.75.75 0 0 1-1.06 0l-1.5-1.5a.75.75 0 0 1 1.06-1.06l.97.97 1.97-1.97a.75.75 0 0 1 1.06 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 10.996a3.747 3.747 0 0 1 3.747-3.748h4.001a3.747 3.747 0 0 1 3.748 3.748v4.001a3.747 3.747 0 0 1-3.748 3.747h-4A3.747 3.747 0 0 1 2.5 14.997v-4.001Zm3.747-2.249A2.248 2.248 0 0 0 4 10.996v4.001a2.248 2.248 0 0 0 2.248 2.248h4.001a2.248 2.248 0 0 0 2.249-2.248v-4.001a2.248 2.248 0 0 0-2.249-2.249h-4Z"
        fill="currentColor"
      />
      <path
        d="M7 5.002c0-.69.56-1.25 1.25-1.25h7.171c.331 0 .649.132.883.366l2.83 2.827c.235.234.367.552.367.884v11.173c0 .69-.56 1.25-1.249 1.25H8.249c-.69 0-1.249-.56-1.249-1.25v-.763H5.501v.763A2.748 2.748 0 0 0 8.25 21.75h10.003A2.748 2.748 0 0 0 21 19.002V7.829c0-.73-.29-1.429-.806-1.944l-2.83-2.828a2.748 2.748 0 0 0-1.943-.803H8.25a2.748 2.748 0 0 0-2.748 2.748v3.244h1.5V5.002Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFileCheck
