// automatically generated with generateIconMap
// please do not write in this file
import { lazy } from 'react'

const ArrowDown = lazy(() => import('./components/arrows/ArrowDown'))
const ArrowLeft = lazy(() => import('./components/arrows/ArrowLeft'))
const ArrowRight = lazy(() => import('./components/arrows/ArrowRight'))
const ArrowUp = lazy(() => import('./components/arrows/ArrowUp'))
const Back = lazy(() => import('./components/arrows/Back'))
const LeftArrowCircle = lazy(
  () => import('./components/arrows/LeftArrowCircle'),
)
const Up = lazy(() => import('./components/arrows/Up'))
const Add = lazy(() => import('./components/essential/Add'))
const Bullet = lazy(() => import('./components/essential/Bullet'))
const Calendar = lazy(() => import('./components/essential/Calendar'))
const Check = lazy(() => import('./components/essential/Check'))
const Clip = lazy(() => import('./components/essential/Clip'))
const Clock = lazy(() => import('./components/essential/Clock'))
const CloseCircle = lazy(() => import('./components/essential/CloseCircle'))
const Close = lazy(() => import('./components/essential/Close'))
const Copy = lazy(() => import('./components/essential/Copy'))
const Download = lazy(() => import('./components/essential/Download'))
const Edit = lazy(() => import('./components/essential/Edit'))
const Email = lazy(() => import('./components/essential/Email'))
const Exit = lazy(() => import('./components/essential/Exit'))
const EyeOff = lazy(() => import('./components/essential/EyeOff'))
const EyeOn = lazy(() => import('./components/essential/EyeOn'))
const Fail = lazy(() => import('./components/essential/Fail'))
const FilterClose = lazy(() => import('./components/essential/FilterClose'))
const Filter = lazy(() => import('./components/essential/Filter'))
const Flag = lazy(() => import('./components/essential/Flag'))
const Home = lazy(() => import('./components/essential/Home'))
const Image = lazy(() => import('./components/essential/Image'))
const Information = lazy(() => import('./components/essential/Information'))
const ItemDocumentsFiles = lazy(
  () => import('./components/essential/ItemDocumentsFiles'),
)
const Key = lazy(() => import('./components/essential/Key'))
const Lock = lazy(() => import('./components/essential/Lock'))
const Menu = lazy(() => import('./components/essential/Menu'))
const Minus = lazy(() => import('./components/essential/Minus'))
const More = lazy(() => import('./components/essential/More'))
const Notification = lazy(() => import('./components/essential/Notification'))
const Plus = lazy(() => import('./components/essential/Plus'))
const Print = lazy(() => import('./components/essential/Print'))
const QuestionCheckmarkCircle = lazy(
  () => import('./components/essential/QuestionCheckmarkCircle'),
)
const Question = lazy(() => import('./components/essential/Question'))
const Refresh = lazy(() => import('./components/essential/Refresh'))
const Search = lazy(() => import('./components/essential/Search'))
const SendShare = lazy(() => import('./components/essential/SendShare'))
const Settings = lazy(() => import('./components/essential/Settings'))
const Star = lazy(() => import('./components/essential/Star'))
const Success = lazy(() => import('./components/essential/Success'))
const Support = lazy(() => import('./components/essential/Support'))
const TimeZone = lazy(() => import('./components/essential/TimeZone'))
const Trash = lazy(() => import('./components/essential/Trash'))
const Unlock = lazy(() => import('./components/essential/Unlock'))
const Upload = lazy(() => import('./components/essential/Upload'))
const User = lazy(() => import('./components/essential/User'))
const Warning = lazy(() => import('./components/essential/Warning'))
const Wifi = lazy(() => import('./components/essential/Wifi'))
const Overflow = lazy(() => import('./components/essential/Overflow'))
const PlusCircle = lazy(() => import('./components/essential/PlusCircle'))
const Brain = lazy(() => import('./components/medicine/Brain'))
const CardEdit = lazy(() => import('./components/medicine/CardEdit'))
const DoctorTalk = lazy(() => import('./components/medicine/DoctorTalk'))
const Folder = lazy(() => import('./components/medicine/Folder'))
const Heartbeat = lazy(() => import('./components/medicine/Heartbeat'))
const Hospital = lazy(() => import('./components/medicine/Hospital'))
const Mask = lazy(() => import('./components/medicine/Mask'))
const MedicalCertificate = lazy(
  () => import('./components/medicine/MedicalCertificate'),
)
const MedicalCross = lazy(() => import('./components/medicine/MedicalCross'))
const Notes = lazy(() => import('./components/medicine/Notes'))
const Pill = lazy(() => import('./components/medicine/Pill'))
const ScaleWeight = lazy(() => import('./components/medicine/ScaleWeight'))
const ShieldHeart = lazy(() => import('./components/medicine/ShieldHeart'))
const Stethoscope = lazy(() => import('./components/medicine/Stethoscope'))
const Syringe = lazy(() => import('./components/medicine/Syringe'))
const Audio = lazy(() => import('./components/meet/Audio'))
const CameraOff = lazy(() => import('./components/meet/CameraOff'))
const CameraOn = lazy(() => import('./components/meet/CameraOn'))
const Fullscreen = lazy(() => import('./components/meet/Fullscreen'))
const MicrophoneOff = lazy(() => import('./components/meet/MicrophoneOff'))
const MicrophoneOn = lazy(() => import('./components/meet/MicrophoneOn'))
const Minimize = lazy(() => import('./components/meet/Minimize'))
const Miniplayer = lazy(() => import('./components/meet/Miniplayer'))
const PhoneOn = lazy(() => import('./components/meet/PhoneOn'))
const Rotate = lazy(() => import('./components/meet/Rotate'))
const ShareScreen = lazy(() => import('./components/meet/ShareScreen'))
const Book = lazy(() => import('./components/otherIcons/Book'))
const BrowserVideoPlayer = lazy(
  () => import('./components/otherIcons/BrowserVideoPlayer'),
)
const Chart = lazy(() => import('./components/otherIcons/Chart'))
const Chat = lazy(() => import('./components/otherIcons/Chat'))
const ClipboardSaved = lazy(
  () => import('./components/otherIcons/ClipboardSaved'),
)
const Clipboard = lazy(() => import('./components/otherIcons/Clipboard'))
const CreditCard = lazy(() => import('./components/otherIcons/CreditCard'))
const Dashboard = lazy(() => import('./components/otherIcons/Dashboard'))
const DocumentAtestado = lazy(
  () => import('./components/otherIcons/DocumentAtestado'),
)
const FaceId = lazy(() => import('./components/otherIcons/FaceId'))
const FileCheck = lazy(() => import('./components/otherIcons/FileCheck'))
const FileError = lazy(() => import('./components/otherIcons/FileError'))
const FileExams = lazy(() => import('./components/otherIcons/FileExams'))
const File = lazy(() => import('./components/otherIcons/File'))
const FileImage = lazy(() => import('./components/otherIcons/FileImage'))
const FilePdf = lazy(() => import('./components/otherIcons/FilePdf'))
const FileText = lazy(() => import('./components/otherIcons/FileText'))
const FileWarningError = lazy(
  () => import('./components/otherIcons/FileWarningError'),
)
const FileCrossed = lazy(() => import('./components/otherIcons/FileCrossed'))
const FilterCrossDelete = lazy(
  () => import('./components/otherIcons/FilterCrossDelete'),
)
const FileWarning = lazy(() => import('./components/otherIcons/FileWarning'))
const UserError = lazy(() => import('./components/otherIcons/UserError'))
const FontBold = lazy(() => import('./components/otherIcons/FontBold'))
const FontItalic = lazy(() => import('./components/otherIcons/FontItalic'))
const FontUnderline = lazy(
  () => import('./components/otherIcons/FontUnderline'),
)
const Frame = lazy(() => import('./components/otherIcons/Frame'))
const IconShare = lazy(() => import('./components/otherIcons/IconShare'))
const Identity = lazy(() => import('./components/otherIcons/Identity'))
const JustifyCenter = lazy(
  () => import('./components/otherIcons/JustifyCenter'),
)
const JustifyLeft = lazy(() => import('./components/otherIcons/JustifyLeft'))
const MedicalCrossFastHelpCircle = lazy(
  () => import('./components/otherIcons/MedicalCrossFastHelpCircle'),
)
const Mobile = lazy(() => import('./components/otherIcons/Mobile'))
const ParagraphList = lazy(
  () => import('./components/otherIcons/ParagraphList'),
)
const ParagraphNumber = lazy(
  () => import('./components/otherIcons/ParagraphNumber'),
)
const Receipt = lazy(() => import('./components/otherIcons/Receipt'))
const SelectAll = lazy(() => import('./components/otherIcons/SelectAll'))
const Send = lazy(() => import('./components/otherIcons/Send'))
const SettingsHandles = lazy(
  () => import('./components/otherIcons/SettingsHandles'),
)
const SigninSendShare = lazy(
  () => import('./components/otherIcons/SigninSendShare'),
)
const ReceiptBullet = lazy(
  () => import('./components/otherIcons/ReceiptBullet'),
)
const Analytics = lazy(() => import('./components/otherIcons/Analytics'))
const DocumentCalculator = lazy(
  () => import('./components/otherIcons/DocumentCalculator'),
)
const ProfileCheckmark = lazy(
  () => import('./components/otherIcons/ProfileCheckmark'),
)
const HandKey = lazy(() => import('./components/otherIcons/HandKey'))

// iconMap
export const iconMap = {
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-up': ArrowUp,
  back: Back,
  'left-arrow-circle': LeftArrowCircle,
  up: Up,
  add: Add,
  bullet: Bullet,
  calendar: Calendar,
  check: Check,
  clip: Clip,
  clock: Clock,
  'close-circle': CloseCircle,
  close: Close,
  copy: Copy,
  download: Download,
  edit: Edit,
  email: Email,
  exit: Exit,
  'eye-off': EyeOff,
  'eye-on': EyeOn,
  fail: Fail,
  'filter-close': FilterClose,
  filter: Filter,
  flag: Flag,
  home: Home,
  image: Image,
  information: Information,
  'item-documents-files': ItemDocumentsFiles,
  key: Key,
  lock: Lock,
  menu: Menu,
  minus: Minus,
  more: More,
  notification: Notification,
  plus: Plus,
  print: Print,
  'question-checkmark-circle': QuestionCheckmarkCircle,
  question: Question,
  refresh: Refresh,
  search: Search,
  'send-share': SendShare,
  settings: Settings,
  star: Star,
  success: Success,
  support: Support,
  'time-zone': TimeZone,
  trash: Trash,
  unlock: Unlock,
  upload: Upload,
  user: User,
  warning: Warning,
  wifi: Wifi,
  overflow: Overflow,
  'plus-circle': PlusCircle,
  brain: Brain,
  'card-edit': CardEdit,
  'doctor-talk': DoctorTalk,
  folder: Folder,
  heartbeat: Heartbeat,
  hospital: Hospital,
  mask: Mask,
  'medical-certificate': MedicalCertificate,
  'medical-cross': MedicalCross,
  notes: Notes,
  pill: Pill,
  'scale-weight': ScaleWeight,
  'shield-heart': ShieldHeart,
  stethoscope: Stethoscope,
  syringe: Syringe,
  audio: Audio,
  'camera-off': CameraOff,
  'camera-on': CameraOn,
  fullscreen: Fullscreen,
  'microphone-off': MicrophoneOff,
  'microphone-on': MicrophoneOn,
  minimize: Minimize,
  miniplayer: Miniplayer,
  'phone-on': PhoneOn,
  rotate: Rotate,
  'share-screen': ShareScreen,
  book: Book,
  'browser-video-player': BrowserVideoPlayer,
  chart: Chart,
  chat: Chat,
  'clipboard-saved': ClipboardSaved,
  clipboard: Clipboard,
  'credit-card': CreditCard,
  dashboard: Dashboard,
  'document-atestado': DocumentAtestado,
  'face-id': FaceId,
  'file-check': FileCheck,
  'file-error': FileError,
  'file-exams': FileExams,
  file: File,
  'file-image': FileImage,
  'file-pdf': FilePdf,
  'file-text': FileText,
  'file-warning-error': FileWarningError,
  'file-crossed': FileCrossed,
  'filter-cross-delete': FilterCrossDelete,
  'file-warning': FileWarning,
  'user-error': UserError,
  'font-bold': FontBold,
  'font-italic': FontItalic,
  'font-underline': FontUnderline,
  frame: Frame,
  'icon-share': IconShare,
  identity: Identity,
  'justify-center': JustifyCenter,
  'justify-left': JustifyLeft,
  'medical-cross-fast-help-circle': MedicalCrossFastHelpCircle,
  mobile: Mobile,
  'paragraph-list': ParagraphList,
  'paragraph-number': ParagraphNumber,
  receipt: Receipt,
  'select-all': SelectAll,
  send: Send,
  'settings-handles': SettingsHandles,
  'signin-send-share': SigninSendShare,
  'receipt-bullet': ReceiptBullet,
  analytics: Analytics,
  'document-calculator': DocumentCalculator,
  'profile-checkmark': ProfileCheckmark,
  'hand-key': HandKey,
}

export const iconList = Object.keys(iconMap)

export type IconMap = keyof typeof iconMap
