import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgQuestion: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.538a8.462 8.462 0 1 0 0 16.924 8.462 8.462 0 0 0 0-16.924ZM2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.436 9.971a1.422 1.422 0 0 0-1.496-1.306.771.771 0 0 1-.074 0 1.467 1.467 0 0 0-1.49 1.135.769.769 0 0 1-1.499-.348 3.005 3.005 0 0 1 3.015-2.324 2.96 2.96 0 0 1 3.082 2.82c0 1.175-.856 1.899-1.386 2.33l-.196.157c-.209.168-.365.294-.492.426a.597.597 0 0 0-.131.173.769.769 0 0 1-1.538-.008c0-.552.301-.96.558-1.229.204-.213.466-.422.686-.598l.143-.115c.56-.455.808-.755.818-1.113ZM12.604 15.242A.767.767 0 0 0 12 14.95a.897.897 0 1 0 .897.897.768.768 0 0 0-.293-.604Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.231 15.718c0-.425.344-.769.769-.769.495 0 .897.402.897.897a.769.769 0 0 1-1.373.476.767.767 0 0 1-.293-.604Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgQuestion
