import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgStar: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2a.73.73 0 0 1 .657.426l2.694 5.722 6.024.923a.74.74 0 0 1 .589.518.783.783 0 0 1-.183.778L17.42 14.82l1.03 6.291a.775.775 0 0 1-.293.745.708.708 0 0 1-.772.055L12 18.943l-5.385 2.968a.708.708 0 0 1-.772-.055.775.775 0 0 1-.292-.745l1.03-6.291-4.362-4.453a.783.783 0 0 1-.183-.778.74.74 0 0 1 .59-.518l6.022-.923 2.695-5.722A.73.73 0 0 1 12 2Zm0 2.491L9.793 9.178a.735.735 0 0 1-.55.418l-4.948.758 3.583 3.658a.78.78 0 0 1 .21.671l-.845 5.16 4.414-2.434a.707.707 0 0 1 .686 0l4.414 2.434-.844-5.16a.78.78 0 0 1 .209-.67l3.583-3.659-4.948-.758a.734.734 0 0 1-.55-.418L12 4.49Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgStar
