import { boxSizingReset } from '@/styles'
import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'
import { TooltipProps } from './types'
import { variantsToStyle, themesToStyle, placementStyle } from './utils'

const useStyles = makeStyles<{
  theme: TooltipProps['theme']
  placement: TooltipProps['placement']
  variant: TooltipProps['variant']
}>({
  root: css`
    ${boxSizingReset};
    position: relative;
    display: flex;
    align-items: center;
    margin: 15px;
    z-index: 0;
    &:hover {
      > p {
        visibility: visible;
        opacity: 1;
      }
    }
  `,

  tooltip: (theme, { variant, theme: tooltipTheme, placement }) => css`
    padding: ${theme.spacing(2)}px;
    margin: 0;
    box-shadow: ${theme.shadows[0]};
    ${typographyToStyle(theme.typography.body2)}
    ${variantsToStyle[variant]}
    background: ${themesToStyle[tooltipTheme].background};
    color: ${themesToStyle[tooltipTheme].color};
    position: absolute;
    ${placementStyle[placement]};
    text-align: left;
    border-radius: ${theme.spacing(1)}px;
    z-index: 1;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
  `,

  tooltipArrow: (theme, { theme: tooltipTheme, placement }) => css`
    display: inline-flex;
    position: absolute;
    ${placementStyle[placement].arrow};
    &::after {
      content: ' ';
      width: 12.73px;
      height: 12.73px;
      display: inline-block;
      background: ${themesToStyle[tooltipTheme].background};
      border-radius: 2px;
      border-color: ${themesToStyle[tooltipTheme].background} transparent
        transparent transparent;
      transform: rotate(45deg);
    }
  `,
})

export default useStyles
