import { SerializedStyles } from '@emotion/react'

export const sizes = ['sm', 'md'] as const

export type Size = typeof sizes[number]

export enum ProgressStylesKeys {
  root = 'root',
  progress = 'progress',
}
export interface ProgressBarProps {
  styles?: Partial<Record<ProgressStylesKeys, SerializedStyles>>
  color?: string
  size?: Size
  progress?: number
  width?: string
}
