import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgEyeOn: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.299 7.404c-1.495 1.162-2.809 2.762-3.732 4.481a.245.245 0 0 0 0 .23c.923 1.719 2.237 3.318 3.732 4.481 1.499 1.166 3.13 1.856 4.701 1.856 1.57 0 3.202-.69 4.701-1.856 1.495-1.162 2.809-2.762 3.732-4.481a.246.246 0 0 0 0-.23c-.923-1.719-2.237-3.318-3.732-4.481-1.5-1.166-3.13-1.856-4.701-1.856-1.571 0-3.202.69-4.701 1.856Zm-.941-1.225C8.033 4.876 9.986 4 12 4c2.014 0 3.967.876 5.642 2.179 1.679 1.305 3.128 3.077 4.145 4.97.284.53.284 1.172 0 1.702-1.017 1.893-2.466 3.665-4.145 4.97C15.967 19.125 14.014 20 12 20c-2.014 0-3.967-.876-5.642-2.179-1.679-1.306-3.128-3.077-4.145-4.97a1.804 1.804 0 0 1 0-1.702C3.23 9.256 4.679 7.484 6.358 6.18Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.658 10.332a2.334 2.334 0 0 0-3.316 0 2.37 2.37 0 0 0 0 3.336c.916.922 2.4.922 3.316 0a2.37 2.37 0 0 0 0-3.336Zm1.036-1.043a3.793 3.793 0 0 0-5.388 0 3.852 3.852 0 0 0 0 5.422 3.793 3.793 0 0 0 5.388 0 3.852 3.852 0 0 0 0-5.422Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEyeOn
