import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgKey: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.673 8.406c.186-.187.476-.357.83-.357s.644.17.83.357c.185.186.355.477.355.833s-.17.647-.355.833a1.183 1.183 0 0 1-.83.357c-.354 0-.644-.17-.83-.357a1.192 1.192 0 0 1-.355-.833c0-.356.17-.647.355-.833Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.01 4.166c-.59-.218-1.073-.12-1.433.241L9.589 7.408a1.48 1.48 0 0 0-.346 1.538l.916 2.044a.778.778 0 0 1-.163.872l-5.95 5.876v2.21H6.25l5.955-5.98a.77.77 0 0 1 .85-.165l2.147.923c.59.218 1.073.12 1.433-.242l2.961-2.973c.341-.426.456-.948.268-1.467l-1.538-3.603-.007-.018a1.27 1.27 0 0 0-.703-.706l-.017-.007-3.589-1.544Zm-2.526-.856c.872-.876 2.034-.987 3.084-.592a.832.832 0 0 1 .033.014L18.2 4.28a2.82 2.82 0 0 1 1.55 1.557l1.543 3.614.013.033c.419 1.121.128 2.238-.542 3.046a.8.8 0 0 1-.047.051l-2.988 3.001c-.873.876-2.034.987-3.084.592a.784.784 0 0 1-.033-.014l-1.683-.724-5.812 5.837a.771.771 0 0 1-.546.227H3.273a.774.774 0 0 1-.773-.776v-3.311c0-.208.083-.407.23-.553l5.799-5.726-.706-1.577a3.037 3.037 0 0 1 .673-3.247l2.988-3Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgKey
