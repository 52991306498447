import { SerializedStyles } from '@emotion/react'
import { makeStyles, css } from '@conexasaude/styles'

const size = 16

const useStyles = makeStyles<{
  checkedOverrides?: SerializedStyles
  disabled: boolean
}>({
  root: ({ spacing }) => css`
    padding: ${spacing(1)}px;
    position: relative;
    display: inline-flex;
  `,

  innerBox: theme => css`
    border-radius: 3px;
    overflow: hidden;
    width: ${size}px;
    height: ${size}px;
    color: ${theme.colors.common.white};
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,

  input: (theme, { checkedOverrides, disabled }) => css`
    z-index: 1;
    appearance: none;
    opacity: 0;
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    cursor: pointer;
    background: transparent;
    appearance: none;
    -webkit-appearance: none;

    /* // TODO: suprimir aninhamento de estilos pois dificulta overrides */
    &:checked {
      /**
        + div  = .hero-radio__visualRadio element
      */
      & + div {
        &:before {
          background: ${disabled
            ? theme.colors.extra.disabled_bg
            : theme.colors.primary[500]};
        }
        & > svg {
          display: inline-block;
        }
      }

      ${checkedOverrides || ''}
    }
  `,

  visualCheckbox: theme => css`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    :before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      box-sizing: border-box;
      border: 1px solid;
      border-color: ${theme.colors.primary[500]};
      background: ${theme.colors.common.white};
      position: absolute;
      top: 0;
      left: 0;
    }
  `,

  disabled: theme => css`
    cursor: not-allowed;
    pointer-events: none;
    /**
      + div  = .hero-radio__visualRadio element
    */
    & + div:before {
      border-color: ${theme.colors.extra.disabled_bg};
    }
  `,

  icon: (theme, { disabled }) => css`
    display: none;
    position: absolute;
    pointer-events: none;
    align-self: center;

    > path {
      stroke: ${disabled
        ? theme.colors.extra.disabled_font
        : theme.colors.common.white};
    }
  `,
})

export default useStyles
