import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFlag: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.774 2.954c.428 0 .774.344.774.77V21.23c0 .425-.346.769-.774.769A.772.772 0 0 1 4 21.23V3.724c0-.425.347-.77.774-.77ZM19.226 3.026c.427 0 .774.344.774.769v10.277c0 .425-.347.769-.774.769a.772.772 0 0 1-.774-.77V3.796c0-.425.346-.77.774-.77Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m4.774 14.051-.495-.591a.766.766 0 0 0-.1 1.083.778.778 0 0 0 1.074.113l.002-.002a.97.97 0 0 1 .078-.046c.084-.047.238-.121.476-.2.475-.157 1.295-.333 2.578-.333 1.187 0 2.186.384 3.329.832l.043.017c1.105.433 2.351.922 3.854.922 1.453 0 2.465-.27 3.138-.568.336-.149.582-.303.754-.43a2.532 2.532 0 0 0 .283-.244l.008-.008.003-.004.002-.002v-.001s.002 0-.575-.514l.576.513a.766.766 0 0 0-.06-1.086.778.778 0 0 0-1.08.045l-.004.005a.988.988 0 0 1-.078.062 2.434 2.434 0 0 1-.46.26c-.456.202-1.25.434-2.507.434-1.187 0-2.186-.384-3.329-.832l-.043-.017c-1.105-.434-2.351-.923-3.854-.923-1.426 0-2.413.197-3.067.412-.326.108-.568.22-.737.314a2.536 2.536 0 0 0-.287.184l-.009.008-.004.003-.002.001s-.002.002.493.593ZM5.325 4.266a.778.778 0 0 1-1.08.02.766.766 0 0 1-.035-1.087l.564.526c-.564-.526-.563-.527-.563-.527v-.001l.002-.002.004-.004.008-.008a1.23 1.23 0 0 1 .083-.077 2.51 2.51 0 0 1 .201-.157c.172-.121.418-.268.752-.41C5.931 2.256 6.94 2 8.387 2c1.503 0 2.75.489 3.854.922l.043.017c1.143.449 2.142.832 3.329.832 1.283 0 2.103-.176 2.578-.333.238-.079.392-.153.476-.2a.987.987 0 0 0 .077-.046l.003-.002a.778.778 0 0 1 1.074.113.766.766 0 0 1-.1 1.084l-.495-.592.494.592-.001.001-.002.001-.004.004-.009.007a1.536 1.536 0 0 1-.085.062 2.52 2.52 0 0 1-.202.122 4.38 4.38 0 0 1-.737.314c-.654.215-1.64.412-3.067.412-1.503 0-2.75-.49-3.854-.923-.014-.005-.029-.01-.043-.017-1.143-.448-2.142-.832-3.329-.832-1.261 0-2.06.223-2.519.417-.23.097-.38.19-.462.248a.975.975 0 0 0-.081.063Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFlag
