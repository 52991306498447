import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgIdentity: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.246 6.998a.75.75 0 0 1 .75-.75h18.008a.75.75 0 0 1 0 1.5H2.996a.75.75 0 0 1-.75-.75ZM6.248 11a.75.75 0 0 1 .75-.75h2.5a.75.75 0 0 1 0 1.5h-2.5a.75.75 0 0 1-.75-.75ZM6.25 14.5a.75.75 0 0 1 .75-.75h5a.75.75 0 1 1 0 1.5H7a.75.75 0 0 1-.75-.75Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.997 4.747a2.251 2.251 0 0 0-2.25 2.251V15a2.251 2.251 0 0 0 2.25 2.252H10a.75.75 0 0 1 0 1.5H5.997A3.751 3.751 0 0 1 2.247 15V6.998a3.752 3.752 0 0 1 3.75-3.751h12.005a3.752 3.752 0 0 1 3.752 3.751V11a.75.75 0 0 1-1.5 0V6.998a2.251 2.251 0 0 0-2.252-2.251H5.997Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.093 16.253H18.41a2.85 2.85 0 0 1 2.843 2.843V19.504c0 .69-.56 1.25-1.25 1.25H13.5c-.69 0-1.25-.56-1.25-1.25v-.409a2.85 2.85 0 0 1 2.843-2.842Zm.003 1.5a1.35 1.35 0 0 0-1.346 1.346v.155h6.003v-.155a1.35 1.35 0 0 0-1.346-1.346h-3.311ZM14 12.49a2.75 2.75 0 1 1 5.502.022 2.75 2.75 0 0 1-5.501-.022Zm2.751 1.262a1.25 1.25 0 1 0-1.25-1.256m1.25 1.256a1.25 1.25 0 0 1-1.25-1.256"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgIdentity
