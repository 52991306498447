import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgCalendar: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5.999A3.749 3.749 0 0 1 5.999 2.25H18a3.749 3.749 0 0 1 3.749 3.749V18a3.749 3.749 0 0 1-3.749 3.749H6a3.749 3.749 0 0 1-3.749-3.749V6Zm3.749-2.25A2.25 2.25 0 0 0 3.749 6V18A2.25 2.25 0 0 0 6 20.251H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.749H6Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 8A.75.75 0 0 1 3 7.25h18a.75.75 0 0 1 0 1.5H3A.75.75 0 0 1 2.25 8ZM17.805 10.945a.748.748 0 0 0-.555-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 11.45a.75.75 0 0 1 .75-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.304.505.748.748 0 0 1-.245-.555ZM14.304 10.945a.748.748 0 0 0-.555-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11.45a.75.75 0 0 1 .75-.75.8.8 0 0 1 .799.8.75.75 0 0 1-1.304.505.748.748 0 0 1-.246-.555ZM10.803 10.945a.748.748 0 0 0-.554-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 11.45a.75.75 0 0 1 .749-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.305.505.748.748 0 0 1-.245-.555ZM7.303 13.945a.748.748 0 0 0-.554-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.246-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14.45a.75.75 0 0 1 .749-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.305.505A.748.748 0 0 1 6 14.45ZM10.803 13.945a.748.748 0 0 0-.554-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 14.45a.75.75 0 0 1 .749-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.305.505.748.748 0 0 1-.245-.555ZM14.304 13.945a.748.748 0 0 0-.555-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 14.45a.75.75 0 0 1 .75-.75.8.8 0 0 1 .799.8.75.75 0 0 1-1.304.505.748.748 0 0 1-.246-.555ZM17.805 13.945a.748.748 0 0 0-.555-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 14.45a.75.75 0 0 1 .75-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.304.505.748.748 0 0 1-.245-.555ZM17.805 16.946a.748.748 0 0 0-.555-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 17.45a.75.75 0 0 1 .75-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.304.505.748.748 0 0 1-.245-.555ZM14.304 16.946a.748.748 0 0 0-.555-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 17.45a.75.75 0 0 1 .75-.75.8.8 0 0 1 .799.8.75.75 0 0 1-1.304.505.748.748 0 0 1-.246-.555ZM10.803 16.946a.748.748 0 0 0-.554-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.245-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 17.45a.75.75 0 0 1 .749-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.305.505.748.748 0 0 1-.245-.555ZM7.303 16.946a.748.748 0 0 0-.554-.245.8.8 0 1 0 .8.8.748.748 0 0 0-.246-.555Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 17.45a.75.75 0 0 1 .749-.75.8.8 0 0 1 .8.8.75.75 0 0 1-1.305.505A.748.748 0 0 1 6 17.45Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCalendar
