import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgHandKey: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9975 21.0038H3.99667C3.44444 21.0038 2.99625 20.5556 2.99625 20.0034V14.0009C2.99625 13.4487 3.44444 13.0005 3.99667 13.0005H5.9975C6.54973 13.0005 6.99792 13.4487 6.99792 14.0009V20.0034C6.99792 20.5556 6.54973 21.0038 5.9975 21.0038Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0004 17.0021H15.3344C15.7676 17.0021 16.1887 16.862 16.5349 16.6019L18.9519 14.7892C19.5551 14.337 20.3995 14.397 20.9327 14.9302V14.9302C21.525 15.5225 21.525 16.4819 20.9327 17.0731L18.8589 19.147C18.3006 19.7052 17.5883 20.0864 16.814 20.2414L13.8828 20.8277C13.3015 20.9437 12.7023 20.9297 12.1271 20.7857L9.47595 20.1234C9.15882 20.0434 8.83368 20.0033 8.50655 20.0033H6.99792"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0004 17.0022H14.486C15.3234 17.0022 16.0017 16.3239 16.0017 15.4865V15.1834C16.0017 14.4881 15.5285 13.8819 14.8542 13.7138L12.5612 13.1405C12.1881 13.0475 11.8059 13.0005 11.4218 13.0005V13.0005C10.4944 13.0005 9.587 13.2746 8.81567 13.7898L6.99792 15.0013"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4496 4.17076L8.82268 4.17376C8.55757 4.17376 8.30346 4.2798 8.11638 4.46688L7.29104 5.29223C6.89987 5.68339 6.89987 6.31665 7.29104 6.70682L8.11938 7.53516C8.30646 7.72224 8.56157 7.82828 8.82668 7.82728L13.4526 7.82428L13.4636 7.82528C14.1269 9.11282 15.4544 10.0012 17.0041 9.99918C19.213 9.99318 21.0037 8.19744 21.0037 5.98952C21.0047 3.7806 19.216 1.99385 17.0071 1.99585C15.4634 1.99985 14.1389 2.88923 13.4726 4.17176"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0041 6.26168C17.1481 6.26168 17.2642 6.14463 17.2642 6.00057C17.2642 5.85651 17.1461 5.73947 17.0031 5.73947C16.859 5.73947 16.742 5.85651 16.742 6.00057C16.742 6.14463 16.859 6.26168 17.0041 6.26168"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgHandKey
