import { FunctionComponent } from 'react'
import useStyles from './styles'
import { CheckboxProps } from './types'
import { useStateStyles } from './utils'
import { ReactComponent as CheckedIcon } from '@/assets/checked.svg'

export const Checkbox: FunctionComponent<CheckboxProps> = ({
  styles: overrides,
  onChange,
  checked,
  disabled = false,
  inputProps,
  inputRef,
}) => {
  const styles = useStyles({ disabled, checkedOverrides: overrides?.checked })

  const { disabledStyles } = useStateStyles({
    styles,
    overrides,
    disabled,
  })

  // TODO: extrair componente base pois há redundancia com Radio
  return (
    <span css={[styles.root, overrides?.root]} className="hero-checkbox__root">
      <span
        css={[styles.innerBox, overrides?.innerBox]}
        className="hero-checkbox__innerBox"
      >
        <input
          type="checkbox"
          data-testid="hero-checkbox"
          {...inputProps}
          className="hero-checkbox__input"
          css={[styles.input, overrides?.input, ...disabledStyles]}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          ref={inputRef}
        />
        <div
          css={[styles.visualCheckbox, overrides?.visualCheckbox]}
          className="hero-checkbox__visualCheckbox"
        >
          <CheckedIcon
            className="hero-checkbox__icon"
            css={[styles.icon, overrides?.icon]}
            width="8px"
            height="8px"
          />
        </div>
      </span>
    </span>
  )
}
