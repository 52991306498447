import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMobile: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.998 3.746c-.69 0-1.25.56-1.25 1.251v14.006c0 .69.56 1.25 1.25 1.25h8.004c.69 0 1.25-.56 1.25-1.25V4.997c0-.69-.56-1.25-1.25-1.25h-.75v1.026A1.307 1.307 0 0 1 14 6.147H9.999a1.307 1.307 0 0 1-1.25-1.375V3.747h-.75Zm0-1.5a2.75 2.75 0 0 0-2.75 2.751v14.006a2.75 2.75 0 0 0 2.75 2.75h8.004a2.75 2.75 0 0 0 2.75-2.75V4.997a2.75 2.75 0 0 0-2.75-2.75H7.998Zm2.251 1.5v.901h3.502v-.9H10.25Zm-1 14.257a.75.75 0 0 1 .75-.75h4.002a.75.75 0 0 1 0 1.5H9.999a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMobile
