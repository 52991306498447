import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgEmail: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 6.8A2.543 2.543 0 0 1 4.8 4.25h14.4a2.543 2.543 0 0 1 2.547 2.68.75.75 0 0 1 .003.07v10A2.756 2.756 0 0 1 19 19.75H5A2.756 2.756 0 0 1 2.25 17V7c0-.024.001-.047.003-.07a2.55 2.55 0 0 1-.003-.13Zm1.5 2.374V17c0 .06.004.119.013.177l4.748-4.75L3.75 9.175Zm6.39 2.55a.77.77 0 0 0-.072-.05l-5.82-3.976c-.357-.293-.498-.589-.498-.898 0-.586.464-1.05 1.05-1.05h14.4c.586 0 1.05.464 1.05 1.05 0 .312-.14.62-.466 1.042l-5.827 3.885a.65.65 0 0 0-.025.017l-.043.028c-1.149.736-2.525.737-3.675.003l-.074-.051Zm-.344 1.54-4.973 4.973c.058.009.117.013.177.013h14c.06 0 .119-.004.177-.013l-4.94-4.939a4.81 4.81 0 0 1-4.44-.034Zm5.741-.787 4.7 4.7c.009-.058.013-.117.013-.177V9.335l-4.713 3.142Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgEmail
