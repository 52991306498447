import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgCardEdit: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4.82A2.82 2.82 0 0 1 4.82 2h12.308a2.82 2.82 0 0 1 2.82 2.82V9.95a.77.77 0 0 1-1.538 0V4.82c0-.709-.573-1.283-1.282-1.283H4.821c-.709 0-1.283.574-1.283 1.283V19.18c0 .707.574 1.282 1.283 1.282h5.128a.77.77 0 1 1 0 1.538H4.82A2.82 2.82 0 0 1 2 19.18V4.82Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.31 13.658c.7-.7 1.837-.7 2.538 0l1.626 1.627c.701.7.701 1.837 0 2.538l-3.65 3.651a1.795 1.795 0 0 1-1.27.526h-2.652a.77.77 0 0 1-.77-.77v-2.652c0-.476.19-.932.526-1.269l3.652-3.65Zm1.087 1.088-3.65 3.651a.257.257 0 0 0-.076.181v1.884h1.883a.256.256 0 0 0 .181-.076l3.652-3.65c.1-.101.1-.263 0-.363m-1.627-1.627Zm-.363 0c.1-.1.263-.1.363 0h-.363ZM10.975 11.23a.77.77 0 0 1 .769.77v3.077a.77.77 0 0 1-1.539 0V12a.77.77 0 0 1 .77-.77Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.667 13.539a.77.77 0 0 1 .769-.77h3.077a.77.77 0 0 1 0 1.539H9.436a.77.77 0 0 1-.77-.77ZM7.128 6.872a.77.77 0 0 1 .77-.77h6.153a.77.77 0 1 1 0 1.54H7.898a.77.77 0 0 1-.77-.77Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCardEdit
