import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgReceipt: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.114 7.394c0-.427.346-.773.772-.773h8.228a.772.772 0 0 1 0 1.546H7.886a.772.772 0 0 1-.772-.773ZM7.114 11.515c0-.427.346-.773.772-.773h8.362a.772.772 0 0 1 0 1.546H7.886a.772.772 0 0 1-.772-.773Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 4.045a.258.258 0 0 0-.257.258v15.235l1.626-.815a.77.77 0 0 1 .69 0l2.398 1.201 2.398-1.2a.77.77 0 0 1 .69-.001l2.398 1.2 2.397-1.2a.77.77 0 0 1 .69 0l1.627.814V4.303a.258.258 0 0 0-.257-.258H4.8ZM3 4.303C3 3.308 3.806 2.5 4.8 2.5h14.4c.994 0 1.8.808 1.8 1.803v15.651c0 .96-1.007 1.578-1.86 1.152h-.001l-1.654-.829-2.167 1.086-.002.001c-.362.18-.785.18-1.146 0h-.002L12 20.277l-2.168 1.086-.002.001c-.36.18-.784.18-1.146 0h-.002l-2.167-1.087-1.654.829-.002.001A1.287 1.287 0 0 1 3 19.954V4.303Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.114 15.636c0-.427.346-.773.772-.773h5.142a.772.772 0 0 1 0 1.546H7.886a.772.772 0 0 1-.772-.773Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgReceipt
