import typographyToStyle from '@/utils/typographyToStyle'
import { makeStyles, css } from '@conexasaude/styles'

export const useStyles = makeStyles<{
  isMobile: boolean
}>({
  root: (theme, { isMobile }) => css`
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: ${isMobile ? 'flex-end' : 'center'};
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition .2 ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
  `,
  open: (theme, { isMobile }) => css`
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    ${isMobile && `.hero-modal { bottom: 0;}`}
  `,
  closeOnBackdrop: css`
    cursor: pointer;
    pointer-events: all;
  `,
  backdrop: ({ colors }) => css`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: ${colors.common.black};
    opacity: 0.64;
    border: none;
  `,
  modal: ({ colors, shadows, borderRadius }, { isMobile }) => css`
    overflow: hidden;
    box-sizing: border-box;
    box-shadow: ${shadows[3]};
    background: ${colors.common.white};
    border-radius: ${borderRadius.md};
    transition: all 0.5s ease-in-out;
    max-height: 90vh;
    ${isMobile
      ? `
      position: absolute;
      bottom: -100%;
      left: 0;
      border-radius: 16px 16px 0px 0px;
      padding: 24px 16px;
      max-width: 100%;
      height: 90vh;
    `
      : `
    padding: 24px;
    max-width: 480px;
    height: 100%;
    max-height: 528px;
    `};

    z-index: 1;
    display: flex;
    flex-direction: column;
  `,
  header: ({ typography, colors, spacing }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${spacing(3)}px;
    border-bottom: 1px solid ${colors.extra.disabled_bg};

    > h4 {
      margin: 0;
      ${typographyToStyle(typography.h4)};
      color: ${colors.neutral[900]};
    }

    > button {
      padding: 0;
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      pointer-events: all;
    }

    > svg {
      width: 24px;
      height: 24px;
      & path {
        fill: ${colors.extra.gray_icon};
      }
    }
  `,
  container: ({ spacing }) => css`
    padding-top: ${spacing(3)}px;
    flex-grow: 1;
    overflow-y: auto;
  `,
  footer: ({ spacing }, { isMobile }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: ${spacing(6)}px;
    ${isMobile
      ? `
      flex-direction: column;
      > button {
        width: 100%; 
        margin-bottom: 16px;

        &:last-child {
         margin-bottom: 0;
        }
      }
    `
      : `
      flex-direction: row;
    `}

    > div {
      display: flex;
      ${isMobile
        ? 'flex-direction: column; width: 100%'
        : 'flex-direction: row'};
      margin-left: auto;
    }

    .hero-link {
      margin-right: ${isMobile ? '0' : spacing(4)}px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      ${isMobile && 'flex-direction: column;'};
      width: 100% !important;
    }
  `,
})

export default useStyles
