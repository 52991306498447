import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSelectAll: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5A2.75 2.75 0 0 1 5 2.25a.75.75 0 0 1 0 1.5c-.69 0-1.25.56-1.25 1.25a.75.75 0 0 1-1.5 0ZM3 7.58a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2A.75.75 0 0 1 3 7.58ZM7.58 3a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2A.75.75 0 0 1 7.58 3ZM21 18.25a.75.75 0 0 1 .75.75A2.75 2.75 0 0 1 19 21.75a.75.75 0 0 1 0-1.5c.69 0 1.25-.56 1.25-1.25a.75.75 0 0 1 .75-.75ZM18.25 3a.75.75 0 0 1 .75-.75A2.75 2.75 0 0 1 21.75 5a.75.75 0 0 1-1.5 0c0-.69-.56-1.25-1.25-1.25a.75.75 0 0 1-.75-.75ZM21 7.58a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75ZM21 12.92a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75ZM12.92 3a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM12.92 21a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM9.308 15.58a.75.75 0 0 1 0 1.061l-2.222 2.222a.75.75 0 0 1-1.06 0l-1.334-1.332a.75.75 0 1 1 1.06-1.061l.804.803 1.692-1.692a.75.75 0 0 1 1.06 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 13.75c-.69 0-1.25.56-1.25 1.25v4c0 .69.56 1.25 1.25 1.25h4c.69 0 1.25-.56 1.25-1.25v-4c0-.69-.56-1.25-1.25-1.25H5ZM2.25 15A2.75 2.75 0 0 1 5 12.25h4A2.75 2.75 0 0 1 11.75 15v4A2.75 2.75 0 0 1 9 21.75H5A2.75 2.75 0 0 1 2.25 19v-4Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgSelectAll
