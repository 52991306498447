import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFullscreen: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.073 1.375c0-.397.322-.719.719-.719h3.833c.397 0 .719.322.719.72v3.832a.719.719 0 0 1-1.438 0V2.094h-3.114a.719.719 0 0 1-.72-.719Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.133.867c.28.28.28.736 0 1.016l-5.75 5.75a.719.719 0 1 1-1.016-1.016l5.75-5.75a.719.719 0 0 1 1.016 0ZM1.375 14.073c.397 0 .719.322.719.719v3.114h3.114a.719.719 0 1 1 0 1.438H1.375a.719.719 0 0 1-.719-.719v-3.833c0-.397.322-.719.719-.719Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.633 12.367c.28.28.28.736 0 1.016l-5.75 5.75a.719.719 0 1 1-1.016-1.016l5.75-5.75a.719.719 0 0 1 1.016 0ZM4.25 3.052c-.662 0-1.198.536-1.198 1.198v4.792a.719.719 0 1 1-1.437 0V4.25A2.635 2.635 0 0 1 4.25 1.615h4.792a.719.719 0 1 1 0 1.437H4.25ZM17.667 10.24c.397 0 .718.321.718.718v4.792a2.635 2.635 0 0 1-2.635 2.636H10a.719.719 0 0 1 0-1.438h5.75c.662 0 1.198-.536 1.198-1.198v-4.792c0-.397.322-.718.719-.718Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFullscreen
