import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgRefresh: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77 3.538a.77.77 0 0 1 .768.77v3.077h3.077a.77.77 0 1 1 0 1.538H2.77A.77.77 0 0 1 2 8.153V4.309a.77.77 0 0 1 .77-.77Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.917 7.833C4.497 4.396 7.964 2 12 2c5.523 0 10 4.477 10 10a.77.77 0 0 1-1.538 0A8.46 8.46 0 0 0 12 3.538c-3.41 0-6.345 2.023-7.685 4.937a.77.77 0 0 1-1.398-.642ZM16.615 15.846a.77.77 0 0 1 .77-.77h3.846a.77.77 0 0 1 .769.77v3.846a.77.77 0 0 1-1.538 0v-3.077h-3.077a.77.77 0 0 1-.77-.769Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77 11.23a.77.77 0 0 1 .768.77A8.46 8.46 0 0 0 12 20.462c3.41 0 6.345-2.023 7.685-4.937a.77.77 0 1 1 1.398.642C19.502 19.604 16.035 22 12 22 6.477 22 2 17.523 2 12a.77.77 0 0 1 .77-.77Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgRefresh
