import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgPrint: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.75a.25.25 0 0 0-.25.25v3.25h8.5V4a.25.25 0 0 0-.25-.25H8Zm9.75 3.5V4A1.75 1.75 0 0 0 16 2.25H8A1.75 1.75 0 0 0 6.25 4v3.25H5A2.75 2.75 0 0 0 2.25 10v5A2.75 2.75 0 0 0 5 17.75h1.25V20c0 .966.784 1.75 1.75 1.75h8A1.75 1.75 0 0 0 17.75 20v-2.25H19A2.75 2.75 0 0 0 21.75 15v-5A2.75 2.75 0 0 0 19 7.25h-1.25ZM5 8.75c-.69 0-1.25.56-1.25 1.25v5c0 .69.56 1.25 1.25 1.25h1.25V13.8a.75.75 0 0 1 .75-.75h10a.75.75 0 0 1 .75.75v2.45H19c.69 0 1.25-.56 1.25-1.25v-5c0-.69-.56-1.25-1.25-1.25H5Zm11.25 5.8h-8.5V20c0 .138.112.25.25.25h8a.25.25 0 0 0 .25-.25v-5.45ZM6.25 11a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgPrint
