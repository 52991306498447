import typographyToStyle from '@/utils/typographyToStyle'
import { makeStyles, css } from '@conexasaude/styles'
import { TabProps } from './types'
import { getSizeStyles } from './utils'

const useStyles = makeStyles<Pick<TabProps, 'active' | 'size'>>({
  root: ({ colors }, { active, size }) => {
    const { marginRight } = getSizeStyles(size)
    return css`
      margin: 0;
      border: none;
      background: transparent;
      cursor: pointer;
      pointer-events: all;
      margin-right: ${marginRight}px;
      padding: 5px 0;
      position: relative;
      border-bottom: 2px solid;
      border-color: ${active ? colors.primary[500] : 'transparent'};
      transition: border-color 0.1s ease-in-out;
      z-index: 1;
    `
  },

  label: ({ colors, typography }, { active }) => css`
    transition: color 0.1s ease-in-out;
    cursor: pointer;
    white-space: nowrap;
    color: ${active ? colors.primary[500] : colors.neutral[500]};
    ${typographyToStyle(typography.link)};
  `,
})

export default useStyles
