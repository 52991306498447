import { createContext, useContext } from 'react'
import { IRadioGroupContext } from './types'

export const RadioGroupContext = createContext<IRadioGroupContext>(null)

export const useRadioGroup = (): IRadioGroupContext => {
  const context = useContext(RadioGroupContext)

  return context
}
