import { css, Global } from '@emotion/react'
import { FunctionComponent } from 'react'
import { boxSizingReset, normalize, reset, fonts } from '@/styles'
import type { ResetStylesProps } from './types'
import { generateCustomFonts } from '../../utils/generateCustomFonts'

export const ResetStyles: FunctionComponent<ResetStylesProps> = ({
  customFonts,
}) => {
  return (
    <Global
      styles={css`
        ${reset};
        ${normalize};
        ${fonts};
        ${customFonts ? generateCustomFonts({ fonts: customFonts }) : ''}
        body {
          ${boxSizingReset};
        }
      `}
    />
  )
}
