import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMinus: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 16 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1c0-.552.398-1 .889-1H15.11c.491 0 .889.448.889 1s-.398 1-.889 1H.89C.398 2 0 1.552 0 1Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMinus
