import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMicrophoneOff: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 11.25a.75.75 0 0 1 .75.75A2.751 2.751 0 0 0 12 14.752a.75.75 0 0 1 0 1.5A4.251 4.251 0 0 1 7.75 12a.75.75 0 0 1 .75-.75ZM8.5 8.75a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 7.75a.75.75 0 0 1 .75.75V12a.75.75 0 1 1-1.5 0V8.5a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.498 10.75a.75.75 0 0 1 .749.75 6.744 6.744 0 0 0 11.29 4.99.75.75 0 0 1 1.012 1.107A8.244 8.244 0 0 1 3.747 11.5a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 18.253a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .75-.75ZM3.466 3.466a.75.75 0 0 1 1.061 0l16.007 16.007a.75.75 0 0 1-1.061 1.06L3.466 4.528a.75.75 0 0 1 0-1.06ZM8.238 4.566A4.236 4.236 0 0 1 16.252 6.5V11a.75.75 0 0 1-1.5 0V6.495a2.736 2.736 0 0 0-5.176-1.25.75.75 0 1 1-1.338-.678ZM19.505 10.75a.75.75 0 0 1 .748.752 8.159 8.159 0 0 1-.835 3.572.75.75 0 1 1-1.346-.661 6.658 6.658 0 0 0 .681-2.915.75.75 0 0 1 .752-.748Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.006 13.945a.75.75 0 0 1 .001 1.061 4.238 4.238 0 0 1-3.006 1.245.75.75 0 0 1 0-1.5c.729.001 1.43-.288 1.944-.804a.75.75 0 0 1 1.061-.002Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMicrophoneOff
