import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFileImage: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.0033 7.99867H16.0016C15.4491 7.99867 15.0012 7.55076 15.0012 6.99825V2.99658"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.99744 7.99867V4.99742C5.99744 3.89239 6.89324 2.99658 7.99827 2.99658H15.1733C15.7039 2.99658 16.2127 3.20757 16.5878 3.58283L19.417 6.412C19.7923 6.78715 20.0033 7.29598 20.0033 7.82659V19.0032C20.0033 20.1083 19.1075 21.0041 18.0024 21.0041H7.99827C6.89324 21.0041 5.99744 20.1083 5.99744 19.0032V18.0028"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.38866 10.9994C6.46883 11.0787 6.49318 11.1986 6.45031 11.3029C6.40745 11.4072 6.30584 11.4753 6.19308 11.4753C6.08032 11.4753 5.97871 11.4072 5.93584 11.3029C5.89298 11.1986 5.91733 11.0787 5.9975 10.9994C6.10632 10.8915 6.28184 10.8915 6.39066 10.9994"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0004 11.9502C11.4628 11.7149 9.89938 12.1655 8.72294 13.1831C7.54651 14.2007 6.8754 15.683 6.88686 17.2384C6.88843 17.4943 6.90883 17.7498 6.94789 18.0027"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.99622"
        y="7.99841"
        width="10.0042"
        height="10.0042"
        rx="3"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgFileImage
