import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgDashboard: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.128 3.962a3.167 3.167 0 0 0-3.166 3.166v9.744a3.167 3.167 0 0 0 3.166 3.166h9.744a3.167 3.167 0 0 0 3.166-3.166V7.128a3.167 3.167 0 0 0-3.166-3.166H7.128ZM2.5 7.128A4.628 4.628 0 0 1 7.128 2.5h9.744A4.628 4.628 0 0 1 21.5 7.128v9.744a4.628 4.628 0 0 1-4.628 4.628H7.128A4.628 4.628 0 0 1 2.5 16.872V7.128Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m11.124 8.615 2.825 5.648 1.16-2.32a1.219 1.219 0 0 1 1.09-.674h4.57a.73.73 0 0 1 0 1.462h-4.42l-1.31 2.62c-.45.9-1.731.9-2.18.001l-2.833-5.664L8.73 12.09v.002a1.22 1.22 0 0 1-1.072.638H3.231a.73.73 0 0 1 0-1.462h4.282l1.45-2.688a1.218 1.218 0 0 1 2.16.034Zm-.875.66Zm-.432-.007ZM13.73 14.7Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDashboard
