import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFontBold: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 6A.75.75 0 0 1 7 5.25h5.875a3.75 3.75 0 1 1 0 7.5H7a.75.75 0 0 1-.75-.75V6Zm1.5.75v4.5h5.125a2.25 2.25 0 0 0 0-4.5H7.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 12a.75.75 0 0 1 .75-.75h7a3.75 3.75 0 1 1 0 7.5H7a.75.75 0 0 1-.75-.75v-6Zm1.5.75v4.5H14a2.25 2.25 0 0 0 0-4.5H7.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFontBold
