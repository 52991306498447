import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgClipboardSaved: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.167 12.321c.29.293.29.768 0 1.06l-2.768 2.792a.74.74 0 0 1-1.051.001l-1.665-1.673a.755.755 0 0 1-.002-1.06.74.74 0 0 1 1.052-.002l1.139 1.144 2.243-2.262a.74.74 0 0 1 1.052 0ZM9.946 3.555 8.843 4.939v.72c0 .134.109.243.243.243h5.828c.134 0 .243-.11.243-.244V4.94l-1.102-1.384a.244.244 0 0 0-.19-.092h-3.73a.244.244 0 0 0-.19.092Zm5.94 1.128.569-.457-1.262-1.586A1.698 1.698 0 0 0 13.864 2h-3.73c-.516 0-1.004.235-1.326.64L7.545 4.226a.733.733 0 0 0-.16.46v-.003h.73v.731a.728.728 0 0 1-.73-.728v.972c0 .943.762 1.708 1.7 1.708h5.83c.938 0 1.7-.765 1.7-1.708v-.975h-.73Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.634A2.677 2.677 0 0 1 6.171 3.95h1.943a.73.73 0 0 1 .729.732.73.73 0 0 1-.729.731H6.171c-.67 0-1.214.546-1.214 1.22v12.683c0 .674.543 1.22 1.214 1.22H17.83c.67 0 1.214-.546 1.214-1.22V6.634c0-.674-.543-1.22-1.214-1.22h-1.943a.73.73 0 0 1-.729-.731.73.73 0 0 1 .729-.732h1.943c1.475 0 2.671 1.2 2.671 2.683v12.683A2.677 2.677 0 0 1 17.829 22H6.17A2.677 2.677 0 0 1 3.5 19.317V6.634Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgClipboardSaved
