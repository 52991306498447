import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMore: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 18.004a1.247 1.247 0 0 1 1.247-1.254c.69 0 1.253.558 1.253 1.25 0 .69-.56 1.25-1.25 1.25a1.252 1.252 0 0 1-1.25-1.246Zm.688-.004h-.688v.004M10.75 12.004a1.247 1.247 0 0 1 1.247-1.254c.69 0 1.253.558 1.253 1.25 0 .69-.56 1.25-1.25 1.25a1.252 1.252 0 0 1-1.25-1.246Zm.688-.004h-.688v.004M10.75 6.004a1.247 1.247 0 0 1 1.247-1.254c.69 0 1.253.558 1.253 1.25 0 .69-.56 1.25-1.25 1.25a1.252 1.252 0 0 1-1.25-1.246ZM11.438 6h-.688v.004"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMore
