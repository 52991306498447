import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgSettings: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        clipRule="evenodd"
        d="M14.121 9.879A3 3 0 1 1 9.88 14.12 3 3 0 0 1 14.12 9.88Z"
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M16.18 18.725a1.29 1.29 0 0 0 1.823 0l.722-.722a1.29 1.29 0 0 0 0-1.823 1.324 1.324 0 0 1-.284-1.453 7.63 7.63 0 0 0 .065-.16 1.217 1.217 0 0 1 1.137-.766h.067c.712 0 1.289-.577 1.289-1.289v-1.021c0-.712-.577-1.289-1.289-1.289h-.067c-.5 0-.954-.301-1.137-.766a7.63 7.63 0 0 0-.065-.16 1.324 1.324 0 0 1 .284-1.453 1.29 1.29 0 0 0 0-1.823l-.722-.722a1.29 1.29 0 0 0-1.823 0 1.324 1.324 0 0 1-1.453.284l-.16-.065a1.227 1.227 0 0 1-.767-1.141v-.067C13.8 3.577 13.223 3 12.511 3H11.49a1.29 1.29 0 0 0-1.29 1.289v.067c0 .5-.301.954-.766 1.137-.054.022-.107.043-.16.066a1.324 1.324 0 0 1-1.453-.284 1.29 1.29 0 0 0-1.823 0l-.723.722a1.29 1.29 0 0 0 0 1.823c.382.382.494.955.284 1.453a5.419 5.419 0 0 0-.065.161 1.22 1.22 0 0 1-1.138.766h-.067C3.577 10.2 3 10.777 3 11.489v1.021c0 .713.577 1.29 1.289 1.29h.067c.5 0 .954.301 1.137.766l.065.16c.21.498.098 1.071-.284 1.453a1.29 1.29 0 0 0 0 1.823l.722.722a1.29 1.29 0 0 0 1.823 0 1.324 1.324 0 0 1 1.453-.284l.16.065c.466.183.766.637.766 1.137v.067c0 .712.577 1.289 1.289 1.289h1.021c.712 0 1.289-.577 1.289-1.289v-.067c0-.5.301-.954.766-1.137l.16-.065a1.332 1.332 0 0 1 1.457.285Z"
        stroke={props.color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgSettings
