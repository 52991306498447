import { generateUniqueId } from './../../utils/generateUniqueId'
import {
  ToastConfig,
  EventType,
  ToastPayload,
  ISubscribers,
  MessageType,
  MessageCallback,
  DismissCallback,
  DismissAllCallback,
  MessageIdType,
  AllowedCallback,
} from './types'

const DEFAULT_EVENT = 'message' as const

export interface ToastSubscribe {
  (event: 'message', callback: MessageCallback): () => void
  (event: 'dismiss', callback: DismissCallback): () => void
  (event: 'dismissAll', callback: DismissAllCallback): () => void
}

export interface PublishType {
  (
    event?: 'message',
    messageOrMessageId?: MessageType,
    payload?: ToastPayload,
  ): void
  (
    event: 'dismiss',
    messageOrMessageId?: MessageIdType,
    payload?: ToastPayload,
  ): void
  (event: 'dismissAll'): void
}

export interface ToastControllerType {
  (
    message: MessageType,
    config?: ToastConfig,
    type?: ToastPayload['type'],
  ): MessageIdType
  dismiss(messageId: MessageIdType): void
  dismissAll(): void
  subscribe: ToastSubscribe
}

// TODO: write unit tests
function createToastController(): ToastControllerType {
  const subscribers: ISubscribers = { message: [], dismiss: [], dismissAll: [] }

  const publish = (
    event: EventType = DEFAULT_EVENT,
    messageOrMessageId: MessageType | MessageIdType = '',
    payload?: ToastPayload,
  ) => {
    subscribers[event] &&
      subscribers[event].forEach(subscriber => {
        // TODO: corrigir parametros inferidos como any
        subscriber(messageOrMessageId, payload)
      })
  }

  const toast: ToastControllerType = (message, config, type) => {
    const messageId = generateUniqueId()
    publish('message', message, { ...config, type, messageId })
    return messageId
  }

  const unsubscribe = (
    event: EventType = DEFAULT_EVENT,
    callback: AllowedCallback,
  ) => {
    subscribers[event].findIndex(
      (savedCallback: AllowedCallback) => savedCallback === callback,
    )
  }

  toast.dismiss = messageId => {
    publish('dismiss', messageId)
  }

  toast.dismissAll = () => {
    publish('dismissAll')
  }

  // TODO: corrigir parametros inferidos como any
  toast.subscribe = (event, callback) => {
    if (!subscribers[event]) subscribers[event] = []
    subscribers[event].push(callback)

    return () => unsubscribe(event, callback)
  }

  return toast
}

export const toast = createToastController()
