import { makeStyles, css } from '@conexasaude/styles'
import typographyToStyle from '@/utils/typographyToStyle'

export const useStyles = makeStyles<{
  isMobile: boolean
}>({
  root: ({ colors }, { isMobile }) => css`
    width: 100%;
    height: 72px;
    padding: ${isMobile ? '16px;' : '16px 24px;'}
    border-bottom: 1px solid ${colors.neutral[100]};

    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  rightSide: css`
    display: flex;
    align-items: center;
  `,
  leftSide: css`
    display: flex;
    align-items: center;
  `,
  rightSideIcon: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    pointer-events: all;
    background: transparent;
    border: none;
  `,
  leftSideIcon: (theme, { isMobile }) => css`
    display: flex;
    align-items: center;
    margin-right: ${isMobile ? '0' : '16px'};
    cursor: pointer;
    pointer-events: all;
    background: transparent;
    border: none;
  `,
  logo: css`
    > img {
      width: 115px;
    }
  `,
  rightSideContent: (theme, { isMobile }) => css`
    margin-left: ${isMobile ? '0' : '16px'};
    display: flex;
    align-items: center;

    ${isMobile &&
    `
      .css-9wvx7c-Avatar > span {
        display: none;
      }
    `}
  `,
  label: theme => css`
    ${typographyToStyle(theme.typography.h3)};
  `,
  avatarButton: ({ spacing }) => css`
    margin-left: ${spacing(1)}px;
    background: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    padding: 0;
  `,
  button: css``,
})

export default useStyles
