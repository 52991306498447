import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFontUnderline: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 5a.75.75 0 0 1 .75.75v6a3.25 3.25 0 0 0 6.5 0v-6a.75.75 0 0 1 1.5 0v6a4.75 4.75 0 1 1-9.5 0v-6A.75.75 0 0 1 8 5ZM6.25 18.75A.75.75 0 0 1 7 18h10a.75.75 0 0 1 0 1.5H7a.75.75 0 0 1-.75-.75Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgFontUnderline
