import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMedicalCertificate: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.031 7.646a3.75 3.75 0 0 1 1.677-.396H8a.75.75 0 1 1 0 1.5H6.708c-.35 0-.694.08-1.006.237l-.585.293a2.25 2.25 0 0 0-.924.855l-1.55 2.584a.75.75 0 1 1-1.286-.771l1.55-2.585A3.75 3.75 0 0 1 4.447 7.94m0 0 .585-.293M17.5 5.25a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0V6a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.25 7.5a.75.75 0 0 1 .75-.75h3a.75.75 0 1 1 0 1.5h-3a.75.75 0 0 1-.75-.75ZM16.25 13a.75.75 0 0 1 .75-.75h2a.75.75 0 1 1 0 1.5h-2a.75.75 0 0 1-.75-.75ZM14.25 16a.75.75 0 0 1 .75-.75h4a.75.75 0 1 1 0 1.5h-4a.75.75 0 0 1-.75-.75ZM10.66 10.28a2.396 2.396 0 0 1 3.687 3.023l-2.993 4.488a4.75 4.75 0 0 1-2.337 1.832l-5.762 2.082a.75.75 0 1 1-.51-1.41l5.762-2.082a3.25 3.25 0 0 0 1.6-1.254l2.993-4.488a.896.896 0 0 0-1.38-1.13l-2.19 2.19a.75.75 0 0 1-.53.219H7a.75.75 0 1 1 0-1.5h1.69l1.97-1.97Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 4.5A2.25 2.25 0 0 1 9.5 2.25h11a2.25 2.25 0 0 1 2.25 2.25v15a2.25 2.25 0 0 1-2.25 2.25h-11a2.25 2.25 0 0 1-2.25-2.25v-.307a.75.75 0 0 1 1.5 0v.307c0 .415.336.75.75.75h11a.75.75 0 0 0 .75-.75v-15a.75.75 0 0 0-.75-.75h-11a.75.75 0 0 0-.75.75V13a.75.75 0 1 1-1.5 0V4.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMedicalCertificate
