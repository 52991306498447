import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgBrain: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.165 2.84a3.849 3.849 0 0 1 6.14 2.1l.004.016a.27.27 0 0 0 .204.206 4.355 4.355 0 0 1 2.757 6.681l-.004.007a.256.256 0 0 0 0 .29l.005.006a4.355 4.355 0 0 1-2.758 6.682.27.27 0 0 0-.204.205l-.003.015a3.845 3.845 0 0 1-6.14 2.112l-.004-.004a.257.257 0 0 0-.323 0l-.004.003a3.849 3.849 0 0 1-6.14-2.099l-.004-.016a.27.27 0 0 0-.204-.206 4.355 4.355 0 0 1-2.758-6.68l.005-.008a.256.256 0 0 0 0-.29l-.006-.008a4.355 4.355 0 0 1 2.759-6.68.27.27 0 0 0 .204-.205l.004-.016a3.845 3.845 0 0 1 6.14-2.105l.004.003c.084.068.2.075.291.022a.715.715 0 0 1 .035-.03Zm1.012 1.162a1.796 1.796 0 0 1-2.304.044 2.31 2.31 0 0 0-3.68 1.258 1.808 1.808 0 0 1-1.386 1.372l-.008.002a2.821 2.821 0 0 0-1.791 4.32 1.793 1.793 0 0 1 0 2.014 2.818 2.818 0 0 0 1.79 4.32l.01.002c.689.148 1.23.684 1.384 1.371a2.308 2.308 0 0 0 3.681 1.255 1.796 1.796 0 0 1 2.254 0 2.31 2.31 0 0 0 3.681-1.264 1.808 1.808 0 0 1 1.385-1.372l.009-.002a2.821 2.821 0 0 0 1.79-4.321 1.793 1.793 0 0 1 0-2.012 2.818 2.818 0 0 0-1.79-4.322l-.009-.001a1.808 1.808 0 0 1-1.385-1.371 2.308 2.308 0 0 0-3.63-1.293Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.904c.425 0 .77.344.77.769v16.653a.769.769 0 0 1-1.539 0V3.673c0-.425.344-.769.77-.769ZM17.13 14.82c-.709 0-1.283.573-1.283 1.281a.769.769 0 0 1-1.538 0 2.82 2.82 0 0 1 2.82-2.82.769.769 0 1 1 0 1.538ZM8.922 7.13c.425 0 .77.345.77.769a2.82 2.82 0 0 1-2.821 2.82.769.769 0 1 1 0-1.538c.708 0 1.282-.574 1.282-1.282 0-.424.345-.769.77-.769ZM6.101 14.05c0-.424.345-.768.77-.768A2.82 2.82 0 0 1 9.69 16.1a.769.769 0 0 1-1.538 0c0-.708-.574-1.282-1.282-1.282a.769.769 0 0 1-.77-.769ZM15.078 7.13c.425 0 .769.345.769.769 0 .708.574 1.282 1.283 1.282a.769.769 0 1 1 0 1.537A2.82 2.82 0 0 1 14.309 7.9c0-.424.344-.769.769-.769Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgBrain
