import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgScaleWeight: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 7.897A5.898 5.898 0 0 1 7.897 2h8.206A5.898 5.898 0 0 1 22 7.897v8.206A5.898 5.898 0 0 1 16.103 22H7.897A5.898 5.898 0 0 1 2 16.103V7.897Zm5.897-4.359a4.36 4.36 0 0 0-4.359 4.36v8.205a4.36 4.36 0 0 0 4.36 4.358h8.205a4.36 4.36 0 0 0 4.358-4.358V7.897a4.36 4.36 0 0 0-4.358-4.359H7.897Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7.06a3.334 3.334 0 0 0-3.333 3.334c0 .141.115.256.256.256h6.154a.256.256 0 0 0 .256-.256A3.333 3.333 0 0 0 12 7.06Zm-4.872 3.334a4.872 4.872 0 0 1 9.744 0c0 .992-.805 1.795-1.795 1.795H8.923a1.795 1.795 0 0 1-1.795-1.795Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.37 8.676a.77.77 0 0 1 .344 1.032l-1.026 2.051a.77.77 0 1 1-1.376-.688l1.026-2.05a.77.77 0 0 1 1.032-.345Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgScaleWeight
