import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgImage: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.558 4.385c-.642 0-1.162.516-1.162 1.153v12.924c0 .637.52 1.153 1.162 1.153h14.88c.643 0 1.164-.516 1.164-1.153V5.538c0-.637-.52-1.153-1.163-1.153H4.559ZM2 5.538A2.548 2.548 0 0 1 4.558 3h14.88a2.548 2.548 0 0 1 2.56 2.538v12.924A2.549 2.549 0 0 1 19.437 21H4.559A2.548 2.548 0 0 1 2 18.462V5.538Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1 8.415a1.17 1.17 0 0 0-1.645 0 1.147 1.147 0 0 0 0 1.632c.454.45 1.19.45 1.645 0a1.147 1.147 0 0 0 0-1.632Zm.986-.98c-1-.99-2.62-.99-3.618 0-1 .992-1 2.6 0 3.59 1 .992 2.62.992 3.618 0 1-.99 1-2.598 0-3.59ZM15.524 12.288a1.638 1.638 0 0 1 2.29-.252l3.924 3.116c.301.239.35.674.11.973a.702.702 0 0 1-.982.108l-3.925-3.116a.235.235 0 0 0-.327.036m0 0-3.104 3.85a1.638 1.638 0 0 1-2.288.252l-1.739-1.38a.234.234 0 0 0-.324.032l-4.067 4.844a.701.701 0 0 1-.983.088.689.689 0 0 1-.09-.974l4.068-4.844a1.637 1.637 0 0 1 2.268-.228l1.738 1.38c.1.08.248.064.328-.035l3.103-3.85"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgImage
