import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgDoctorTalk: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.262 2.75a1.04 1.04 0 0 0-1.04 1.04v1.039h5.659v-1.04a1.04 1.04 0 0 0-1.04-1.039H8.262Zm6.119 1.04a2.54 2.54 0 0 0-2.54-2.54H8.262a2.54 2.54 0 0 0-2.54 2.54v3.578a4.32 4.32 0 0 0 1.6 3.36l-.335 1.342a1.56 1.56 0 0 0-.43.025l-.216.043a.82.82 0 0 0-.032.007l-2.299.46a3.434 3.434 0 0 0-2.761 3.367v.344a.75.75 0 0 0 1.5 0v-.344c0-.922.65-1.716 1.555-1.897l1.419-.283v1.902a2.252 2.252 0 1 0 1.5 0v-1.912c1.133.67 2.386.908 3.724.643a.75.75 0 0 0-.291-1.472 3.628 3.628 0 0 1-2.28-.257l.305-1.22c.431.144.892.221 1.37.221.482 0 .946-.078 1.379-.224l.092.359a.75.75 0 1 0 1.453-.375l-.19-.732a4.32 4.32 0 0 0 1.596-3.357V3.79Zm-1.5 2.539H7.223v1.04a2.827 2.827 0 0 0 2.829 2.828 2.829 2.829 0 0 0 2.829-2.829v-1.04ZM6.473 17.066a.75.75 0 1 0 0 1.501.75.75 0 0 0 0-1.501ZM11.443 16.904a5.654 5.654 0 1 1 5.654 5.654.75.75 0 0 1 0-1.5 4.155 4.155 0 1 0-4.154-4.154.75.75 0 0 1-1.5 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.193 16.154a.75.75 0 0 1 .75.75c0 .628.139 1.222.387 1.756a.75.75 0 0 1 .054.47l-.396 1.898 1.893-.403a.75.75 0 0 1 .471.054 4.148 4.148 0 0 0 1.75.384.75.75 0 1 1 0 1.5c-.758 0-1.482-.15-2.144-.42l-2.783.59a.75.75 0 0 1-.89-.886l.582-2.79a5.651 5.651 0 0 1-.424-2.153.75.75 0 0 1 .75-.75ZM14.814 16.965a.05.05 0 1 1-.07.07.05.05 0 0 1 .07-.07Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.213 16.434a.8.8 0 1 1 1.132 1.132.8.8 0 0 1-1.132-1.132ZM17.064 16.965a.05.05 0 1 1-.07.07.05.05 0 0 1 .07-.07Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.463 16.434a.8.8 0 1 1 1.132 1.132.8.8 0 0 1-1.132-1.132ZM19.314 16.965a.05.05 0 1 1-.07.07.05.05 0 0 1 .07-.07Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.713 16.434a.8.8 0 1 1 1.132 1.132.8.8 0 0 1-1.132-1.132Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgDoctorTalk
