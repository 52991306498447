import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgHeartbeat: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.086 21.23a.77.77 0 0 1 .771-.768h10.286a.77.77 0 1 1 0 1.538H6.857a.77.77 0 0 1-.771-.77ZM5.829 3.538c-.71 0-1.286.574-1.286 1.283v1.025a.77.77 0 0 1-1.543 0V4.821A2.824 2.824 0 0 1 5.829 2H18.17A2.824 2.824 0 0 1 21 4.82V9.95a.77.77 0 0 1-.771.769h-4.115a.772.772 0 0 1-.622-.315l-1.714-2.335-2.73 5.582a.771.771 0 0 1-1.316.117l-2.238-3.05H4.543v4.36c0 .709.575 1.282 1.285 1.282h12.345c.709 0 1.284-.573 1.284-1.282V14.05a.77.77 0 0 1 .772-.769.77.77 0 0 1 .771.77v1.025a2.824 2.824 0 0 1-2.828 2.82H5.829A2.824 2.824 0 0 1 3 15.077V9.949a.77.77 0 0 1 .771-.77h4.115c.246 0 .477.117.622.315l1.714 2.335 2.73-5.582a.771.771 0 0 1 1.316-.117l2.238 3.05h2.951V4.82c0-.708-.575-1.282-1.286-1.282H5.83Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHeartbeat
