import { SerializedStyles } from '@emotion/react'
import { makeStyles, css } from '@conexasaude/styles'

const size = 16

const useStyles = makeStyles<{
  checkedOverrides?: SerializedStyles
  disabled: boolean
}>({
  root: ({ spacing }) => css`
    padding: ${spacing(1)}px;
    position: relative;
    flex: 0 0 auto;
    overflow: visible;
    display: inline-flex;
    appearance: none;
  `,

  innerBox: theme => css`
    width: ${size}px;
    height: ${size}px;
    color: ${theme.colors.common.white};
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,

  input: (theme, { checkedOverrides }) => css`
    z-index: 1;
    appearance: none;
    opacity: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: all;
    cursor: pointer;
    background: transparent;

    /* // TODO: suprimir aninhamento de estilos pois dificulta overrides */
    &:checked {
      /**
        + div  = .hero-radio__visualRadio element
      */
      & + div > svg {
        display: inline-block;
      }

      ${checkedOverrides || ''}
    }
  `,

  visualRadio: theme => css`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    :before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      border-radius: ${theme.borderRadius.circular};
      box-sizing: border-box;
      border: 1px solid;
      border-color: ${theme.colors.primary[500]};
      background: ${theme.colors.common.white};
      position: absolute;
      top: 0;
      left: 0;
    }
  `,

  icon: ({ colors }, { disabled }) => css`
    display: none;
    position: absolute;
    pointer-events: none;
    align-self: center;
    cursor: pointer;
    z-index: 0;

    > circle {
      fill: ${disabled ? colors.extra.disabled_font : colors.primary[500]};
    }
  `,

  disabled: theme => css`
    cursor: not-allowed;
    pointer-events: none;

    /**
      + div  = .hero-radio__visualRadio element
    */
    & + div:before {
      border-color: ${theme.colors.extra.disabled_bg};
    }
  `,
})

export default useStyles
