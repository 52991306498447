import { theme } from '@conexasaude/styles'
import { useMemo } from 'react'
import { TabProps, Size, sizes, SizeStyles, UseValidateProps } from './types'

export const isSize = (value: Size): value is Size => sizes.includes(value)

export const useValidateProps: UseValidateProps = ({ sizeProp }) => {
  const size = useMemo<TabProps['size']>(() => {
    if (!isSize(sizeProp)) {
      console.warn(
        'The provided size property is not a valid size. A default value will be used.',
      )
      return 'md'
    }
    return sizeProp
  }, [sizeProp])
  return { size }
}

export const getSizeStyles = (size: TabProps['size']): SizeStyles => {
  const sizesToStyles = {
    sm: {
      marginRight: theme.spacing(4),
    },
    md: {
      marginRight: theme.spacing(5),
    },
  }

  const currStyles = sizesToStyles[size]
  return currStyles
}
