import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgHome: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.757 3.27a3.653 3.653 0 0 1 4.487 0l5.845 4.547a3.653 3.653 0 0 1 1.411 2.884v7.145a3.654 3.654 0 0 1-3.654 3.654H6.154A3.654 3.654 0 0 1 2.5 17.846v-7.145c0-1.127.52-2.192 1.41-2.884L9.757 3.27Zm.897 1.154L4.808 8.97a2.191 2.191 0 0 0-.846 1.73v7.145c0 1.211.98 2.192 2.192 2.192h11.692c1.211 0 2.192-.98 2.192-2.192v-7.145a2.19 2.19 0 0 0-.846-1.73l-5.846-4.547a2.192 2.192 0 0 0-2.692 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.55 11.912a2.192 2.192 0 1 0-3.1 3.1 2.192 2.192 0 0 0 3.1-3.1Zm1.033-1.034a3.654 3.654 0 1 0-5.167 5.167 3.654 3.654 0 0 0 5.167-5.167Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHome
