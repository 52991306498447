import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgHospital: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.326 9.432c0-.425.343-.77.767-.77h4.093c.99 0 1.79.805 1.79 1.797V20.73c0 .425-.343.77-.766.77a.769.769 0 0 1-.768-.77V10.46a.257.257 0 0 0-.256-.258h-4.093a.769.769 0 0 1-.767-.77Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.814 4.04a.257.257 0 0 0-.256.257V20.73a.769.769 0 1 1-1.534 0V4.297c0-.992.8-1.797 1.79-1.797H15.07c.989 0 1.79.805 1.79 1.797V20.73c0 .425-.343.77-.767.77a.769.769 0 0 1-.767-.77V4.297a.257.257 0 0 0-.256-.257H3.814Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.023 20.73c0 .425-.343.77-.767.77H6.628a.769.769 0 0 1-.767-.77v-4.108c0-.425.343-.77.767-.77h5.628c.424 0 .767.345.767.77v4.108Zm-1.534-.77v-2.568H7.395v2.568h4.094Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.442 15.852c.423 0 .767.345.767.77v4.108c0 .425-.344.77-.767.77a.769.769 0 0 1-.767-.77v-4.108c0-.425.343-.77.767-.77Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 20.73c0-.425.343-.77.767-.77h20.466c.424 0 .767.345.767.77 0 .425-.343.77-.767.77H1.767A.769.769 0 0 1 1 20.73ZM9.442 7.635c.423 0 .767.345.767.77v3.082c0 .425-.344.77-.767.77a.769.769 0 0 1-.767-.77V8.404c0-.425.343-.77.767-.77Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14 9.946c0-.425.343-.77.767-.77h3.07c.423 0 .767.345.767.77 0 .425-.344.77-.767.77h-3.07a.769.769 0 0 1-.767-.77Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgHospital
