import { AvatarProps } from './types'
import { makeStyles, css } from '@conexasaude/styles'
import { getSizeStyles } from './utils'
import typographyToStyle from '@/utils/typographyToStyle'

const useStyles = makeStyles<{ size: AvatarProps['size'] }>({
  root: css`
    position: relative;
    display: inline-flex;
    align-items: center;
  `,

  avatar: (theme, { size }) => {
    const { avatar } = getSizeStyles(size)
    return css`
      position: relative;
      display: inline-flex;
      width: ${avatar.size.imgWidth}px;
      height: ${avatar.size.imgHeight}px;
    `
  },

  label: (theme, { size }) => {
    const { name, avatar } = getSizeStyles(size)
    return css`
      ${typographyToStyle(name.style)}
      margin-left: ${avatar.margin}px;
    `
  },

  image: theme => css`
    z-index: 0;
    object-fit: cover;
    position: relative;
    border-radius: ${theme.borderRadius.circular};
    width: 100%;
    height: 100%;
  `,

  notification: (theme, { size }) => {
    const { avatar } = getSizeStyles(size)
    return css`
      z-index: 1;
      position: absolute;
      border-radius: ${theme.borderRadius.circular};
      box-sizing: border-box;
      right: 0;
      width: ${avatar.size.notificationWidth}px;
      height: ${avatar.size.notificationHeight}px;
      border: ${avatar.border.notificationBorder};
      background: ${theme.colors.success[500]};
    `
  },

  avatarDefault: (theme, { size }) => {
    const { avatar } = getSizeStyles(size)
    return css`
      width: ${avatar.size.imgWidth}px;
      height: ${avatar.size.imgHeight}px;
      border-radius: ${theme.borderRadius.circular};
      background: ${theme.colors.extra.disabled_bg};
    `
  },
})

export default useStyles
