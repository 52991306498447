import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgArrowRight: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.24 7.231a.84.84 0 0 1 1.157 0l4.363 4.21a.77.77 0 0 1 0 1.117l-4.363 4.21a.84.84 0 0 1-1.157 0 .77.77 0 0 1 0-1.116L13.025 12 9.24 8.348a.77.77 0 0 1 0-1.117Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowRight
