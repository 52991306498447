import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgNotification: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.292 17.593a.75.75 0 0 1 .75.75v.365a3.042 3.042 0 0 1-6.084.001v-.365a.75.75 0 0 1 1.5 0v.365a1.542 1.542 0 0 0 3.084-.001v-.365a.75.75 0 0 1 .75-.75ZM9.417 4.833a2.583 2.583 0 1 1 5.166 0v.918a.75.75 0 0 1-1.5 0v-.918a1.083 1.083 0 1 0-2.166 0v.918a.75.75 0 0 1-1.5 0v-.918Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.094 6.407a3.78 3.78 0 0 0-3.781 3.781v2.797c0 .73-.29 1.429-.806 1.944l-.64.641a1.25 1.25 0 0 0-.367.884c0 .63.51 1.14 1.14 1.14h10.721c.63 0 1.14-.51 1.14-1.14 0-.33-.132-.65-.366-.884l-.641-.64a2.75 2.75 0 0 1-.806-1.945v-2.797a3.78 3.78 0 0 0-3.781-3.781h-1.813Zm-5.281 3.781a5.28 5.28 0 0 1 5.281-5.281h1.813a5.28 5.28 0 0 1 5.281 5.281v2.797c0 .33.132.65.366.884l.641.64a2.75 2.75 0 0 1 .806 1.945 2.64 2.64 0 0 1-2.64 2.64H6.64A2.64 2.64 0 0 1 4 16.454c0-.73.29-1.429.806-1.944l.64-.641a1.25 1.25 0 0 0 .367-.884v-2.797Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgNotification
