import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgArrowLeft: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.76 7.231a.77.77 0 0 1 0 1.117L10.975 12l3.785 3.652a.77.77 0 0 1 0 1.117.84.84 0 0 1-1.157 0l-4.363-4.21a.77.77 0 0 1 0-1.117l4.363-4.21a.84.84 0 0 1 1.157 0Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgArrowLeft
