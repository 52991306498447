import { SerializedStyles } from '@emotion/react'
import { Theme } from '@conexasaude/styles'
import { useMemo } from 'react'
import {
  Size,
  sizes,
  Variant,
  variants,
  ButtonProps,
  PropsToValidate,
  ValidatedProps,
  SizeStyles,
} from './types'

export const isSize = (value: Size): value is Size => sizes.includes(value)
export const isVariant = (value: Variant): value is Variant =>
  variants.includes(value)

export const getSizeStyles = (
  size: ButtonProps['size'],
  { spacing, typography }: Partial<Theme>,
): SizeStyles => {
  const borderWidth = 2

  const sizesToStyles = {
    sm: {
      spacing: {
        vertical: spacing(1) - borderWidth,
        horizontal: spacing(2) - borderWidth,
      },
      typography: typography.button1,
    },
    md: {
      spacing: {
        vertical: spacing(2) - borderWidth,
        horizontal: spacing(2) - borderWidth,
      },
      typography: typography.button2,
    },
    lg: {
      spacing: {
        vertical: spacing(3) - borderWidth,
        horizontal: spacing(3) - borderWidth,
      },
      typography: typography.button2,
    },
  }
  const currStyles = sizesToStyles[size]
  return currStyles
}

export const useValidateProps = ({
  sizeProp,
  variantProp,
}: PropsToValidate): ValidatedProps => {
  const size = useMemo(() => {
    if (!isSize(sizeProp)) {
      console.warn(
        'The provided size property is not a valid size. A default value will be used.',
      )
      return 'sm'
    }

    return sizeProp
  }, [sizeProp])

  const variant = useMemo(() => {
    if (!isVariant(variantProp)) {
      console.warn(
        'The provided variant property is not a valid variant. A default value will be used.',
      )
      return 'flat'
    }

    return variantProp
  }, [variantProp])

  return { size, variant }
}

export interface useStateStylesReturn {
  disabledStyles: SerializedStyles[]
  busyStyles: SerializedStyles[]
  variantStyles: SerializedStyles[]
}

export interface useStateStylesProps extends Partial<ButtonProps> {
  overrides?: ButtonProps['styles']
}

export interface IUseStateStyles {
  (props: useStateStylesProps): useStateStylesReturn
}

export const useStateStyles: IUseStateStyles = ({
  disabled,
  busy,
  styles,
  variant,
  overrides,
}) => {
  const disabledStyles = useMemo(() => {
    if (disabled) return [styles.disabled, overrides?.disabled]

    return []
  }, [disabled, styles.disabled, overrides?.disabled])

  const busyStyles = useMemo(() => {
    if (busy) return [styles.busy, overrides?.busy]

    return []
  }, [busy, styles.busy, overrides?.busy])

  const variantStyles = useMemo(() => {
    if (variant) {
      return [styles[variant], overrides?.[variant]]
    }

    return []
  }, [variant, styles, overrides])

  return {
    disabledStyles,
    busyStyles,
    variantStyles,
  }
}
