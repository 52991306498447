import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgClipboard: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.946 3.555 8.843 4.94v.719c0 .134.109.243.243.243h5.828c.134 0 .243-.109.243-.243v-.72l-1.102-1.384a.244.244 0 0 0-.19-.092h-3.73a.244.244 0 0 0-.19.092Zm5.94 1.128.569-.457-1.262-1.585V2.64A1.698 1.698 0 0 0 13.864 2h-3.73c-.516 0-1.004.236-1.326.64L7.545 4.226a.733.733 0 0 0-.16.46v-.003h.73v.732a.728.728 0 0 1-.73-.729v.973c0 .942.762 1.707 1.7 1.707h5.83c.938 0 1.7-.765 1.7-1.707v-.976h-.73Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 6.634a2.677 2.677 0 0 1 2.671-2.683h1.943a.73.73 0 0 1 .729.732.73.73 0 0 1-.729.732H6.171c-.67 0-1.214.545-1.214 1.22v12.682c0 .674.543 1.22 1.214 1.22H17.83c.67 0 1.214-.546 1.214-1.22V6.634c0-.674-.543-1.22-1.214-1.22h-1.943a.73.73 0 0 1-.729-.731.73.73 0 0 1 .729-.732h1.943c1.475 0 2.671 1.201 2.671 2.683v12.683A2.677 2.677 0 0 1 17.829 22H6.17A2.677 2.677 0 0 1 3.5 19.317V6.634Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.386 11.512a.73.73 0 0 1 .728-.732h7.772a.73.73 0 0 1 .728.732.73.73 0 0 1-.728.732H8.114a.73.73 0 0 1-.728-.732ZM7.386 16.39a.73.73 0 0 1 .728-.732h7.772a.73.73 0 0 1 .728.732.73.73 0 0 1-.728.732H8.114a.73.73 0 0 1-.728-.732Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgClipboard
