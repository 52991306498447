import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgMask: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39 13.718a.77.77 0 0 1 .767-.772h5.686a.77.77 0 0 1 .767.772.77.77 0 0 1-.767.773H9.157a.77.77 0 0 1-.767-.773ZM8.39 10.284a.77.77 0 0 1 .767-.773h5.686a.77.77 0 0 1 .767.773.77.77 0 0 1-.767.772H9.157a.77.77 0 0 1-.767-.772Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.998 5.2 6.5 6.641a1.287 1.287 0 0 0-.896 1.227v8.264c0 .562.363 1.058.896 1.227h.002l4.492 1.438a3.29 3.29 0 0 0 2.011 0l4.498-1.437c.532-.17.893-.667.893-1.228V7.87a1.287 1.287 0 0 0-.893-1.224l-4.499-1.442a3.29 3.29 0 0 0-2.006-.005Zm2.474-1.468a4.812 4.812 0 0 0-2.936-.006l-.002.001L6.04 5.168h-.001a2.83 2.83 0 0 0-1.968 2.7v8.265a2.832 2.832 0 0 0 1.968 2.7h.001l4.49 1.437a4.816 4.816 0 0 0 2.942 0l4.497-1.437a2.83 2.83 0 0 0 1.962-2.7V7.867a2.83 2.83 0 0 0-1.962-2.693l-4.496-1.442Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.396 9.139a.77.77 0 0 1 .767-.772h.227C21.384 8.367 23 9.994 23 12c0 2.007-1.616 3.634-3.61 3.634h-.227a.77.77 0 0 1-.767-.772.77.77 0 0 1 .767-.772h.227A2.083 2.083 0 0 0 21.466 12c0-1.154-.93-2.09-2.076-2.09h-.227a.77.77 0 0 1-.767-.772ZM4.61 9.911a2.083 2.083 0 0 0-2.076 2.09c0 1.154.93 2.09 2.076 2.09h.227a.77.77 0 0 1 .767.772.77.77 0 0 1-.767.772H4.61c-1.994 0-3.61-1.627-3.61-3.634 0-2.007 1.616-3.634 3.61-3.634h.227a.77.77 0 0 1 .767.772.77.77 0 0 1-.767.772H4.61ZM8.39 13.718a.77.77 0 0 1 .767-.772h5.686a.77.77 0 0 1 .767.772.77.77 0 0 1-.767.773H9.157a.77.77 0 0 1-.767-.773ZM8.39 10.284a.77.77 0 0 1 .767-.773h5.686a.77.77 0 0 1 .767.773.77.77 0 0 1-.767.772H9.157a.77.77 0 0 1-.767-.772Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgMask
