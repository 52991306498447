import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFileWarning: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0003 12.6588V10.709"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4167 6.41152L15.5875 3.58234C15.2124 3.20708 14.7036 2.99609 14.173 2.99609H6.99796C5.89293 2.99609 4.99713 3.8919 4.99713 4.99693V19.0028C4.99713 20.1078 5.89293 21.0036 6.99796 21.0036H17.0021C18.1072 21.0036 19.003 20.1078 19.003 19.0028V7.82611C19.003 7.29549 18.792 6.78666 18.4167 6.41152Z"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9997 14.8084C11.9445 14.8084 11.8997 14.8532 11.8997 14.9084C11.8997 14.9637 11.9445 15.0085 11.9997 15.0085C12.0433 14.9966 12.0735 14.9571 12.0735 14.9119C12.0735 14.8668 12.0433 14.8272 11.9997 14.8154"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgFileWarning
