import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgFilterClose: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.234 3.473h9.896c.681 0 1.239.553 1.243 1.234v1.034c0 .147-.11.246-.234.258a.253.253 0 0 1-.205-.07l-.541-.517a.26.26 0 0 1-.041-.053.139.139 0 0 1-.025-.05.214.214 0 0 1-.016-.085v-.517a.175.175 0 0 0-.177-.176H4.24a.175.175 0 0 0-.177.176v1.83c0 .044.017.09.05.122l3.682 3.675a.6.6 0 0 1 .086.115l.002.004.002.004.005.008a.144.144 0 0 1 .014.03l.002.007a.29.29 0 0 0 .02.05c.002.003.003.008.004.013l.005.015.001.007c.001.004.003.008.003.013 0 .004 0 .01.002.015a.08.08 0 0 1 .002.014c0 .004 0 .008.002.012a.051.051 0 0 1 .002.013v4.466c0 .23.217.402.439.344l1.767-.443a.356.356 0 0 0 .267-.344v-2.334c0-.147.11-.246.234-.258a.253.253 0 0 1 .205.07l.541.516a.261.261 0 0 1 .041.054l.025.049a.214.214 0 0 1 .016.086V14.673c0 .648-.443 1.214-1.07 1.37l-1.768.442a1.414 1.414 0 0 1-1.755-1.37v-4.19l-3.523-3.52A1.225 1.225 0 0 1 3 6.533V4.707c0-.68.554-1.234 1.234-1.234Zm9.33 3.367a.57.57 0 0 1 .804.804L13.15 8.862l1.218 1.218a.57.57 0 0 1-.804.803l-1.218-1.218-1.218 1.219a.57.57 0 0 1-.803-.804l1.218-1.218-1.218-1.218a.57.57 0 0 1 .803-.804l1.218 1.218 1.218-1.218Z"
        fill={props.color}
      />
    </svg>
  )
}

export default SvgFilterClose
