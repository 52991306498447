import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgCopy: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.103 8.922a2.82 2.82 0 0 1 2.82-2.82H19.18A2.82 2.82 0 0 1 22 8.923v10.259A2.82 2.82 0 0 1 19.18 22H8.923a2.82 2.82 0 0 1-2.82-2.82V8.923Zm2.82-1.281c-.709 0-1.282.573-1.282 1.281v10.259c0 .707.573 1.281 1.281 1.281h10.259c.707 0 1.281-.574 1.281-1.281V8.922c0-.708-.574-1.281-1.281-1.281H8.922Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.82 3.538c-.708 0-1.282.574-1.282 1.282v10.257c0 .708.574 1.282 1.282 1.282h2.052a.769.769 0 0 1 0 1.538H4.82A2.82 2.82 0 0 1 2 15.077V4.82A2.82 2.82 0 0 1 4.82 2h10.257a2.82 2.82 0 0 1 2.82 2.82v2.052a.769.769 0 0 1-1.538 0V4.82c0-.708-.574-1.282-1.282-1.282H4.82Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgCopy
