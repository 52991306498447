import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgRotate: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.424 4.053a.75.75 0 0 1 .75.75v2.893a.75.75 0 0 1-.75.75h-2.893a.75.75 0 0 1 0-1.5h2.143V4.803a.75.75 0 0 1 .75-.75Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12c0-5.384 4.365-9.75 9.75-9.75a9.747 9.747 0 0 1 8.563 5.087.75.75 0 0 1-1.316.718A8.25 8.25 0 1 0 20.25 12a.75.75 0 0 1 1.5 0c0 5.384-4.365 9.75-9.75 9.75S2.25 17.384 2.25 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.945 10.055a2.75 2.75 0 1 0-3.89 3.89 2.75 2.75 0 0 0 3.89-3.89Zm1.06-1.06a4.25 4.25 0 1 0-6.01 6.01 4.25 4.25 0 0 0 6.01-6.01Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgRotate
