import { forwardRef, PropsWithChildren, useCallback } from 'react'
import { RadioGroupContext } from './RadioGroupContext'
import { FormGroup } from '@/components/FormGroup'
import { useControlled } from '@/utils/useControlled'
import { RadioGroupProps, IContextRef } from './types'

export const RadioGroup = forwardRef<
  IContextRef,
  PropsWithChildren<RadioGroupProps>
>(function RadioGroup(
  { children, value: valueProp, defaultValue, name, onChange, ...rest },
  ref,
) {
  const [value, setValueState] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: 'RadioGroup',
  })

  const handleChange = useCallback(
    event => {
      setValueState(event.target.value)

      if (onChange) {
        onChange(event, event.target.value)
      }
    },
    [onChange, setValueState],
  )

  return (
    <RadioGroupContext.Provider
      value={{ value, name, onChange: handleChange, ref }}
    >
      <FormGroup {...rest} rootProps={{ role: 'radiogroup' }}>
        {children}
      </FormGroup>
    </RadioGroupContext.Provider>
  )
})
