import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgUser: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.348 16.472c-2.082 0-3.806 1.625-3.806 3.765A.767.767 0 0 1 4.77 21 .767.767 0 0 1 4 20.237c0-3.005 2.437-5.29 5.348-5.29h5.304c3.038 0 5.348 2.41 5.348 5.29a.767.767 0 0 1-.771.763.767.767 0 0 1-.77-.763c0-2.06-1.643-3.765-3.807-3.765H9.348ZM14.63 5.56a3.774 3.774 0 0 0-5.164.009c-1.41 1.313-1.378 3.599.034 5.083 1.327 1.396 3.638 1.364 5.138-.033 1.429-1.33 1.456-3.6-.009-5.058Zm1.074-1.118a5.346 5.346 0 0 0-7.307.003c-2.126 1.98-1.95 5.25-.034 7.265 2.001 2.103 5.308 1.93 7.344.033 2.104-1.959 2.08-5.238.02-7.28a.778.778 0 0 0-.011-.01l-.012-.011Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgUser
