import { FunctionComponent, useCallback } from 'react'
import { LinkProps, ButtonProps, AnchorProps } from './types'
import { useStateStyles, isAnchorProps } from './utils'
import useStyles from './styles'

export const Link: FunctionComponent<
  (ButtonProps | AnchorProps) & LinkProps
> = props => {
  const {
    children,
    active,
    fullWidth,
    styles: overrides,
    disabled,
    startIcon,
    endIcon,
    renderAs = 'a',
    ...rest
  } = props
  const styles = useStyles({ active, fullWidth })
  const { disabledStyles } = useStateStyles({ disabled, overrides, styles })

  const RootElement = useCallback(
    (props: (ButtonProps | AnchorProps) & LinkProps) => {
      if (isAnchorProps(props)) {
        const { renderAs, ...rest } = props
        return <a {...rest} />
      }

      const { renderAs, ...rest } = props
      return <button {...rest} />
    },
    [],
  )

  return (
    <RootElement
      className="hero-link hero-link__root"
      data-testid="hero-link"
      renderAs={renderAs}
      {...rest}
      css={[styles.root, overrides?.root, ...disabledStyles]}
    >
      {startIcon && (
        <span
          className="hero-link__icon hero-link__startIcon"
          css={[
            styles.iconBox,
            overrides?.iconBox,
            styles.startIcon,
            overrides?.startIcon,
          ]}
          data-testid="startIcon"
        >
          {startIcon}
        </span>
      )}
      <span className="hero-link__label" css={[styles.label, overrides?.label]}>
        {children}
        {active && (
          <div
            className="hero-link__activeLine"
            data-testid="hero-activeLine"
            css={styles.activeLine}
          />
        )}
      </span>
      {endIcon && (
        <span
          className="hero-link__icon hero-link__endIcon"
          css={[
            styles.iconBox,
            overrides?.iconBox,
            styles.endIcon,
            overrides?.endIcon,
          ]}
          data-testid="endIcon"
        >
          {endIcon}
        </span>
      )}
    </RootElement>
  )
}
