import React, { FunctionComponent } from 'react'
import { IndividualIconProps } from '../../../types'
import getIconSize from '../../../utils/getIconSize'

const SvgWifi: FunctionComponent<IndividualIconProps> = props => {
  const sizeInPixels = getIconSize(props.size)
  return (
    <svg
      {...props}
      width={sizeInPixels}
      height={sizeInPixels}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.876 19.627a1.125 1.125 0 1 1 2.247-.003 1.125 1.125 0 0 1-2.247.003Zm.473-.003-.473-.003v.006M18.901 12.553c-3.805-3.49-9.998-3.49-13.803 0a.75.75 0 1 1-1.014-1.106c4.38-4.016 11.452-4.016 15.831 0a.75.75 0 1 1-1.014 1.106Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.91 8.366c-5.463-4.821-14.356-4.821-19.82 0a.75.75 0 0 1-.992-1.124c6.03-5.323 15.774-5.323 21.805 0a.75.75 0 1 1-.993 1.124ZM15.891 16.353a5.526 5.526 0 0 0-7.783 0 .75.75 0 1 1-1.058-1.064 7.026 7.026 0 0 1 9.9 0 .75.75 0 0 1-1.059 1.064Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default SvgWifi
