import { isString } from '@/utils/isString'
import { isNumber } from '@/utils/isNumber'
import { makeStyles, css } from '@conexasaude/styles'
import { keyframes } from '@emotion/react'

import { SpinnerProps } from './types'

const loader = keyframes`
  from {
    transform:  rotate(0deg);
  }
  to {
    transform:  rotate(360deg);
  }
`

const useStyles = makeStyles<Pick<SpinnerProps, 'size'>>({
  root: css`
    position: relative;
    display: inline-flex;
    transition: 0.2s ease-in-out;
    transition-property: all;
    transition-delay: 0s;
    margin-right: 0;
    width: auto;
    height: auto;
    max-width: 0;
    max-height: 0;
    flex-basis: auto;
    flex-shrink: 0;
    overflow: hidden;
    align-items: center;
    justify-content: center;
  `,
  active: css`
    overflow: visible;
    max-width: 100px;
    max-height: 100px;
  `,

  icon: (theme, { size }) => {
    const sizeValue = isString(size)
      ? size
      : isNumber(size)
      ? `${size}px`
      : 'auto'

    return css({
      animation: `${loader} 1.1s infinite linear`,
      width: sizeValue,
      height: 'auto',
    })
  },
})

export default useStyles
