import { ITypographySpecification } from '@conexasaude/styles'
import { SerializedStyles, css } from '@emotion/react'

const typographyToStyle = (
  specification: ITypographySpecification,
): SerializedStyles => css`
  font-family: ${specification.fontFamily};
  font-weight: ${specification.fontWeight};
  font-size: ${specification.fontSize};
  line-height: ${specification.lineHeight};
  letter-spacing: ${specification.letterSpacing};
`

export default typographyToStyle
