import type { CustomFontsProps, FontProps } from './types'

export const generateCustomFonts = ({ fonts }: CustomFontsProps): string => {
  const generateFontSrc = (font: FontProps) => {
    return font.webFont
      ? `src: url(${font.webFont.url}) format('${font.webFont.format}');`
      : ''
  }

  const generateFontFaces = (fonts: FontProps[]) => {
    return fonts
      .map(font => {
        return `
          @font-face {
            font-family: '${font.fontFamily}';
            font-style: ${font.fontStyle ? font.fontStyle : 'normal'};
            font-weight: ${font.fontWeight};
            font-display: ${font.fontDisplay};
            ${generateFontSrc(font)}
          }
        `
      })
      .join('\n')
  }

  return generateFontFaces(fonts)
}
