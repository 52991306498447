import { makeStyles, css } from '@conexasaude/styles'
import { FormGroupProps } from './types'

export const useStyles = makeStyles<Pick<FormGroupProps, 'row'>>({
  root: (theme, { row }) => css`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    ${!row
      ? `
    align-items:flex-start;
    `
      : ''}
  `,

  row: css`
    flex-direction: row;
  `,
})

export default useStyles
