import typographyToStyle from '@/utils/typographyToStyle'
import { makeStyles, css } from '@conexasaude/styles'

export const useStyles = makeStyles<{
  isOpen: boolean
}>({
  root: css`
    position: relative;
    display: inline-flex;
    cursor: pointer;
  `,
  button: ({ spacing }) => css`
    margin-left: ${spacing(1)}px;
    background: transparent;
    border: none;
    cursor: pointer;
    pointer-events: all;
    padding: 0;
  `,
  dropdown: ({ colors, shadows, typography, spacing }, { isOpen }) => css`
    z-index: 1;
    position: absolute;
    top: 60px;
    right: 0;
    background: ${colors.common.white};
    box-shadow: ${shadows[1]};

    display: ${isOpen ? 'flex' : 'none'};
    align-items: flex-start;
    flex-direction: column;
    min-width: 200px;
    transition: 0.2 ease-in-out;
    > .hero-link {
      width: 100%;
      ${typographyToStyle(typography.body1)};
      color: ${colors.neutral[900]};
      > span {
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding: 0 ${spacing(2)}px;
      }

      &:hover {
        background: ${colors.primary[100]};

        > span {
          color: ${colors.primary[500]};
        }
      }
    }
  `,
})

export default useStyles
